import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";

class EventValue_Pie {
    static getUUID() {
        return "d88173a7-c148-4f75-8cb5-6d6fc1fddfeb";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"events", name:"Event Value List", type:"json"},
            {id:"names", name:"Event Name List", type:"json"},
            {id:"filter", name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 0.5;
    }

    static getHeightRatio() {
        return "100%";
    }

    static calcEvents(_eventData, _groupName, _groupValue, _name) {
        let eventData = _eventData;
        if (_groupName && _groupValue) {
            eventData = AnalyticsUtil.filterBy(_eventData, (_e) => _e[_groupName] === _groupValue);
        }
        let eventCount = 0;
        for (let i = 0; i < eventData.length; i++) {
            const current = eventData[i];
            eventCount += current.eventCount;
        }
        return {name: _name, y: eventCount, text: eventCount+" Events"};
    }

    static lookupName(_props, _event) {
        let idx = _props.events.indexOf(_event);
        if (idx >= 0 && _props.names && idx < _props.names.length) {
            console.log(_props.names[idx]);
            return _props.names[idx];
        }
        return _event;
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        filter.name = _props.events;
        ctx.sendAsync("events","event_v4",["sum:value:eventCount"],["name"], filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let eventData = _data.events.result;
                let groups = _props.events;
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = EventValue_Pie.calcEvents(eventData, "name", groups[i], EventValue_Pie.lookupName(_props, groups[i]));
                        series.push(data);
                    }
                } else {
                    let data = EventValue_Pie.calcEvents(eventData);
                    series.push(data);
                }
                series.sort((a,b) => {return b.y-a.y;});
                let graphData = {
                    chart : {type: "pie"},
                    plotOptions: { pie : { showInLegend: false}},
                    legend: {enabled: groups.length > 1},
                    series : [{data: series}],
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {}

    static getYAxis() {}

    static getTooltip() {
        return {
            formatter : function() {
                let percentage = (Math.round(this.point.percentage*100)/100)+"%";
                return "<b>"+this.point.name+":</b> "+percentage+" ("+this.point.text+")";
            }
        };
    }
}

export default CachedGraphV2(EventValue_Pie, HighChartsGraph);