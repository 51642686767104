
function getDayWithOffset(_offset) {
    let d = new Date();
    d.setDate(d.getDate() + _offset);
    return d;
}

function pad(num, size) {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

function formatDate(_date) {
    return pad(_date.getUTCFullYear(),4)+"-"+pad(_date.getUTCMonth()+1,2)+"-"+pad(_date.getUTCDate(),2);
}

function formatGermanDate(_date) {
    return pad(_date.getUTCDate(),2)+"."+pad(_date.getUTCMonth()+1,2)+"."+pad(_date.getUTCFullYear(),4);
}

function formatLocalDateAndTime(_date) {
    return pad(_date.getDate(),2)+"."+pad(_date.getMonth()+1,2)+"."+pad(_date.getFullYear(),4)+" "+
        pad(_date.getHours(),2)+":"+pad(_date.getMinutes(),2)+":"+pad(_date.getSeconds(),2);
}

function getUnixTime() {
    return Math.floor(Date.now() / 1000);
}

function ceilToDayUTC(_datetime) {
    let ts = Date.UTC(_datetime.getUTCFullYear(), _datetime.getUTCMonth(), _datetime.getUTCDate());
    return new Date(ts);
}

function formatSeconds(_seconds) {
    _seconds = Math.trunc(_seconds);
    let hours   = Math.floor(_seconds / 3600);
    let minutes = Math.floor((_seconds - (hours * 3600)) / 60);
    let seconds = _seconds - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (hours > 0) {
        return hours+'h '+minutes+'m '+seconds+'s';
    } else {
        return minutes+'m '+seconds+'s';
    }
}

export {
    ceilToDayUTC,
    getDayWithOffset,
    formatDate,
    formatLocalDateAndTime,
    getUnixTime,
    formatGermanDate,
    formatSeconds,
};