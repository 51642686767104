import React from "react";
import withNavigation from "../common/SiteWithNavigation";
import withURLParams from "../common/SiteWithURLParams";
import {Button, Container, Divider, Icon, IconButton, Input, InputGroup, Panel, Tag, Tooltip} from "rsuite";
import BuildNavigation from "./BuildNavigation";
import SimpleProgresUI from "../common/SimpleProgresUI";
import BuildContext from "./BuildContext";
import ConditionalFragment from "../common/ConditionalFragment";
import DeleteBuildModal from "./DeleteBuildModal";
import SimpleProgressModal from "../common/SimpleProgressModal";
import BuildComp from "./BuildComp";

function format2(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return ""+_num;
}

class BuildsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            builds : [],
            maxBuilds : 10,
            editBuild : {
            }
        };

        this.progressUI = SimpleProgresUI.getReference();
        this.progressModal = SimpleProgressModal.getReference();
        this.deleteBuildModal = DeleteBuildModal.getReference();

        this.loadBuilds = this.loadBuilds.bind(this);
        this.onLoadMoreBuilds = this.onLoadMoreBuilds.bind(this);
        this.renderBuild = this.renderBuild.bind(this);
        this.onDeleteBuildButton = this.onDeleteBuildButton.bind(this);
        this.onApplyEditButton = this.onApplyEditButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBuilds();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBuilds() {
        this.progressUI.start("Retrieving Builds...");
        BuildContext.getProjectDetails(this.props.urlParams.projectId, (_err, _projectDetails) => {
            if (!this._isMounted) {return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.setState(() => {
                return {
                    projectDetail : _projectDetails
                };
            });
            BuildContext.getBuilds(this.props.urlParams.projectId, this.props.urlParams.buildType, (_err, _builds) => {
                if (!this._isMounted) {return; }
                if (_err) {
                    this.progressUI.error(_err);
                    return;
                }
                this.progressUI.stop();
                this.setState(() => {
                    return {
                        builds : _builds,
                    };
                });
            })

        });
    }

    onLoadMoreBuilds() {
        this.setState((_prevState, _props) => {
            return {
                maxBuilds : _prevState.maxBuilds + 50
            };
        });
    }

    onDeleteBuildButton(_build) {
        this.deleteBuildModal.show(_build, (_delete) => {
            if (_delete) {
                this.progressModal.show("Deleting Build");
                this.progressModal.startTask("Deleting Build: "+_build.version+" ("+_build.buildType+")");
                return BuildContext.deleteBuild(_build, (_err, _builds) => {
                    if (_err) {
                        this.progressModal.error(_err);
                    } else {
                        this.progressModal.complete();
                        this.setState(() => {
                            return {
                                builds: _builds
                            };
                        });
                    }
                });
            }
        });
    }

    onApplyEditButton(_build, _comment) {
        this.progressModal.show("Updating Build");
        this.progressModal.startTask("Updating Build");
        return BuildContext.updateBuildComment(_build, _comment, (_err, _builds) => {
            if (_err) {
                this.progressModal.error(_err);
            } else {
                this.progressModal.complete();
                this.setState(() => {
                    return {
                        builds: _builds,
                        editBuild : {}
                    };
                });
            }
        });
    }

    renderBuild(_build) {
        return (<BuildComp build={_build} project={this.state.projectDetail} withLink
                           onDelete={() => {this.onDeleteBuildButton(_build)}}
                           onCommentChanged={(_comment) => {this.onApplyEditButton(_build, _comment)}}/>);
    }

    render() {
        let renderedBuilds = [];
        let hasMoreBuilds = false;
        if (this.state.builds) {
            let builds = this.state.builds;
            builds.sort((_a, _b) => {return _b.timestamp - _a.timestamp;});
            let buildCount = Math.min(builds.length, this.state.maxBuilds);
            hasMoreBuilds = buildCount < builds.length;
            for (let i = 0; i < buildCount; i++) {
                renderedBuilds.push(this.renderBuild(builds[i]));
            }
        }
        let displayName = "";
        if (this.state.projectDetail) {
            displayName = this.state.projectDetail.displayName;
        }
        return (
            <Container style={{margin:"10px"}}>
                <DeleteBuildModal ref={this.deleteBuildModal.getRef()}/>
                <SimpleProgressModal ref={this.progressModal.getRef()} size="xs"/>
                <ConditionalFragment condition={displayName !== ""}>
                    <h4>{displayName}</h4>
                </ConditionalFragment>
                <BuildNavigation projectId={this.props.urlParams.projectId} buildType={this.props.urlParams.buildType}/>
                <br/>
                <SimpleProgresUI ref={this.progressUI.getRef()}/>
                {renderedBuilds}
                <ConditionalFragment condition={hasMoreBuilds}>
                    <Button appearance={"primary"} onClick={this.onLoadMoreBuilds}>Show 50 more builds </Button>
                </ConditionalFragment>
            </Container>
        );
    }

}

export default withNavigation(
    withURLParams(
        BuildsPage
    )
);