import React from "react";
import {SelectPicker, Panel, Button, Notification} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import NewESRepositoryModal from "./NewESRepositoryModal";
import AnalyticsConnection from "../AnalyticsConnection";

class ESRepositoryUI extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {};

        this.progressUI = React.createRef();
        this.newRepoModal = React.createRef();

        this.loadRepositories = this.loadRepositories.bind(this);
        this.renderRepositories = this.renderRepositories.bind(this);
        this.onRepoSelected = this.onRepoSelected.bind(this);
        this.onRegisterNewRepository = this.onRegisterNewRepository.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadRepositories();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadRepositories() {
        this.progressUI.current.start("Retrieving Repositories...");
        AnalyticsConnection.sendAuthenticated({type:"admin",subType: "esRequest",
            method:"GET", path:"/_snapshot", contentType:"Application/json", payload:"{}"}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            this.progressUI.current.stop();
            let repositories = [];
            for(let repoName in _res.result) {
                repositories.push({id: repoName, config: _res.result[repoName]});
            }
            repositories.sort((_a,_b) => _a.id.localeCompare(_b.id));
            this.setState({
                repositories: repositories
            });
        });
    }

    onRegisterNewRepository() {
        this.newRepoModal.current.show((_create, _config) => {
            if (_create === true) {
                let payload = {
                    type: "s3",
                    settings: {
                        bucket: _config.bucket,
                        region: _config.region,
                        role_arn: _config.role_arn
                    }
                };
                return AnalyticsConnection.sendAuthenticated({type:"admin", subType: "esRequest",
                    method: "PUT", path:"/_snapshot/"+_config.name, contentType: "Application/json", payload : JSON.stringify(payload)}, (_err, _res) => {
                    if (!this._isMounted) { return; }
                    if (_err) {
                       Notification.error({title: "Error", description: _err.message});
                       return;
                   }
                   Notification.success({title: "Success", description: "Successfully registered Repository"});
                   this.loadRepositories();
                });
            }
        });
    }

    onRepoSelected(_value) {
        if (typeof this.props.onSelected === "function") {
            this.props.onSelected(_value);
        }
    }

    renderRepositories() {
        if (this.state.repositories && this.progressUI.current.isVisible() !== true) {
            let options = [];
            for (let i = 0; i < this.state.repositories.length; i++) {
                const current = this.state.repositories[i];
                options.push({label : current.id, value: current});
            }
            return (<SelectPicker data={options} onChange={this.onRepoSelected} placeholder={"Select Repository"} style={{width:"250px"}}/>);
        }
        return null;
    }

    render() {
        return (
            <Panel bordered shaded header={<h6>Elastic Search Repositories</h6>}>
                <SimpleProgresUI ref={this.progressUI}/>
                <NewESRepositoryModal ref={this.newRepoModal}/>
                {this.renderRepositories()} &nbsp;<Button appearance="primary" onClick={this.onRegisterNewRepository}>Register New Repository</Button>
            </Panel>
        );
    }

}

export default ESRepositoryUI;