import React from "react";
import {Button, Loader, Message, Modal} from "rsuite";
import ConditionalFragment from "./ConditionalFragment";
import ColdFireReactRef from "./ColdFireReactRef";

class SimpleProgressModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            active: false,
            name: "Progress",
            tasks: [],
            completed: false
        }

        this.show = this.show.bind(this);
        this.startTask = this.startTask.bind(this);
        this.complete = this.complete.bind(this);
        this.error = this.error.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    static getReference() {
        return new ColdFireReactRef(["show","startTask","complete","error"]);
    }

    onHide() {
        if (this.state.completed === true) {
            this.setState(()=> {
                return {active: false};
            });
        }
    }

    show(_name) {
        return this.setState(()=> {
            return {
                name: _name,
                active: true,
                tasks: [],
                completed: false
            };
        })
    }

    startTask(_text) {
        return this.setState((_state, _props) => {
            let copy = JSON.parse(JSON.stringify(_state.tasks));
            copy.push(_text);
            return {tasks: copy};
        });
    }

    complete() {
        this.setState(() => {
            return {
                completed: true,
                error: null
            };
        });
    }

    error(_err) {
        this.setState(() => {
            return {
                completed: true,
                error: _err
            };
        });
    }

    render() {
        let tasks = [];
        for(let i = 0; i < this.state.tasks.length; i++) {
            const task = this.state.tasks[i];
            if (i < this.state.tasks.length-1) {
                tasks.push(<Message key={"task_"+i} showIcon type={"success"} description={task}/>);
            } else {
                if (!this.state.completed) {
                    tasks.push(<Message key={"task_"+i} type={"info"} description={<Loader content={task} />}/>);
                } else {
                    if (this.state.error) {
                        tasks.push(<Message key={"task_"+i} showIcon type={"error"} description={task}/>);
                    } else {
                        tasks.push(<Message key={"task_"+i} showIcon type={"success"} description={task}/>);
                    }
                }
            }
        }
        let error = null;
        if (this.state.error) {
            let errorText = "";
            if (this.state.error.code) {
                errorText += "Code " + this.state.error.code + ": ";
            }
            if (this.state.error.message) {
                errorText += this.state.error.message;
            }
            error = (<Message type="error" title="Error" description={<p>{errorText}</p>}/>);
        }
        return (
            <Modal show={this.state.active} size={this.props.size}>
                <Modal.Header>
                    <Modal.Title>{this.state.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {tasks}
                    {error}
                </Modal.Body>
                <ConditionalFragment condition={this.state.completed}>
                    <Button onClick={this.onHide} block>Close</Button>
                </ConditionalFragment>
            </Modal>
        )
    }
}

export default SimpleProgressModal;