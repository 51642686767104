import React, {Fragment} from "react";
import GraphCache from "./GraphCache";
import {Button, Container, Icon, IconButton, Loader, Message, Panel} from "rsuite";
import EditGraphConfigModal from "./EditGraphConfigModal";
import ConditionalFragment from "../../common/ConditionalFragment";
import {mergeFilters} from "../filters/FilterLogic";
import ESContext from "../ESContext";

const objectHash = require("object-hash");

function CachedGraphV2(_wrappedComponent, GraphComponent, _detailComponent) {
    return class extends React.Component {
        constructor(_props) {
            super(_props);

            this.state = {
                shouldReload : true,
                counter: 0
            };

            this.instance = new _wrappedComponent();
            if (typeof this.instance.setGraphV2 === "function") {
                this.instance.setGraphV2(this);
            }

            this.concurrentCheck = {};

            this.editConfigModal = React.createRef();
            this.detailModal = React.createRef();

            this.callWrapper = this.callWrapper.bind(this);
            this.onForwardButton = this.onForwardButton.bind(this);
            this.onBackwardButton = this.onBackwardButton.bind(this);
            this.onDeleteButton = this.onDeleteButton.bind(this);
            this.onEditButton = this.onEditButton.bind(this);
            this.onCopyButton = this.onCopyButton.bind(this);
            this.getProps = this.getProps.bind(this);
            this.reload = this.reload.bind(this);
            this.retrieveData = this.retrieveData.bind(this);
            this.getGraphSize = this.getGraphSize.bind(this);
            this.getChartOptions = this.getChartOptions.bind(this);
            this.exportChartOptions = this.exportChartOptions.bind(this);
        }

        getProps() {
            return this.callWrapper("getProps");
        }

        getGraphId() {
            return this.callWrapper("getUUID");
        }

        getGraphSize() {
            return this.callWrapper("getGraphSize");
        }

        callWrapper(_name, _props) {
            if (_wrappedComponent && _wrappedComponent[_name] && typeof _wrappedComponent[_name] === "function") {
                return _wrappedComponent[_name](_props);
            }
        }

        componentDidMount() {
            this.retrieveData(false);
        }

        componentWillUnmount() {
            if (this._esContext !== undefined) {
                this._esContext.cancelAll();
            }
        }

        componentDidUpdate(_prevProps, _prevState, _snapshot) {
            let hash = objectHash(this.props);
            if (hash !== _prevState.propHash) {
                this.setState(() => {
                    return {
                        data: null,
                        error: null,
                        propHash : hash
                    };
                });
                this.retrieveData(false);
            }
        }

        updateComponentState(_fn) {
            return this.setState((_prevState, _props) => {
                let copy = {};
                if (_prevState.componentState) {
                    copy = JSON.parse(JSON.stringify(_prevState.componentState));
                }
                let result = _fn(copy);
                return {componentState : result};
            });
        }

        getComponentState() {
            return this.state.componentState;
        }

        setGraphData(_data) {
            return this.setState(() => {
                return {data: _data};
            });
        }

        reload() {
            this.setState((_state, _props) => {
                return {
                    data : null,
                    counter: _state.counter + 1
                };
            });
            this.retrieveData(true);
        }

        retrieveData(_refreshCache) {
            let graphName = "Unknown";
            if (_wrappedComponent && _wrappedComponent.prototype && _wrappedComponent.prototype.constructor) {
                graphName = _wrappedComponent.prototype.constructor.name;
            }
            if (!_wrappedComponent.getUUID || typeof _wrappedComponent.getUUID !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error: "+graphName, text: "Missing getUUID() in component!"}};
                });
            }

            if (!_wrappedComponent.getProps || typeof _wrappedComponent.getProps !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error: "+graphName, text: "Missing getProps() in component!"}};
                });
            }

            if (!_wrappedComponent.getXAxis || typeof _wrappedComponent.getXAxis !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error: "+graphName, text: "Missing getXAxis() in component!"}};
                });
            }

            if (!_wrappedComponent.getYAxis || typeof _wrappedComponent.getYAxis !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error: "+graphName, text: "Missing getYAxis() in component!"}};
                });
            }

            if (!_wrappedComponent.getTooltip || typeof _wrappedComponent.getTooltip !== "function") {
                return this.setState(() => {
                    return { error: {title: "Graph Error: "+graphName, text: "Missing getTooltip() in component!"}};
                });
            }

            let properties = JSON.parse(JSON.stringify(this.props.config));

            const propDefs = this.getProps();
            for(let i = 0; i < propDefs.length; i++) {
                const currentDef = propDefs[i];
                if (currentDef.type === "number" && typeof properties[currentDef.id] !== "number") {
                    let val = parseFloat(properties[currentDef.id]);
                    if (typeof val === "number" && !isNaN(val)) {
                        properties[currentDef.id] = val;
                    } else {
                        properties[currentDef.id] = currentDef.default !== undefined ? currentDef.default : 0;
                    }
                }
                if (currentDef.type === "boolean" && typeof properties[currentDef.id] !== "boolean") {
                    properties[currentDef.id] = currentDef.default !== undefined ? currentDef.default : false;
                }
            }

            if (this.props.parentFilter) {
                properties.filter = mergeFilters(this.props.parentFilter, properties.filter);
            }
            if (this.props.dateSelection) {
                properties.dateSelection = this.props.dateSelection;
            }

            let hash = objectHash({id: _wrappedComponent.getUUID(), props: properties});
            let cached = GraphCache.getData(hash);
            if (_refreshCache === true) {
                cached = null;
            }
            if (cached) {
                // fix datetime for x-axis
                let xAxis = this.callWrapper("getXAxis");
                if (xAxis && xAxis.type && xAxis.type === "datetime") {
                    if (cached.series) {
                        for( let seriesIdx = 0; seriesIdx < cached.series.length; seriesIdx++) {
                            const series = cached.series[seriesIdx];
                            if (series.data) {
                                for (let i = 0; i < series.data.length; i++) {
                                    if (series.data[i].x && typeof series.data[i].x === "string") {
                                        series.data[i].x = new Date(series.data[i].x);
                                    }
                                }
                            }
                        }
                    }
                }
                return this.setState((_state, _props) => {
                    return {data : cached};
                });
            }

            if (this.concurrentCheck[hash] === true) {
                return;
            }
            this.concurrentCheck[hash] = true;
            this.setState(() => {
                return { isLoading: true };
            });
            let getterFn = _wrappedComponent.getGraphData;
            if (this.instance && typeof this.instance.getGraphData === "function") {
                getterFn = this.instance.getGraphData;
            }
            if (this._esContext !== undefined) {
                this._esContext.cancelAll();
                delete this._esContext;
            }
            const esContext = ESContext.getAsyncContext();
            if (_refreshCache) {
                esContext.disableCache();
            }
            this._esContext = esContext;
            return getterFn(esContext, properties, (_err, _graph) => {
                delete this._esContext;
                delete this.concurrentCheck[hash];
                this.setState(() => {
                    return { isLoading: false};
                });
                if(_err) {
                    return this.setState((_state, _props) => {
                        return {
                            error: {title: "API Error", text: _err.code+": "+_err.message}
                        };
                    });
                } else {
                    GraphCache.storeData(hash, _graph);
                    return this.setState((_state, _props) => {
                        return {data : _graph};
                    });
                }
            });
        }

        onForwardButton() {
            if (typeof this.props.onPushForward === "function") {
                this.props.onPushForward();
            }
        }

        onBackwardButton() {
            if (typeof this.props.onPushBackward === "function") {
                this.props.onPushBackward();
            }
        }

        onDeleteButton() {
            if (typeof this.props.onDelete === "function") {
                this.props.onDelete();
            }
        }

        onEditButton() {
            if (typeof this.props.onEdit === "function") {
                this.props.onEdit();
            }
        }

        onCopyButton() {
            if (typeof this.props.onCopy === "function") {
                this.props.onCopy();
            }
        }

        getChartOptions() {
            let chartOptions = this.state.data;
            if (!chartOptions) {
                chartOptions = {
                    chart : {height: this.callWrapper("getHeightRatio")}
                };
            }
            if (chartOptions.chart && !chartOptions.chart.height) {
                chartOptions.chart.height = this.callWrapper("getHeightRatio");
            }
            if (!chartOptions.title) {
                chartOptions.title = {text: ""};
            }
            chartOptions.credits = {enabled: false};
            let xAxis = this.callWrapper("getXAxis");
            if (xAxis) {
                chartOptions.xAxis = xAxis;
            }
            let yAxis = this.callWrapper("getYAxis");
            if (yAxis) {
                chartOptions.yAxis = yAxis;
            }
            let tooltip = this.callWrapper("getTooltip");
            if (tooltip) {
                chartOptions.tooltip = tooltip;
            }
            if (chartOptions.tooltip && chartOptions.tooltip.outside === undefined) {
                chartOptions.tooltip.outside = true;
            }
            return chartOptions;
        }

        exportChartOptions() {
            return this.getChartOptions();
        }

        render() {
            let graphProps = this.callWrapper("getGraphProps", this.props);
            if (graphProps && graphProps.xAxis && graphProps.xAxis === "dataCategory") {
                graphProps.xAxis = [];
                if (this.state.data && this.state.data[0] && this.state.data[0].data) {
                    for (let i = 0; i < this.state.data[0].data.length; i++) {
                        graphProps.xAxis.push(this.state.data[0].data[i].name);
                    }
                }
            }

            let configProps = this.props.config;

            if (this.state.error) {
                if (this.props.editMode) {
                    return (
                        <Container style={{marginBottom:10}}>
                            <Panel bodyFill bordered>
                                <EditGraphConfigModal ref={this.editConfigModal}/>
                                <h6 style={{textAlign:"center"}}>
                                    {configProps ? configProps.title : ""}
                                    <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"backward"}/>} onClick={this.onBackwardButton} style={{float:"left"}}/>
                                    <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"forward"}/>} onClick={this.onForwardButton} style={{float:"left", marginLeft:10}}/>
                                    <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"edit2"}/>} onClick={this.onEditButton} style={{float:"left", marginLeft:10}}/>
                                    <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"copy-o"}/>} onClick={this.onCopyButton} style={{float:"left", marginLeft:10}}/>
                                    <IconButton size="xs"color="red" icon={<Icon icon={"trash2"} />} onClick={this.onDeleteButton} style={{float: "right"}}/>
                                </h6>
                                <div style={{clear:"both"}}></div>
                                <Message type="error" title={this.state.error.title} description={this.state.error.text}/>
                            </Panel>
                        </Container>
                    )
                }
                return (<Fragment><Message type="error" title={this.state.error.title} description={this.state.error.text}/><br/><Button appearance={"primary"} block onClick={this.reload}>Retry</Button></Fragment>);
            }

            let chartOptions = this.getChartOptions();
            let graphComp = (<GraphComponent key={this.state.propHash+"_"+this.state.counter} chartOptions={chartOptions}/>);
            let loadingIcon = null;
            if(this.state.isLoading) {
                loadingIcon = (<Loader style={{marginRight:10}}/>);
            }

            if (this.props.editMode) {
                return (
                    <Container style={{marginBottom:10}}>
                        <Panel bodyFill bordered>
                            <EditGraphConfigModal ref={this.editConfigModal}/>
                            <h6 style={{textAlign:"center"}}>
                                {loadingIcon}
                                {configProps.title}
                                <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"backward"}/>} onClick={this.onBackwardButton} style={{float:"left"}}/>
                                <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"forward"}/>} onClick={this.onForwardButton} style={{float:"left", marginLeft:10}}/>
                                <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"edit2"}/>} onClick={this.onEditButton} style={{float:"left", marginLeft:10}}/>
                                <IconButton size="xs" appearance={"primary"} icon={<Icon icon={"copy-o"}/>} onClick={this.onCopyButton} style={{float:"left", marginLeft:10}}/>
                                <IconButton size="xs"color="red" icon={<Icon icon={"trash2"} />} onClick={this.onDeleteButton} style={{float: "right"}}/>
                            </h6>
                            <div style={{clear:"both"}}></div>
                            {graphComp}
                        </Panel>
                    </Container>
                )
            }

            let detailCompRendered = null;
            if (_detailComponent !== undefined) {
                detailCompRendered = (<_detailComponent ref={this.detailModal}/>);
            }

            let extraUI = null;
            if (this.instance&& typeof this.instance.renderExtraUI === "function") {
                extraUI = this.instance.renderExtraUI();
            }
            return (
                <Container style={{marginBottom:10}}>
                    {detailCompRendered}
                    <Panel bodyFill bordered>
                        <h6 style={{textAlign:"center", margin:5}}>
                            {loadingIcon}
                            {configProps.title}
                            <IconButton size="xs" icon={<Icon icon={"reload"} />} onClick={this.reload} style={{float: "right", marginLeft:"5px"}}/>
                            <ConditionalFragment condition={_detailComponent !== undefined}>
                                <IconButton size="xs" icon={<Icon icon={"attribution"} />} onClick={()=>{this.detailModal.current.show(configProps)}} style={{float: "right"}}/>
                            </ConditionalFragment>
                        </h6>
                        {extraUI}
                        {graphComp}
                    </Panel>
                </Container>
            );
        }
    }

}

export default CachedGraphV2;