import React, {Fragment} from "react";
import withURLParams from "../common/SiteWithURLParams";
import withNavigation from "../common/SiteWithNavigation";
import {
    Button,
    Container,
    FlexboxGrid,
    Icon,
    IconButton, Input,
    List,
    Panel,
    SelectPicker,
    Table,
    Toggle
} from "rsuite";
import GameContext from "./GameContext";
import GamePagesContext from "./GamePagesContext";
import SimpleProgresUI from "../common/SimpleProgresUI";
import NewPageNameModal from "./NewPageNameModal";
import SimpleProgressModal from "../common/SimpleProgressModal";
import ConditionalFragment from "../common/ConditionalFragment";
import DeletePageModal from "./DeletePageModal";

class PageSettingsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            gameName : _props.urlParams.gameId
        }

        this.progressUI = SimpleProgresUI.getReference();
        this.progressModal = SimpleProgressModal.getReference();
        this.pageNameModal = NewPageNameModal.getReference();
        this.deletePageModal = DeletePageModal.getReference();

        this.resolveGameName = this.resolveGameName.bind(this);
        this.refreshPages = this.refreshPages.bind(this);
        this.onNewButton = this.onNewButton.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onDiscardChanges = this.onDiscardChanges.bind(this);
        this.onApplyChanges = this.onApplyChanges.bind(this);
        this.onDeleteButton = this.onDeleteButton.bind(this);
        this.renderPageList = this.renderPageList.bind(this);
    }

    resolveGameName() {
        GameContext.resolveGameName(this.props.urlParams.gameId, (_err, _name) => {
            if (!this._isMounted) {return; }
            if (_err) {
                // do nothing
            } else {
                return this.setState(() => {
                    return {gameName : _name};
                });
            }
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.resolveGameName();
        this.refreshPages();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    refreshPages() {
        this.setState(() => {
            return {isLoading: true};
        });
        this.progressUI.start("Retrieving Pages...");
        return GamePagesContext.getPages(this.props.urlParams.gameId, (_err, _pages) => {
            if (!this._isMounted) {return;}
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.progressUI.stop();
            return this.setState(() => {
                return {pages: _pages, backup: JSON.parse(JSON.stringify(_pages)), isLoading: false};
            });
        });
    }

    onNewButton() {
        this.pageNameModal.show((_create, _name) => {
            if (_create) {
                this.progressModal.show("Creating New Page");
                this.progressModal.startTask("Creating Page: "+_name);
                return GamePagesContext.createNewPage(this.props.urlParams.gameId, _name, (_err) => {
                    if(!this._isMounted) {return;}
                    if (_err) {
                        this.progressModal.error(_err);
                        return;
                    }
                    this.progressModal.complete();
                    this.refreshPages();
                });
            }
        });
    }

    onDeleteButton(_name, _id) {
        this.deletePageModal.show(_name, (_delte) => {
            if (_delte) {
                this.progressModal.show("Deleting Page");
                this.progressModal.startTask("Deleting Page: "+_name);
                return GamePagesContext.deletePage(_id, (_err) => {
                    if(!this._isMounted) {return;}
                    if (_err) {
                        this.progressModal.error(_err);
                        return;
                    }
                    this.progressModal.complete();
                    this.refreshPages();
                });
            }
        });
    }

    onValueChanged(_id, _key, _val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.pages));
            for (let i = 0; i < copy.length; i++) {
                if (copy[i].id === _id) {
                    copy[i][_key] = _val;
                    copy[i]._hasChanges = true;
                    break;
                }
            }
            return { pages : copy};
        });
    }

    onDiscardChanges(_id) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.pages));
            let backup = null;
            for (let i = 0; i < _prevState.backup.length; i++) {
                if (_prevState.backup[i].id === _id) {
                    backup = _prevState.backup[i];
                }
            }
            if (backup) {
                for (let i = 0; i < copy.length; i++) {
                    if (copy[i].id === _id) {
                        copy[i] = JSON.parse(JSON.stringify(backup));
                        break;
                    }
                }
            }
            return { pages : copy};
        });
    }

    onApplyChanges(_id) {
        const pages = this.state.pages;
        let page = null;
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].id === _id) {
                page = pages[i];
                break;
            }
        }
        if (page) {
            this.progressModal.show("Updating Page");
            this.progressModal.startTask("Updating Page: "+page.name);
            delete page._hasChanges;
            return GamePagesContext.updatePage(page, (_err) => {
                if(!this._isMounted) {return;}
                if (_err) {
                    this.progressModal.error(_err);
                    return;
                }
                this.progressModal.complete();
                this.refreshPages();
            });
        }
    }

    renderPageList() {
        let pageTypes = [{value: "abTest",label:"AB-Test"},{value: "page", label:"Page"}];
        if (this.state.pages) {
            return (
                <Panel bordered shaded >
                    <Table data={this.state.pages} autoHeight>
                        <Table.Column width={200} resizable>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    return (
                                        <Fragment>
                                            <Icon icon={"file-text"}/>&nbsp;&nbsp;&nbsp;{rowData.name}&nbsp;&nbsp;&nbsp;
                                            <IconButton size="xs" icon={<Icon icon={"external-link"}/>} href={"/analytics/game/"+rowData.gameId+"/page/"+rowData.id} />
                                        </Fragment>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={100}>
                            <Table.HeaderCell>Visible</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    return (
                                        <Toggle checked={rowData.visible === true} onChange={(_val) => {this.onValueChanged(rowData.id, "visible", _val);}} checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />} />
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={150}>
                            <Table.HeaderCell>Page Type</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    return (
                                        <SelectPicker size={"xs"} value={rowData.type} data={pageTypes} onChange={(_val) => {this.onValueChanged(rowData.id, "type", _val)}} style={{width:120}} cleanable={false}/>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={150}>
                            <Table.HeaderCell>Order</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    return (
                                        <Input size="xs" value={rowData.order} onChange={(_val) => {this.onValueChanged(rowData.id, "order", _val)}} style={{width:120}} cleanable={false}/>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    return (
                                        <Fragment>
                                            <ConditionalFragment condition={rowData._hasChanges === true}>
                                                <IconButton size="xs" color="green" icon={<Icon icon={"check"}/>} onClick={()=>{this.onApplyChanges(rowData.id);}} style={{marginRight:5}}/>
                                                <IconButton size="xs" color="red" icon={<Icon icon={"close"}/>} onClick={()=>{this.onDiscardChanges(rowData.id);}} style={{marginRight:5}}/>
                                            </ConditionalFragment>
                                            <ConditionalFragment condition={rowData._hasChanges !== true}>
                                                <IconButton size="xs" color="red" icon={<Icon icon={"trash"}/>} onClick={()=>{this.onDeleteButton(rowData.name, rowData.id);}} style={{marginRight:5}}/>
                                            </ConditionalFragment>
                                        </Fragment>
                                    )
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </Panel>
            );
        }
        return null;
    }

    render () {
        return (
            <Container style={{margin:"10px"}}>
                <SimpleProgressModal ref={this.progressModal.getRef()}/>
                <NewPageNameModal ref={this.pageNameModal.getRef()}/>
                <DeletePageModal ref={this.deletePageModal.getRef()}/>
                <Panel bordered shaded header={<h4>Page Settings: {this.state.gameName}</h4>}>
                    <SimpleProgresUI ref={this.progressUI.getRef()}/>
                    <ConditionalFragment condition={!this.state.isLoading}>
                        <Button appearance="primary" onClick={this.onNewButton}><Icon icon={"file-text"}/> New Page</Button>
                        <br/><br/>
                        {this.renderPageList()}
                    </ConditionalFragment>
                </Panel>
            </Container>
        );
    }
}

export default withNavigation(
    withURLParams(
        PageSettingsPage
    )
);