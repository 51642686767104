import React from "react";
import withNavigation from "../common/SiteWithNavigation";
import withPermission from "../common/SiteWithPermission";
import AnalyticsConnection from "./AnalyticsConnection";
import {Button, Container, FlexboxGrid, Input, InputGroup, Message, Panel, SelectPicker, Table, Toggle} from "rsuite";
import SimpleProgresUI from "../common/SimpleProgresUI";
import AnalyticsContext from "./AnalyticsContext";
import ESContext from "./ESContext";
import withURLParams from "../common/SiteWithURLParams";
import TablePagination from "rsuite/es/Table/TablePagination";
import ConditionalFragment from "../common/ConditionalFragment";

function format2(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return ""+_num;
}

function formatTime(_ts) {
    const d = new Date(_ts*1000);
    return format2(d.getDate())+"."+format2(d.getMonth()+1)+"."+d.getFullYear()+" "+format2(d.getHours())+":"+format2(d.getMinutes())+":"+format2(d.getSeconds());
}

class UserDebugPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLiveEnvironment : false,
            tables : [{label:"Events", value : "events"},{label:"Currencies", value: "currencies"},{label:"Ad Impressions", value:"adImpressions"}],
            selectedTable : "events"
        }

        this.progressUI = SimpleProgresUI.getReference();

        this.onEnvironmentChanged = this.onEnvironmentChanged.bind(this);
        this.onTableChanged = this.onTableChanged.bind(this);
        this.onUserIdChanged = this.onUserIdChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderResultTable = this.renderResultTable.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);
        this.fetchCurrencies = this.fetchCurrencies.bind(this);
        this.fetchAdImpressions = this.fetchAdImpressions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onEnvironmentChanged(_val) {
        return this.setState(()=> {
            return { isLiveEnvironment : _val};
        });
    }

    onTableChanged(_val) {
        return this.setState(()=> {
            return { selectedTable : _val};
        });
    }

    onUserIdChanged(_val) {
        return this.setState(()=> {
            return { userId : _val};
        });
    }

    onSubmit() {
        let isLongUSerId = this.state.userId.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g) !== null && this.state.userId.length === 36;
        let shortUserId = this.state.userId.match(/[0-9a-fA-F]{4}-[0-9a-fA-F]{4}/g) !== null && this.state.userId.length === 9;
        if (isLongUSerId === false && shortUserId === false) {
            this.setState(() => {
                return { error : "Malformed UserId"};
            })
            return;
        }
        this.setState(() => {
            return { error : ""};
        })
        this.progressUI.start("Retrieving User Data...");
        let userId = this.state.userId;
        if (shortUserId !== false) {
            userId = "*"+this.state.userId.toLowerCase().replaceAll("-","");
        }
        let gameId = this.props.urlParams.gameId;
        if (this.state.isLiveEnvironment === false) {
            gameId += "_test";
        }
        if (this.state.selectedTable === "events") {
            this.fetchEvents(userId, gameId);
        }
        else if (this.state.selectedTable === "currencies") {
            this.fetchCurrencies(userId, gameId);
        }
        else if (this.state.selectedTable === "adImpressions") {
            this.fetchAdImpressions(userId, gameId);
        }
    }



    fetchEvents(_userId, _gameId) {
        ESContext.retrieveDocuments("event_v4", {user_id: _userId, game_id : _gameId}, ["cfid","timestamp","name"], (_err, _result) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.progressUI.stop();
            _result.sort((_a, _b) => {return _a.timestamp - _b.timestamp;});
            let result = [];
            for (let i = 0; i < _result.length; i++) {
                result.push({time: formatTime(_result[i].timestamp), name: _result[i].name, value: _result[i].value});
            }
            this.setState(()=> {
                return {
                    resultData : result,
                    resultHeader : ["time","name","value"]
                }
            });
        });
    }

    fetchCurrencies(_userId, _gameId) {
        ESContext.retrieveDocuments("currency_v4", {user_id: _userId, game_id : _gameId}, ["cfid","transaction_id","currency","type"], (_err, _result) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.progressUI.stop();
            _result.sort((_a, _b) => {return _a.timestamp - _b.timestamp;});
            let result = [];
            for (let i = 0; i < _result.length; i++) {
                result.push({
                    time: formatTime(_result[i].timestamp),
                    transactionId: _result[i].transaction_id,
                    currency: _result[i].currency,
                    type: _result[i].type === 0 ? "given" : "spent",
                    amount: _result[i].amount,
                    context: _result[i].context
                });
            }
            this.setState(()=> {
                return {
                    resultData : result,
                    resultHeader : ["time","transactionId","currency","type","amount","context"]
                }
            });
        });
    }

    fetchAdImpressions(_userId, _gameId) {
        ESContext.retrieveDocuments("ad_impression_v4", {user_id: _userId, game_id : _gameId}, ["cfid","timestamp","placement"], (_err, _result) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.progressUI.stop();
            _result.sort((_a, _b) => {return _a.timestamp - _b.timestamp;});
            let result = [];
            for (let i = 0; i < _result.length; i++) {
                result.push({
                    time: formatTime(_result[i].timestamp),
                    placement: _result[i].placement,
                    result : _result[i].result === 0 ? "viewed" : "dismissed"
                });
            }
            this.setState(()=> {
                return {
                    resultData : result,
                    resultHeader : ["time","placement","result"]
                }
            });
        });
    }

    renderResultTable() {
        let columns = [];
        if (this.state.resultHeader) {
            for (let i = 0; i < this.state.resultHeader.length; i++) {
                const colName = this.state.resultHeader[i];
                columns.push(
                    <Table.Column key={"col_"+i} resizable width={200}>
                        <Table.HeaderCell>{colName}</Table.HeaderCell>
                        <Table.Cell>
                            {(_rowData) => {
                                let value = _rowData[colName];
                                if (typeof value === "boolean") {
                                    return value === true ? "true" : "false";
                                }
                                return _rowData[colName];
                            }}
                        </Table.Cell>
                    </Table.Column>
                );
            }
        }
        if (columns.length > 0) {
            return (
                <Table data={this.state.resultData} autoHeight>
                    {columns}
                </Table>
            )
        }
        return null;
    }

    render() {
        return (
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>User-Level Debug</h4>}>
                    <Panel bordered shaded header={<h6>Request Parameters</h6>} style={{width:"1000px"}}>
                        <span>Environment:&nbsp;
                            <Toggle size={"sm"} checked={this.isLiveEnvironment} checkedChildren={"Live"} unCheckedChildren={"Test"} onChange={this.onEnvironmentChanged}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            Table:
                            <SelectPicker size={"sm"} searchable={false} cleanable={false} data={this.state.tables} value={this.state.selectedTable} onChange={this.onTableChanged} style={{marginLeft:"10px",width:140}}/>
                        </span><br/><br/>
                        <InputGroup>
                            <InputGroup.Addon>User-ID</InputGroup.Addon>
                            <Input value={this.state.userId} onChange={(_val) => {this.onUserIdChanged(_val)}}/>
                        </InputGroup><br/>
                        <Button appearance={"primary"} onClick={this.onSubmit}>Fetch User Data</Button>
                    </Panel>
                    <br/>
                    <ConditionalFragment condition={this.state.error && this.state.error !== ""}>
                        <Message type={"error"} title={"Error"} description={<p>{this.state.error}</p>} />
                    </ConditionalFragment>
                    <SimpleProgresUI ref={this.progressUI.getRef()}/>
                    {this.renderResultTable()}
                </Panel>
            </Container>
        );
    }
}

export default
    withNavigation(
        withURLParams(
            withPermission(() => { return AnalyticsConnection.hasPermission("query")},
                UserDebugPage
            )
        )
    );