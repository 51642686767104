import React, {Fragment} from "react";
import {Button, Container, Icon, IconButton, Panel, SelectPicker, Table} from "rsuite";
import withURLParams from "../../common/SiteWithURLParams";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import NewIndexNameModal from "./NewIndexNameModal";
import {Redirect} from "react-router";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";

function select(_text) {
    return {label: _text, value: _text};
}

class ESIndexDetailPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            fieldTypes : [select("binary"), select("boolean"), select("keyword"), select("constant_keyword"), select("wildcard"), select("text"), select("integer"),select("long"), select("double")]
        };

        this.progressUI = React.createRef();
        this.newIndexNameModal = React.createRef();

        this.reloadMappings = this.reloadMappings.bind(this);
        this.onTypeChanged = this.onTypeChanged.bind(this);
        this.onRevertButton = this.onRevertButton.bind(this);
        this.onSaveButton = this.onSaveButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.reloadMappings();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    reloadMappings() {
        this.progressUI.current.start("")
        return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
            method: "GET", path: "/"+this.props.urlParams.indexName+"/_mapping", contentType: "Application/json", payload: "{}"}, (_err, _res) => {
            if (!this._isMounted) {return;}
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            this.progressUI.current.stop();
            let fields = [];
            let fieldTypes = this.state.fieldTypes;
            if (_res.result[this.props.urlParams.indexName]) {
                const data = _res.result[this.props.urlParams.indexName];
                if (data && data.mappings && data.mappings.properties) {
                    const properties = data.mappings.properties;
                    for (let fieldName in properties) {
                        const type = properties[fieldName].type;
                        let found = false;
                        for(let i = 0; i < fieldTypes.length; i++) {
                            if (fieldTypes[i].label === type){
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            fieldTypes.push(select(type));
                        }
                        fields.push({name: fieldName, type: properties[fieldName].type, origType: properties[fieldName].type, hasChanges: false});
                    }
                }
            }
            return this.setState(() => {
                return {
                    fields : fields,
                    fieldTypes: fieldTypes
                }
            })
        });
    }

    onTypeChanged(_name, _val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.fields));
            for(let i = 0; i < copy.length; i++) {
                if (copy[i].name === _name) {
                    copy[i].type = _val;
                    copy[i].hasChanges = true;
                }
            }
            return {fields : copy};
        });
    }

    onRevertButton(_name) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.fields));
            for(let i = 0; i < copy.length; i++) {
                if (copy[i].name === _name) {
                    copy[i].type = copy[i].origType;
                    copy[i].hasChanges = false;
                }
            }
            return {fields : copy};
        });
    }

    onSaveButton() {
        this.newIndexNameModal.current.show((_save, _newName) => {
            if(_save === true) {
                let payload = {
                    mappings: {
                        properties : {}
                    }
                };
                for(let i = 0; i < this.state.fields.length; i++) {
                    let current = this.state.fields[i];
                    payload.mappings.properties[current.name] = {type : current.type};
                }
                return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
                    method: "PUT", path: "/"+_newName, contentType: "Application/json", payload: JSON.stringify(payload)}, (_err, _res) => {
                    if (!this._isMounted) { return; }
                    return this.setState(() => {
                        return {redirect: "/analytics/db/indices"};
                    });
                })
            }
        });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>);
        }
        let hasChanges = false;
        if (this.state.fields) {
            for(let i = 0; i < this.state.fields.length; i++) {
                if (this.state.fields[i].hasChanges) {
                    hasChanges = true;
                    break;
                }
            }
        }
        return (
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>Index: {this.props.urlParams.indexName}</h4>}>
                    <SimpleProgresUI ref={this.progressUI}/>
                    <NewIndexNameModal ref={this.newIndexNameModal}/>
                    <Table data={this.state.fields} autoHeight>
                        <Table.Column resizable width={200}>
                            <Table.HeaderCell>Field</Table.HeaderCell>
                            <Table.Cell dataKey={"name"}/>
                        </Table.Column>
                        <Table.Column resizable width={300}>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell>
                                {(_rowData)=> {
                                    let saveUI = null;
                                    if (_rowData.hasChanges) {
                                        saveUI = (
                                            <Fragment>
                                                <IconButton size="sm" color={"red"} icon={<Icon icon={"close-circle"}/>} style={{marginLeft:10}} onClick={() => {this.onRevertButton(_rowData.name);}} />
                                            </Fragment>
                                        )
                                    }
                                    return (
                                        <Fragment>
                                            <span>
                                                <SelectPicker size={"sm"} value={_rowData.type} data={this.state.fieldTypes} onChange={(_val) => {this.onTypeChanged(_rowData.name, _val)}}/>
                                                {saveUI}
                                            </span>
                                        </Fragment>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                    {(() => {
                        if (hasChanges) {
                            return (
                                <Button color="green" onClick={this.onSaveButton}>Save Changes</Button>
                            )
                        }
                        return null;
                    })()}
                </Panel>
            </Container>
        );
    }

}

export default
    withNavigation(
        withPermission(()=>{return AnalyticsConnection.hasPermission("admin")},
            withURLParams(
                ESIndexDetailPage
            )
        )
    )
;