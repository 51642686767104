import React from "react";
import {Container, Message} from "rsuite";

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withPermission(_checkFn, _wrapped) {
    return class extends React.Component {
        constructor(_props) {
            super(_props);

            this.compRef = React.createRef();

            this.state = {};
        }

        render() {
            if (typeof _checkFn !== "function") {
                return (
                    <Container style={{margin:"10px"}}>
                        <Message type="error" title="Error" description={"Component "+getDisplayName(_wrapped)+" is using withPermission and no check function!"}/>
                    </Container>
                );
            }
            if(_checkFn() !== true) {
                return (
                    <Container style={{margin:"10px"}}>
                        <Message type="error" title="Not Authorized!" description={"You do not have Permission to view this Page!"}/>
                    </Container>
                );
            }
            return (<_wrapped ref={this.compRef} {...this.props}/>);
        }
    }
}

export default withPermission;