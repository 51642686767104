import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatEuro, YAxisFormatNumber} from "../graphs/GraphFormatting";
import {formatGermanDate} from "../../common/TimeUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";

class AdECPM_Daily {
    static getUUID() {
        return "f012e4ba-9cbe-493a-8884-75da75659e68";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcECPM(_adsData, _groupName, _groupValue) {
        let adsData = _adsData;
        if (_groupName && _groupValue) {
            adsData = AnalyticsUtil.filterBy(_adsData, (_e) => _e[_groupName] === _groupValue);
        }
        let series = [];
        for (let i = 0; i < adsData.length; i++) {
            const current = adsData[i];
            const ecpm = (current.revenue/current.impressions)*1000;
            series.push({name: _groupValue, x: new Date(current.day).getTime(), y: ecpm, text: (Math.round(ecpm*100)/100)+"€ ("+current.impressions+" Impressions)"});
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        filter.result = 0;
        ctx.sendAsync("ads","ad_impression_v4",["value_count:result:impressions","sum:revenue:revenue"],["day", _props.group], filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let adsData = _data.ads.result;
                console.log("+++", adsData);
                let groups = AnalyticsUtil.getDistinct(adsData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = AdECPM_Daily.calcECPM(adsData, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = AdECPM_Daily.calcECPM(adsData);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {type: "datetime", crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatEuro();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = [formatGermanDate(new Date(this.x))];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(AdECPM_Daily, HighChartsGraph);