import React, {Fragment} from 'react';
import {Container, Content, Header } from "rsuite";
import MeteorNavigationBar from "./common/MeteorNavigationBar";
import * as Config from "./config";

class DefaultPage extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Fragment>
                <Container>
                    <MeteorNavigationBar/>

                    <Container>
                        <Header>
                            <h2>Meteor {Config.version}</h2>
                        </Header>
                        <Content></Content>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default DefaultPage;