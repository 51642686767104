import React from "react";
import MeteorNavigationBar from "./MeteorNavigationBar";
import {Container} from "rsuite";

function withNavigation(_wrapped, _checkFn) {
    return class extends React.Component {
        constructor(_props) {
            super(_props);
            this.state = {};
        }

        render() {
            return (
                <Container>
                    <MeteorNavigationBar/>
                    <_wrapped {...this.props}/>
                </Container>
            );
        }
    }
}

export default withNavigation;