import React from "react";
import 'rsuite/dist/styles/rsuite-dark.css';
import AppRoutes from "./common/AppRoutes";
import {BrowserRouter} from "react-router-dom";

class App extends React.Component {

  render() {
    return (
        <BrowserRouter>
            <AppRoutes/>
        </BrowserRouter>
    );
  }
}


export default App;
