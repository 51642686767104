
class EventBus {
    constructor() {
        this._handlers = {};
    }

    registerHandler(_type, _handler) {
        if (!this._handlers[_type]) {
            this._handlers[_type] = [];
        }
        let found = false;
        let list = this._handlers[_type];
        for(let i = 0; i < list.length; i++) {
            if (list[i] === _handler) {
                found = true;
                break;
            }
        }
        if (found === false) {
            list.push(_handler);
        }
    }

    unregisterHandler(_type, _handler) {
        if (!this._handlers[_type]) {
            return;
        }
        let found = -1;
        let list = this._handlers[_type];
        for(let i = 0; i < list.length; i++) {
            if (list[i] === _handler) {
                found = i;
                break;
            }
        }
        if (found >= 0) {
            list.splice(found, 1);
        }
    }

    notify(_type, _data) {
        if (this._handlers[_type]) {
            let list = this._handlers[_type];
            for(let i = 0; i < list.length; i++) {
                list[i](_data);
            }
        }
    }
}

const instance = new EventBus();
export default instance;
