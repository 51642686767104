import React from "react";
import {HelpBlock, Input, Button, Icon, IconStack, Modal} from "rsuite";

class SnapshotNameModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            name: ""
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onNameChanged = this.onNameChanged.bind(this);
    }

    show(_callback) {
        return this.setState(() => {
            return {
                open: true,
                name: "",
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onNameChanged(_val) {
        return this.setState(()=> {
            return {name: _val};
        });
    }

    onSave() {
        if(typeof this.state.callback === "function") {
            this.state.callback(true, this.state.name);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    render() {
        return (
            <Modal show={this.state.open} size={"xs"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        <IconStack size="lg">
                            <Icon icon="save" stack="1x" />
                        </IconStack>
                        &nbsp;New Snapshot
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        Please enter the name of the snapshot
                    </HelpBlock>
                    <Input placeholder={"Enter snapshot name..."} type={"text"} value={this.state.name} onChange={this.onNameChanged} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onSave} appearance="primary">
                        Create Snapshot
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default SnapshotNameModal;