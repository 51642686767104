import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";

class Currency_Retention {
    static getUUID() {
        return "e5149025-6944-42be-b640-4ef9fffb1c81";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"currencyId",name:"Currency Id", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"includeSpend",name:"Include Spent Currency", type: "boolean"},
            {id:"includeGiven",name:"Include Given Currency ", type: "boolean"},
            {id:"filterOutlier", name: "Filter Outlier", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcCurrencyRetention(_currencyData, _installData, _isSpent, _props, _groupValue) {
        let currencyData = _currencyData;
        let installData = _installData;
        if (_props.group && _groupValue) {
            currencyData = AnalyticsUtil.filterBy(_currencyData, (_e) => _e[_props.group] === _groupValue);
            installData = AnalyticsUtil.filterBy(_installData, (_e) => _e[_props.group] === _groupValue);
        }
        let name  = "Given" + (_groupValue ? " - "+_groupValue : "");
        if (_isSpent === true) {
            name  = "Spent" + (_groupValue ? " - "+_groupValue : "");
        }
        let series = [];
        let currencyPilData = AnalyticsUtil.addPil(currencyData);
        for (let pil = 0; pil <= 28; pil++) {
            let type = 0;
            if (_isSpent === true) {
                type = 1;
            }
            const filtered = AnalyticsUtil.filterBy(currencyPilData,(_e) => _e.pil >= 0 && _e.pil <= pil && _e.maxPil >= pil && _e.type === type);
            let installCount = 0;
            for (let i = 0; i < installData.length; i++) {
                const current = installData[i];
                const maxPil = AnalyticsUtil.calcMaxPil(current.install_date);
                if (maxPil >= pil) {
                    installCount += current.installs;
                }
            }
            if (_props.filterOutlier) {
                let userData = {};
                for (let i = 0; i < filtered.length; i++) {
                    const current = filtered[i];
                    if (!userData[current.user_id]) {
                        userData[current.user_id] = 0;
                    }
                    userData[current.user_id] += current.amount;
                }
                let currencyData = [];
                for (let userId in userData) {
                    currencyData.push(userData[userId]);
                }
                while (currencyData.length < installCount) {
                    currencyData.push(0);
                }

                let outlierResult = OutlierUtil.filterOutlier(currencyData);
                let avg = StatisticsUtil.avg(outlierResult.cleaned);
                let outlierPercentage = outlierResult.outlier.length / (outlierResult.outlier.length + outlierResult.cleaned.length);
                const outlierText = "Outliers: "+outlierResult.outlier.length+"("+Math.round(outlierPercentage*10000)/100+"%)";
                if (currencyData.length > 10) {

                    series.push({name: name, x: pil, y: avg, text: (Math.round(avg*10)/10)+" ("+outlierResult.cleaned.length+" Users) "+outlierText});
                }
            } else {
                let currencyData = 0;
                for(let i = 0; i < filtered.length; i++) {
                    if (typeof filtered[i].amount === "number") {
                        currencyData += filtered[i].amount;
                    }
                }
                let normalized = currencyData/installCount;
                if (installCount > 10) {
                    series.push({name: name, x: pil, y: normalized, text: (Math.round(normalized*10)/10)+" ("+installCount+" Users)"});
                }

            }
        }
        return {name: name, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        let currencyFilter = JSON.parse(JSON.stringify(filter));
        currencyFilter.currency = _props.currencyId;
        const grouping = ["install_date","day", "type",_props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        let userFilter = JSON.parse(JSON.stringify(filter));
        if (userFilter.context !== undefined) {
            delete userFilter.context;
        }
        ctx.sendAsync("installs","user_v4",["cardinality:user_id:installs"],["install_date", _props.group], userFilter);
        ctx.sendAsync("currency","currency_v4",["sum:amount:amount"],grouping, currencyFilter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installData = _data.installs.result;
                let currencyData = _data.currency.result;
                let groups = AnalyticsUtil.getDistinct(currencyData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        if (_props.includeSpend) {
                            let data = Currency_Retention.calcCurrencyRetention(currencyData, installData, true, _props, groups[i]);
                            series.push(data);
                        }
                        if (_props.includeGiven) {
                            let data = Currency_Retention.calcCurrencyRetention(currencyData, installData, false, _props, groups[i]);
                            series.push(data);
                        }
                    }
                } else {
                    if (_props.includeSpend) {
                        let data = Currency_Retention.calcCurrencyRetention(currencyData, installData, true, _props);
                        series.push(data);
                    }
                    if (_props.includeGiven) {
                        let data = Currency_Retention.calcCurrencyRetention(currencyData, installData, false, _props);
                        series.push(data);
                    }
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: true},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {labels: {format: "D{value}"}, allowDecimals: false, crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = ["D"+this.x];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(Currency_Retention, HighChartsGraph);