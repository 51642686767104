import React, {Fragment} from 'react';
import {
    Container,
    FlexboxGrid,
    Panel,
    Form,
    FormGroup,
    ControlLabel,
    Schema, Message
} from "rsuite";
import {FormControl, ButtonToolbar, Button, HelpBlock} from "rsuite";
import { ReactComponent as IconMeteor} from '../icons/meteor-solid.svg';
import MeteorContext from "./MeteorContext";
import * as Config from "../config";

const model = Schema.Model( {
    username: Schema.Types.StringType().isRequired("Username is required"),
    password: Schema.Types.StringType().isRequired("Password is required")
})

class LoginPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isLoggingIn : false
        };
        this.setFormData = this.setFormData.bind(this);
        this.onLoginButton = this.onLoginButton.bind(this);
    }

    setFormData(_val) {
        this.setState(() => {
            return {
                loginForm : _val
            };
        });
    }

    onLoginButton() {
        this.setState(() => {
            return {
                isLoggingIn : true
            };
        });
        return MeteorContext.authenticate(this.state.loginForm.username, this.state.loginForm.password, (_err, _res) => {
            if (_err) {
                let errorText = _err.message;
                if (_err.code) {
                    errorText = "Code " + _err.code + ": " + _err.message;
                }
                return this.setState(() => {
                    return {
                        isLoggingIn : false,
                        error : {
                            title: "Login failed!",
                            description: errorText
                        }
                    };
                });
            }
            if (!MeteorContext.isAuthenticated()) {
                this.setState(() => {
                    return {
                        isLoggingIn : false,
                        error : {
                            title: "Login failed!",
                            description: "Please try again and check local time settings."
                        }
                    };
                });
            }
        });
    }

    render() {
        let error = null;
        if (this.state.error) {
            error = (
                <Fragment>
                    <Message showIcon type={"error"} title={this.state.error.title} description={this.state.error.description} />
                    <Message showIcon type={"info"} description={"Please check your time settings."}/><br/>
                </Fragment>
            );
        }
        return (
                <Container>
                    <br/>
                    <FlexboxGrid justify="center" align="bottom" >
                        <FlexboxGrid.Item style={{minWidth:"50vh"}}>
                            <Panel style={{margin:"10px"}} header={
                                <div style={{textAlign:"center"}}>
                                    <i className="rs-icon-size-4x" style={{color:"#ffffff"}}><IconMeteor/></i>
                                    <h3>Sign In to Meteor</h3>
                                    <h6>{Config.version}</h6>
                                </div>
                            } bordered>
                                <HelpBlock>
                                    Please log-in using your user id and password.<br/>If you do not have credentials ask your administrator.
                                </HelpBlock>
                                <br/>
                                <Form fluid model={model} onChange={this.setFormData}>
                                    <FormGroup>
                                        <ControlLabel>Username</ControlLabel>
                                        <FormControl name="username" placeholder="username" disabled={this.state.isLoggingIn}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Password</ControlLabel>
                                        <FormControl name="password" type="password" placeholder="password" disabled={this.state.isLoggingIn}/>
                                    </FormGroup>
                                    {error}
                                    <FormGroup>
                                        <ButtonToolbar>
                                            <Button loading={this.state.isLoggingIn} active={!this.state.isLoggingIn} block appearance="primary" onClick={this.onLoginButton}>Sign in</Button>
                                        </ButtonToolbar>
                                    </FormGroup>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
        );
    }
}

export default LoginPage;