import EventBus from "../common/EventBus";
import MeteorNavigation from "../common/MeteorNavigation";
import {Icon} from "rsuite";
import MeteorRoutes from "../common/MeteorRoutes";
import DBConfigPage from "./db/DBConfigPage";
import DBBackupPage from "./db/DBBackupPage";
import DBDebugPage from "./db/DBDebugPage";
import ESIndicesPage from "./db/ESIndicesPage";
import ESIndexDetailPage from "./db/ESIndexDetailPage";
import ESQueryPage from "./db/ESQueryPage";
import ESSQLPage from "./db/ESSQLPage";
import ABTestPage from "./ABTestPage";
import GameContext from "./GameContext";
import AnalyticsConnection from "./AnalyticsConnection";
import PageSettingsPage from "./PageSettingsPage";
import GamePagesContext from "./GamePagesContext";
import CustomAnalyticsPage from "./CustomAnalyticsPage";
import UserDebugPage from "./UserDebugPage";
import ESCreateBackupPage from "./db/ESCreateBackupPage";

class AnalyticsContext {
    constructor() {
        this.onAnalyticsAuthenticationChanged = this.onAnalyticsAuthenticationChanged.bind(this);
        this.updateNavigation = this.updateNavigation.bind(this);
        this.updateDBNavigation = this.updateDBNavigation.bind(this);
        this.updateGameNavigation = this.updateGameNavigation.bind(this);

        EventBus.registerHandler("ANALYTICS_AUTHENTICATION_CHANGED", this.onAnalyticsAuthenticationChanged);
        this.updateNavigation();
    }

    onAnalyticsAuthenticationChanged(_data) {
        GameContext.clearCache();
        this.updateNavigation();
    }

    updateNavigation() {
        this.updateDBNavigation();
        this.updateGameNavigation();
    }


    updateGameNavigation() {
        let node = MeteorNavigation.getNode("Games");
        if (!node) {
            node = MeteorNavigation.addNode("Games", <Icon icon={"gamepad"}/>)
        }
        node.clearChilds();
        if (!AnalyticsConnection.isAuthenticated()) {
            return; // fast out
        }
        node.setSyncMode(true);

        MeteorRoutes.addRoute("/analytics/game/:gameId/pageSettings", PageSettingsPage);
        MeteorRoutes.addRoute("/analytics/game/:gameId/debug", UserDebugPage);
        MeteorRoutes.addRoute("/analytics/game/:gameId/page/:pageId", CustomAnalyticsPage);
        MeteorRoutes.addRoute("/analytics/game/:gameId/abtest", ABTestPage);
        return GameContext.getGames((_err, _games) => {
            if (_err) {
                node.addNode("No Games Found");
                node.setSyncMode(false);
                return;
            };
            for(let gamesIdx = 0; gamesIdx < _games.length; gamesIdx++) {
                let currentGame = _games[gamesIdx];
                node.addDivider();
                let gameNode = node.addNode(currentGame.name, null, "/analytics/game/"+currentGame.id);

                gameNode.setSyncMode(true);
                GamePagesContext.getPages(currentGame.id, (_err, _pages) => {
                    if (_pages) {
                        let abTests = [];
                        let pages = [];
                        for (let i = 0; i < _pages.length; i++) {
                            if (_pages[i].visible === true && _pages[i].type === "abTest") {
                                abTests.push(_pages[i]);
                            }
                            if (_pages[i].visible === true && _pages[i].type === "page") {
                                pages.push(_pages[i]);
                            }
                        }
                        if (abTests.length > 0) {
                            const abNode = gameNode.addNode("AB-Tests", <Icon icon={"flask"} />, "/analytics/game/"+currentGame.id+"/page/"+abTests[abTests.length -1].id);
                            for(let i = 0; i < abTests.length; i++) {
//                                abNode.addNode(abTests[i].name, null, "/analytics/game/"+currentGame.id+"/page/"+abTests[i].id);
                            }
                        }
                        if (pages.length > 0) {
                            const pageNode = gameNode.addNode("Analytics", <Icon icon={"logo-analytics"}/>, "/analytics/game/"+currentGame.id+"/page/"+pages[0].id);
                            for(let i = 0; i < pages.length; i++) {
                                //pageNode.addNode(pages[i].name, null, "/analytics/game/"+currentGame.id+"/page/"+pages[i].id);
                            }
                        }
                        if (AnalyticsConnection.hasPermission("query")) {
                            const debugNode = gameNode.addNode("Debug",  <Icon icon={"bug"}/>, "/analytics/game/"+currentGame.id+"/debug")
                        }
                    }
                    if (AnalyticsConnection.hasPermission("admin")) {
                        gameNode.addNode("Page Settings", <Icon icon={"setting"}/>, "/analytics/game/"+currentGame.id+"/pageSettings");
                    }
                    gameNode.setSyncMode(false);
                });
            }
            node.addDivider();
            node.setSyncMode(false);
        });
    }

    updateDBNavigation() {
        let dbNode = MeteorNavigation.getNode("DB");
        if (!dbNode) {
            dbNode = MeteorNavigation.addNode("DB", <Icon icon={"database"}/>);
        }
        dbNode.clearChilds();
        if (!AnalyticsConnection.isAuthenticated()) {
            return; // fast out
        }
        dbNode.setSyncMode(true);
        MeteorRoutes.addRoute("/analytics/db/configuration", DBConfigPage);
        MeteorRoutes.addRoute("/analytics/db/backup", DBBackupPage);
        MeteorRoutes.addRoute("/analytics/db/backup/:repository/create", ESCreateBackupPage);
        MeteorRoutes.addRoute("/analytics/db/debug", DBDebugPage);
        MeteorRoutes.addRoute("/analytics/db/indices", ESIndicesPage);
        MeteorRoutes.addRoute("/analytics/db/indices/:indexName", ESIndexDetailPage);
        MeteorRoutes.addRoute("/analytics/db/query", ESQueryPage);
        MeteorRoutes.addRoute("/analytics/db/sql", ESSQLPage);
        AnalyticsConnection.ensureAuthentication(() => {
            if (AnalyticsConnection.hasPermission("admin")) {
                dbNode.addNode("Configuration", <Icon icon={"wrench"}/>, "/analytics/db/configuration");
                dbNode.addNode("Indices", <Icon icon={"list"}/>, "/analytics/db/indices");
                dbNode.addNode("Backup", <Icon icon={"save"}/>, "/analytics/db/backup");
                dbNode.addNode("Debug", <Icon icon={"bug"}/>, "/analytics/db/debug");
                dbNode.addNode("Query", <Icon icon={"search"}/>, "/analytics/db/query");
                dbNode.addNode("SQL", <Icon icon={"search"}/>, "/analytics/db/sql");
            }

            dbNode.setSyncMode(false);
        });

    }

}

const instance = new AnalyticsContext();
export default instance;