import React from "react";
import {HelpBlock, Button, Icon, IconStack, Modal} from "rsuite";

class DeleteIndexModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            name: ""
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    show(_name, _callback) {
        return this.setState(() => {
            return {
                open: true,
                name: _name,
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onDelete() {
        if(typeof this.state.callback === "function") {
            this.state.callback(true);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    render() {
        return (
            <Modal show={this.state.open} size={"xs"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        <IconStack size="lg">
                            <Icon icon="square" stack="2x" style={{color:"red"}}/>
                            <Icon icon="trash" stack="1x" />
                        </IconStack>
                        &nbsp;Delete Index {this.state.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        Do you really want to delete Index "{this.state.name}"
                    </HelpBlock>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onDelete} color={"red"}>
                        Delete Index
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeleteIndexModal;