import React, {Fragment} from 'react';
import {
    Notification,
    Container,
    Panel,
    Form,
    FormGroup,
    ControlLabel,
    Schema, Message
} from "rsuite";
import {FormControl, ButtonToolbar, Button, HelpBlock} from "rsuite";
import MeteorNavigationBar from "../common/MeteorNavigationBar";
import MeteorContext from "./MeteorContext";
import {Redirect} from "react-router";


const model = Schema.Model( {
    password: Schema.Types.StringType().isRequired("Password is required"),
    password2: Schema.Types.StringType().isRequired("Retyped Password is required")
})

class ChangePasswordPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            isSaving : false
        };
        this.setFormData = this.setFormData.bind(this);
        this.onChangePasswordButton = this.onChangePasswordButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setFormData(_val) {
        this.setState(() => {
            return {
                passwordForm : _val
            };
        });
    }

    onChangePasswordButton() {
        if (this.state.passwordForm.password !== this.state.passwordForm.password2) {
            Notification.error({
                title: "Save Password failed",
                description: "Password do not match."
            });
            return;
        }
        this.setState(() => {
            return {isSaving: true};
        });
        return MeteorContext.sendAuthenticated({type:"auth", subType: "changePassword", password: this.state.passwordForm.password}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                Notification.error({
                    title: "Save Password failed",
                    description: _err.message
                });
                return this.setState(() => {
                    return {isSaving: false};
                });
            }
            Notification.success({
                title: "Password saved.",
                description: "Password changed successfully"
            });
            return this.setState(() => {
                return {redirect: "/"};
            });
        });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>);
        }
        let error = null;
        if (this.state.error) {
            error = (
                <Fragment>
                    <Message showIcon type={"error"} title={this.state.error.title} description={this.state.error.description} />
                </Fragment>
            );
        }
        return (
            <Container>
                <MeteorNavigationBar/>
                <Container>
                <Panel style={{margin:"10px", maxWidth:"350px"}} header={
                    <div style={{textAlign:"center"}}>
                        <h3>Change Password</h3>
                    </div>
                } bordered>
                    <HelpBlock>
                        Please enter your new Password.
                    </HelpBlock>
                    <br/>
                    <Form fluid model={model} onChange={this.setFormData}>
                        <FormGroup>
                            <ControlLabel>Password</ControlLabel>
                            <FormControl name="password" type="password" placeholder="Enter password..." disabled={this.state.isSaving}/>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Retype Password</ControlLabel>
                            <FormControl name="password2" type="password" placeholder="Retyped Password..." disabled={this.state.isSaving}/>
                        </FormGroup>
                        {error}
                        <FormGroup>
                            <ButtonToolbar>
                                <Button loading={this.state.isSaving} active={!this.state.isSaving} block appearance="primary" onClick={this.onChangePasswordButton}>Change Password</Button>
                            </ButtonToolbar>
                        </FormGroup>
                    </Form>
                </Panel>
                </Container>
            </Container>
        );
    }
}

export default ChangePasswordPage;