import React from "react";
import {Redirect} from "react-router";
import {Loader, Nav} from "rsuite";

class BuildNavigation extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {
            navs : [
                {id: "devel", name: "Devel", projectId: _props.projectId},
                {id: "stable", name: "Stable", projectId: _props.projectId},
                {id: "release", name: "Release", projectId: _props.projectId}
            ]
        };
        this.onSelect = this.onSelect.bind(this);
    }


    onSelect(_key) {
        for(let i = 0; i< this.state.navs.length; i++) {
            const current = this.state.navs[i];
            if (current.id === _key) {
                return this.setState(() => {
                    return {redirect : "/firebuild/project/"+this.props.projectId+"/buildType/"+current.id};
                });
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>);
        }
        if (this.state.navs.length === 0) {
            return (<Nav appearance="subtle">
                <Nav.Item><Loader/> Loading...</Nav.Item>
            </Nav>);
        } else {
            let navItems = [];
            for (let i = 0; i < this.state.navs.length; i++) {
                const current = this.state.navs[i];
                if (current.id === this.props.buildType) {
                    navItems.push(<Nav.Item eventKey={current.id} active>{current.name}</Nav.Item>);
                } else {
                    navItems.push(<Nav.Item eventKey={current.id}>{current.name}</Nav.Item>);
                }
            }
            return (
                <Nav appearance="subtle" onSelect={this.onSelect}>
                    {navItems}
                </Nav>
            )
        }
    }
}

export default BuildNavigation;