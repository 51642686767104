import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber, YAxisFormatTime} from "../graphs/GraphFormatting";
import {formatGermanDate, formatSeconds} from "../../common/TimeUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";

class AdImpression_Daily {
    static getUUID() {
        return "5c95f4f1-dd8e-49e9-b150-b230399e8324";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"filterOutlier", name: "Filter Outlier", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcAdImpressions(_dauData, _impressionData, _filterOutlier, _groupName, _groupValue) {
        let dauData = _dauData;
        let impressionData = _impressionData;
        if (_groupName && _groupValue) {
            dauData = AnalyticsUtil.filterBy(_dauData, (_e) => _e[_groupName] === _groupValue);
            impressionData = AnalyticsUtil.filterBy(_impressionData, (_e) => _e[_groupName] === _groupValue);
        }
        let series = [];
        if (_filterOutlier) {
            let data = {};
            for (let i = 0; i < impressionData.length; i++) {
                const current = impressionData[i];
                if (!data[current.day]) {
                    data[current.day] = {users: 0 , impressions : []};
                }
                if (typeof current.impressions === "number") {
                    data[current.day].impressions.push(current.impressions);
                }
            }
            for(let i = 0; i < dauData.length; i++) {
                const current = dauData[i];
                if (!data[current.day]) {
                    data[current.day] = {users: 0 , impressions : []};
                }
                if (typeof  current.dau === "number") {
                    data[current.day].users += current.dau;
                }
            }
            for(let day in data) {
                let entry = data[day];
                while( entry.impressions.length < entry.users) {
                    entry.impressions.push(0);
                }
                let outlierResult = OutlierUtil.filterOutlier(entry.impressions);
                let avg = StatisticsUtil.avg(outlierResult.cleaned);
                let outlierPercentage = outlierResult.outlier.length / (outlierResult.outlier.length + outlierResult.cleaned.length);
                const outlierText = "Outliers: "+outlierResult.outlier.length+"("+Math.round(outlierPercentage*10000)/100+"%)";
                series.push({name: _groupValue, x: new Date(day).getTime(), y: avg, text: (Math.round(avg*10)/10)+ " ("+outlierResult.cleaned.length+" Users) "+outlierText});
            }
        } else {
            let data = {};
            for (let i = 0; i < impressionData.length; i++) {
                const current = impressionData[i];
                if (!data[current.day]) {
                    data[current.day] = {users: 0 , impressions : 0};
                }
                if (typeof current.impressions === "number") {
                    data[current.day].impressions += current.impressions;
                }
            }
            for(let i = 0; i < dauData.length; i++) {
                const current = dauData[i];
                if (!data[current.day]) {
                    data[current.day] = {users: 0 , impressions : 0};
                }
                if (typeof  current.dau === "number") {
                    data[current.day].users += current.dau;
                }
            }
            for (let day in data) {
                const current = data[day];
                const adImpressionsPerUser = current.impressions / current.users;
                series.push({name: _groupValue, x: new Date(day).getTime(), y: adImpressionsPerUser, text: (Math.round(adImpressionsPerUser*10)/10)+ " ("+current.users+" Users)"});
            }
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        const grouping = ["day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        ctx.sendAsync("dau","session_duration_v4",["cardinality:user_id:dau"],grouping, filter);
        let adImpressionFilter = JSON.parse(JSON.stringify(filter));
        adImpressionFilter.result = 0;
        ctx.sendAsync("impressions","ad_impression_v4",["value_count:placement:impressions"],grouping, adImpressionFilter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let dauData = _data.dau.result;
                let impressionData = _data.impressions.result;
                let groups = AnalyticsUtil.getDistinct(dauData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = AdImpression_Daily.calcAdImpressions(dauData, impressionData, _props.filterOutlier, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = AdImpression_Daily.calcAdImpressions(dauData, impressionData, _props.filterOutlier);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {type: "datetime", crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = [formatGermanDate(new Date(this.x))];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(AdImpression_Daily, HighChartsGraph);