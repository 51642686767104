import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatEuro} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";

class LTV {
    static getUUID() {
        return "98e29a43-5039-49ce-b9f2-7246ec88d0ff";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"excludeAds", name: "Exclude Ads", type:"boolean"},
            {id:"excludeIAP", name: "Exclude IAPs", type:"boolean"},
            {id:"split", name: "Split Ads & IAPs", type:"boolean"},
            {id:"filterOutlier", name: "Filter Outlier", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcLTV(_adsData, _iapData, _installData, _props, _groupValue) {
        if (!_adsData) {
            _adsData = [];
        }
        if (!_iapData) {
            _iapData = [];
        }
        let adsData = _adsData;
        let iapData = _iapData;
        let installData = _installData;
        if (_props.group && _groupValue) {
            adsData = AnalyticsUtil.filterBy(_adsData, (_e) => _e[_props.group] === _groupValue);
            iapData = AnalyticsUtil.filterBy(_iapData, (_e) => _e[_props.group] === _groupValue);
            installData = AnalyticsUtil.filterBy(_installData, (_e) => _e[_props.group] === _groupValue);
        }
        let adsPilData = AnalyticsUtil.addPil(adsData);
        let iapPilData = AnalyticsUtil.addPil(iapData);
        let revSeries = [];
        let adSeries = [];
        let iapSeries = [];
        for (let pil = 0; pil <= 28; pil++) {
            const filteredAds = AnalyticsUtil.filterBy(adsPilData,(_e) => _e.pil >= 0 && _e.pil <= pil && _e.maxPil >= pil);
            const filteredIAP = AnalyticsUtil.filterBy(iapPilData,(_e) => _e.pil >= 0 && _e.pil <= pil && _e.maxPil >= pil);
            let installCount = 0;
            for (let i = 0; i < installData.length; i++) {
                const current = installData[i];
                const maxPil = AnalyticsUtil.calcMaxPil(current.install_date);
                if (maxPil >= pil) {
                    installCount += current.installs;
                }
            }
            let purchaseCount = 0;
            for (let i = 0; i < filteredIAP.length; i++) {
                const current = filteredIAP[i];
                const maxPil = AnalyticsUtil.calcMaxPil(current.install_date);
                if (maxPil >= pil) {
                    purchaseCount += current.purchaseCount;
                }
            }
            if (_props.filterOutlier) {
                let userData = {};
                for (let i = 0; i < filteredAds.length; i++) {
                    const current = filteredAds[i];
                    if (!userData[current.user_id]) {
                        userData[current.user_id] = {ads: 0, iap : 0};
                    }
                    if (typeof current.revenue === "number") {
                        userData[current.user_id].ads += current.revenue;
                    }
                }
                for (let i = 0; i < filteredIAP.length; i++) {
                    const current = filteredIAP[i];
                    if (!userData[current.user_id]) {
                        userData[current.user_id] = {ads: 0, iap : 0};
                    }
                    if (typeof current.revenue === "number") {
                        userData[current.user_id].iap += current.revenue;
                    }
                }
                let revenues = [];
                let adRevenues = [];
                let iapRevenues = [];
                for (let userId in userData) {
                    let current = userData[userId];
                    adRevenues.push(current.ads);
                    iapRevenues.push(current.iap);
                    revenues.push(current.ads + current.iap);
                }
                while (revenues.length < installCount) {
                    revenues.push(0);
                }
                while (adRevenues.length < installCount) {
                    adRevenues.push(0);
                }
                while (iapRevenues.length < installCount) {
                    iapRevenues.push(0);
                }
                const calcLTVEntry = (_prefix, _dataList, _outputList) => {
                    let outlierResult = OutlierUtil.filterOutlier(_dataList);
                    let avg = StatisticsUtil.avg(outlierResult.cleaned);
                    let outlierPercentage = outlierResult.outlier.length / (outlierResult.outlier.length + outlierResult.cleaned.length);
                    const outlierText = "Outliers: "+outlierResult.outlier.length+"("+Math.round(outlierPercentage*10000)/100+"%)";
                    if (_dataList.length > 10) {
                        _outputList.push({name: _groupValue, x: pil, y: avg, text: (Math.round(avg*100)/100)+"€ ("+outlierResult.cleaned.length+" Users) "+outlierText});
                    }
                }
                calcLTVEntry("", revenues, revSeries);
                calcLTVEntry(" (Ads)", adRevenues, adSeries);
                calcLTVEntry(" (IAP)", iapRevenues, iapSeries);
            } else {
                let adRevenue = 0;
                let iapRevenue = 0;
                for(let i = 0; i < filteredAds.length; i++) {
                    if (typeof filteredAds[i].revenue === "number") {
                        adRevenue += filteredAds[i].revenue;
                    }
                }
                for (let i = 0; i < filteredIAP.length; i++) {
                    if (typeof filteredIAP[i].revenue === "number") {
                        iapRevenue += filteredIAP[i].revenue;
                    }
                }
                let revenue = (adRevenue + iapRevenue) / installCount;
                adRevenue /= installCount;
                iapRevenue /= installCount;
                if (installCount > 10) {
                    let purchaseCopuntText = "";
                    if (purchaseCount > 0) {
                        purchaseCopuntText = " ("+purchaseCount+" Purchases)";
                    }
                    revSeries.push({name: _groupValue, x: pil, y: revenue, text: Math.round(revenue*100)/100+"€ ("+installCount+" Users)"+purchaseCopuntText});
                    adSeries.push({name: _groupValue+" (Ads)", x: pil, y: adRevenue, text: Math.round(adRevenue*100)/100+"€ ("+installCount+" Users)"});
                    iapSeries.push({name: _groupValue+" (IAP)", x: pil, y: iapRevenue, text: Math.round(iapRevenue*100)/100+"€ ("+installCount+" Users)"+purchaseCopuntText});
                }
            }
        }
        if (_props.split) {
            let result = [];
            if (!_props.excludeAds && !_props.excludeIAP) {
                result.push({name: _groupValue ? _groupValue : "LTV", data : revSeries});
            }
            if (!_props.excludeAds) {
                result.push({name: _groupValue ? _groupValue+" (Ads)" : "Ads", data : adSeries});
            }
            if (!_props.excludeIAP) {
                result.push({name: _groupValue ? _groupValue+" (IAP)" : "IAP", data : iapSeries});
            }
            return result;
        } else {
            return [{name: _groupValue, data : revSeries}];
        }
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        let revFilter = filter;
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
            revFilter = JSON.parse(JSON.stringify(filter));
            revFilter.day = {from : _props.dateSelection.start, to: _props.dateSelection.end + (28*24*60*60)}
        }

        const grouping = ["install_date","day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        ctx.sendAsync("installs","user_v4",["cardinality:user_id:installs"],["install_date", _props.group], filter);
        if (!_props.excludeAds) {
            ctx.sendAsync("ads","ad_impression_v4",["sum:revenue:revenue"],grouping, revFilter);
        }
        if (!_props.excludeIAP) {
            ctx.sendAsync("iap","premium_purchase_v4",["sum:revenue:revenue","value_count:user_id:purchaseCount"],grouping, revFilter);
        }
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installData = _data.installs.result;
                let adsData = _data.ads ? _data.ads.result : [];
                let iapData = _data.iap ? _data.iap.result : [];
                let groups = AnalyticsUtil.getDistinct(installData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = LTV.calcLTV(adsData, iapData, installData, _props, groups[i]);
                        series = series.concat(data);
                    }
                } else {
                    let data = LTV.calcLTV(adsData, iapData, installData, _props);
                    series = series.concat(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: series.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {labels: {format: "D{value}"}, allowDecimals: false, crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatEuro();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = ["D"+this.x];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(LTV, HighChartsGraph);