import React from "react";
import {HelpBlock, Button, Modal, List, FlexboxGrid, Toggle, Icon} from "rsuite";

class RestoreSnapshotModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            name: ""
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onRestore = this.onRestore.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChangeRestoreSetting = this.onChangeRestoreSetting.bind(this);
    }

    show(_name, _indices, _callback) {
        let indices = [];
        for(let i = 0; i < _indices.length; i++) {
            indices.push({name: _indices[i], restore: true});
        }
        return this.setState(() => {
            return {
                open: true,
                name: _name,
                indices: indices,
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onRestore() {
        if(typeof this.state.callback === "function") {
            let indices = [];
            for(let i = 0; i < this.state.indices.length; i++) {
                if(this.state.indices[i].restore === true) {
                    indices.push(this.state.indices[i].name);
                }
            }
            this.state.callback(true, indices);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    onChangeRestoreSetting(_name, _val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.indices));
            for(let i = 0; i< copy.length; i++) {
                if (copy[i].name === _name) {
                    copy[i].restore = _val;
                    break;
                }
            }
            return {indices: copy};
        });
    }

    render() {
        let indices = [];
        if (this.state.indices) {
            for(let i = 0; i < this.state.indices.length; i++) {
                indices.push(
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={22}>
                                {this.state.indices[i].name}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={2} style={{display:"flex", justifyContent: "flex-end"}}>
                                <Toggle style={{float:"right"}} checked={this.state.indices[i].restore}
                                        onChange={(_val)=> {this.onChangeRestoreSetting(this.state.indices[i].name, _val)}}
                                        checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                );
            }
        }
        return (
            <Modal show={this.state.open} size={"xs"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        Restore Snapshot {this.state.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        Do you really want to restore Snapshot "{this.state.name}"
                    </HelpBlock>
                    <List size="sm">
                        {indices}
                    </List>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onRestore} color={"green"}>
                        Restore Snapshot
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default RestoreSnapshotModal;