import React from "react";
import {Button, Container, FlexboxGrid, Input, InputGroup, Panel, SelectPicker, Notification} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";

class DBDebugPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            methods :  [{label: "GET", value: "GET"}, {label: "POST", value: "POST"}, {label:"PUT", value: "PUT"}, {label:"DELETE", value: "DELETE"}],
            selectedMethod : "GET",
            path : "/",
            payload : "{}"
        }

        this.progressUI = React.createRef();

        this.onMethodChanged = this.onMethodChanged.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onMethodChanged(_val) {
        return this.setState(()=> {
            return { selectedMethod : _val};
        });
    }

    onTextChanged(_id, _val) {
        return this.setState(() => {
            let obj = {};
            obj[_id] = _val;
            return obj;
        });
    }

    onSubmit() {
        let json = null;
        try {
            json = JSON.parse(this.state.payload);
        } catch (ex) {}
        if (json === null) {
            Notification.error({title: "Error", description: "JSON Payload not parsable."});
            return;
        }
        this.progressUI.current.start("Waiting for Server...");
        return AnalyticsConnection.sendAuthenticated({type: "admin",subType: "esRequest", method: this.state.selectedMethod,
                path: this.state.path, contentType: "Application/json", payload : this.state.payload}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.current.error(_err);
                return this.setState(() => {
                    return { response: _err};
                });
            }
            this.progressUI.current.stop();
            return this.setState(() => {
                return { response: JSON.stringify(_res.result, null, 2)};
            });
        });
    }

    render() {
        let lines = 3;
        if (this.state.response && typeof this.state.response === "string") {
            lines = (this.state.response.match(/\n/g) || '').length + 1
        }
        return (
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>DB Debug</h4>}>
                    <Panel bordered shaded header={<h6>Request Parameters</h6>} style={{width:"1000px"}}>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={3}>
                            <SelectPicker data={this.state.methods} value={this.state.selectedMethod} onChange={this.onMethodChanged} style={{width:100}}/>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={21}>
                            <InputGroup>
                                <InputGroup.Addon>Path</InputGroup.Addon>
                                <Input value={this.state.path} onChange={(_val) => {this.onTextChanged("path", _val)}}/>
                            </InputGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid><br/>
                    <Input componentClass="textarea" value={this.state.payload} onChange={(_val) => {this.onTextChanged("payload", _val)}} rows={1} style={{width:1000, resize:"auto"}}/>
                    <br/>
                    <Button appearance={"primary"} onClick={this.onSubmit}>Send</Button>
                    </Panel>
                    <br/>
                    <SimpleProgresUI ref={this.progressUI}/>
                    <Panel bordered shaded header={<h6>Response</h6>} style={{width:1000}}>
                        <Input componentClass="textarea" value={this.state.response} style={{width:1000, height:lines*10, resize:"auto"}} rows={lines}/>
                    </Panel>
                </Panel>
            </Container>
        );
    }
}

export default
    withNavigation(
        withPermission(()=>{return AnalyticsConnection.hasPermission("admin")},
            DBDebugPage
        )
    )
;