import React from "react";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";
import withURLParams from "../../common/SiteWithURLParams";
import {Notification, Button, Checkbox, Container, HelpBlock, Input, Panel} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import ConditionalFragment from "../../common/ConditionalFragment";


class ESCreateBackupPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            snapshotName : ""
        }

        this.progressUI = SimpleProgresUI.getReference();

        this.loadIndices = this.loadIndices.bind(this);
        this.onSnapshotNameChanged = this.onSnapshotNameChanged.bind(this);
        this.onIndexChanged = this.onIndexChanged.bind(this);
        this.onCreateButton = this.onCreateButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadIndices();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadIndices() {
        this.progressUI.start("Retrieving indices...");
        return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
            method: "GET", path: "/_stats", contentType : "Application/json", payload: "{}"}, (_err, _stats) => {
            if(!this._isMounted) { return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.progressUI.stop();
            let indices = [];
            for (let index in _stats.result.indices) {
                indices.push({index: index, selected: true});
            }
            indices.sort((_a,_b) => { return _a.index.localeCompare(_b.index)});
            this.setState(() => {
                return {indices : indices};
            });
        });
    }

    onSnapshotNameChanged(_val) {
        return this.setState(() => {
            return {snapshotName: _val};
        });
    }

    onIndexChanged(_index, _val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.indices));
            for(let i = 0; i < copy.length; i++) {
                if (copy[i].index === _index) {
                    copy[i].selected = _val;
                }
            }
            return {indices : copy};
        });
    }

    onCreateButton() {
        if (this.state.snapshotName === "") {
            Notification.error({title: "Error", description: "Please enter a name."});
            return;
        }
        let backupIndices = [];
        for (let i = 0; i < this.state.indices.length; i++) {
            if (this.state.indices[i].selected === true) {
                backupIndices.push(this.state.indices[i].index);
            }
        }
        let payload = {
            indices : backupIndices
        };
        const snapshotName = this.state.snapshotName;
        const repoName = this.props.urlParams.repository;
        return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
            method: "PUT", path:"/_snapshot/"+repoName+"/"+snapshotName, contentType:"Application/json", payload:JSON.stringify(payload)}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                Notification.error({title: "Error", description: _res.errorText});
                return;
            }
            Notification.success({title: "Success", description: "Snapshot initialized."});

        });
    }

    render() {
        let indicesSelection = [];
        if (this.state.indices) {
            for(let i = 0; i < this.state.indices.length; i++) {
                indicesSelection.push(<Checkbox key={"idx_"+i} checked={this.state.indices[i].selected} onChange={(_val) => {this.onIndexChanged(this.state.indices[i].index, _val);}}>{this.state.indices[i].index}</Checkbox>);
            }
        }
        return(
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>Create Backup</h4>}>
                    <SimpleProgresUI ref={this.progressUI.getRef()}/>
                    <ConditionalFragment condition={this.state.indices && this.state.indices.length > 0}>
                        <HelpBlock>
                            Selected Backup Repository
                        </HelpBlock>
                        <Input disabled={true} type={"text"} value={this.props.urlParams.repository} />
                        <HelpBlock>
                            Snapshot Name
                        </HelpBlock>
                        <Input placeholder={"Enter snapshot name..."} type={"text"} value={this.state.snapshotName} onChange={this.onSnapshotNameChanged} />
                        {indicesSelection}
                        <Button appearance={"primary"} onClick={this.onCreateButton}>Create Backup</Button>
                    </ConditionalFragment>
                </Panel>
            </Container>
        );
    }
}

export default
withNavigation(
    withPermission(()=> {return AnalyticsConnection.hasPermission("admin")},
        withURLParams(ESCreateBackupPage)
    )
);
