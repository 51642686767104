import EventBus from "../common/EventBus";
import MeteorNavigation from "../common/MeteorNavigation";
import {Icon} from "rsuite";
import MeteorRoutes from "../common/MeteorRoutes";
import BuildContext from "./BuildContext";
import AnalyticsConnection from "../analytics/AnalyticsConnection";
import GamePagesContext from "../analytics/GamePagesContext";
import CustomAnalyticsPage from "../analytics/CustomAnalyticsPage";
import BuildsPage from "./BuildsPage";
import BuildPage from "./BuildPage";

class FirebuildContext {
    constructor() {
        this.onFirebuildAuthenticationChanged = this.onFirebuildAuthenticationChanged.bind(this);
        this.updateNavigation = this.updateNavigation.bind(this);
        this.updateGameNavigation = this.updateGameNavigation.bind(this);

        EventBus.registerHandler("FIREBUILD_AUTHENTICATION_CHANGED", this.onFirebuildAuthenticationChanged);
        this.updateNavigation();
    }

    onFirebuildAuthenticationChanged(_data) {
        BuildContext.clearCache();
        this.updateNavigation();
    }

    updateNavigation() {
        this.updateGameNavigation();
    }


    updateGameNavigation() {
        let node = MeteorNavigation.getNode("Builds");
        if (!node) {
            node = MeteorNavigation.addNode("Builds", <Icon icon={"gitlab"}/>)
        }
        node.clearChilds();
        if (!AnalyticsConnection.isAuthenticated()) {
            return; // fast out
        }
        node.setSyncMode(true);
        MeteorRoutes.addRoute("/firebuild/project/:projectId/buildType/:buildType", BuildsPage);
        MeteorRoutes.addRoute("/firebuild/project/:projectId/build/:buildId", BuildPage);

        return BuildContext.getProjects((_err, _projects) => {
            if (_err) {
                node.addNode("No Projects Found");
                node.setSyncMode(false);
                return;
            };
            let projectCounter = 0;
            _projects.sort((_a,_b) => {return _a.sortOrder-_b.sortOrder;});
            for(let projectIdx = 0; projectIdx < _projects.length; projectIdx++) {
                let currentProject = _projects[projectIdx];
                if (currentProject.hidden !== true) {
                    if (projectCounter > 0) {
                        node.addDivider();
                    }
                    let projectNode = node.addNode(currentProject.displayName, null);
                    projectNode.addNode("Devel", null, "/firebuild/project/"+currentProject.projectId+"/buildType/devel");
                    projectNode.addNode("Stable", null, "/firebuild/project/"+currentProject.projectId+"/buildType/stable");
                    projectNode.addNode("Release", null, "/firebuild/project/"+currentProject.projectId+"/buildType/release");
                    projectCounter += 1;
                }
            }
            node.setSyncMode(false);
        });
    }


}

const instance = new FirebuildContext();
export default instance;