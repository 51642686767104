import Logger from "../../common/Logger";
import * as Config from "../../config";
const objectHash = require("object-hash");
class GraphCache {
    getHash(_id, _props) {
        return objectHash({id: _id, props: _props});
    }

    cleanup(_needed) {
        const now = Math.floor(Date.now() / 1000);
        let freedStorage = 0;
        let keyLookup = {};
        for(let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            let text = localStorage.getItem(key);
            let obj = null;
            try {
                obj = JSON.parse(text);
            } catch (ex) {}
            if (obj !== null && obj.ts && typeof obj.ts === "number") {
                if (obj.ts + (60*60) < now) {
                    freedStorage += text.length;
                    localStorage.removeItem(key);
                } else {
                    keyLookup[key] = { ts: obj.ts, length: text.length};
                }
            }
        }
        if (freedStorage >= _needed) {
            Logger.logInfo("Graph-Cache", "Cleaned up: " + freedStorage);
            return true;
        }
        let sortedKeys = [];
        for(let key in keyLookup) {
            sortedKeys.push({key : key, ts : keyLookup[key].ts, length: keyLookup[key].length});
        }
        sortedKeys.sort((_a,_b) => {return _a.ts - _b.ts});
        for(let i = 0; i < sortedKeys.length; i++) {
            const current = sortedKeys[i];
            localStorage.removeItem(current.key);
            freedStorage += current.length;
            if (freedStorage >= _needed) {
                Logger.logInfo("Graph-Cache", "Cleaned up: "+freedStorage);
                return true;
            }
        }
        Logger.logErr("Graph-Cache","Cleanup failed to free "+freedStorage);
        return false;
    }

    storeData(_hash, _data) {
        const data = {
            ts: Math.floor(Date.now() / 1000),
            payload : _data
        };
        let text = JSON.stringify(data);
        try {
            localStorage.setItem(_hash, text);
        } catch (ex) {
            Logger.logErr("Graph-Cache","STORE failed "+_hash)
            const successful = this.cleanup(text.length);
            if (successful) {
                try {
                    localStorage.setItem(_hash, text);
                    Logger.logDebug("Graph-Cache","STORE "+_hash)
                } catch (ex) {
                    Logger.logErr("Graph-Cache","STORE failed "+_hash)
                }
                return;
            }
        }
        Logger.logDebug("Graph-Cache","STORE "+_hash);
    }

    getData(_hash) {
        if (!Config.useCaching) {
            Logger.logDebug("Graph-Cache","MISS (forced) "+_hash);
            return null;
        }
        let text = localStorage.getItem(_hash);
        let obj = null;
        try {
            obj = JSON.parse(text);
        } catch (ex) {}
        if (obj !== null) {
            const now = Math.floor(Date.now() / 1000);
            if (obj.ts && typeof obj.ts === "number" && obj.ts + (60*60) < now) {
                Logger.logDebug("Graph-Cache","MISS (outdated) "+_hash);
                localStorage.removeItem(_hash);
                return null;
            }
            Logger.logDebug("Graph-Cache","HIT "+_hash);
            return obj.payload;
        }
        Logger.logDebug("Graph-Cache","MISS "+_hash);
        return null;
    }

}

const instance = new GraphCache();
export default instance;