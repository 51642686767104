
function isStringArray(_list) {
    if (Array.isArray(_list)) {
        for(let i = 0; i < _list.length; i++) {
            if (typeof _list[i] !== "string") {
                return false;
            }
        }
        return true;
    }
    return false;
}

function convertDateKey(_key, _value) {
    if (_key === "day" || _key === "install_date") {
        if (typeof _value === "string") {
            let tokens = _value.split("-");
            if (tokens.length === 3) {
                const year = parseInt(tokens[0],10);
                const month = parseInt(tokens[1], 10);
                const day = parseInt(tokens[2], 10);
                let ts = Date.UTC(year, month-1, day);
                return ts/1000;
            }
        }
    }
    return _value;
}

function buildESTermFilter(_key, _value) {
    let filter = {};
    if (isStringArray(_value)) {
        let containsWildCard = false;
        for (let i = 0; i < _value.length; i++) {
            if (_value[i].indexOf("*") >= 0) {
                containsWildCard = true;
                break;
            }
        }
        if (containsWildCard) { // or query
            filter.bool = {
                should : []
            };
            for (let i = 0;i < _value.length; i++) {
                const f = buildESFilter(_key, convertDateKey(_key, _value[i]));
                filter.bool.should.push(f)
            }
        } else {
            filter.terms = {};
            filter.terms[_key] = convertDateKey(_key, _value);
        }
    } else {
        filter.term = {};
        filter.term[_key] = {value : convertDateKey(_key, _value)};
    }
    return filter;
}

function buildESPrefixFilter(_key, _value) {
    let filter = { prefix : {}};
    filter.prefix[_key] = {value : _value.substr(0, _value.length-1)};
    return filter;
}

function buildESWildcardFilter(_key, _value) {
    let filter = { wildcard : {}};
    filter.wildcard[_key] = {value : _value};
    return filter;
}

function buildESRangeFilter(_key, _from, _to) {
    let filter = { range : {}};
    filter.range[_key] = {};
    if (_from !== undefined && _from !== null) {
        filter.range[_key].gte = convertDateKey(_key, _from);
    }
    if (_to !== undefined && _to !== null) {
        filter.range[_key].lte = convertDateKey(_key, _to);
    }
    return filter;
}


function buildESFilter(_key, _condition) {
    if (isStringArray(_condition)) {
        return buildESTermFilter(_key, _condition);
    } else if (typeof _condition === "string" && _condition.indexOf("*") >= 0 && _condition.indexOf("*") !== _condition.length-1) {
        return buildESWildcardFilter(_key, _condition);
    } else if (typeof _condition === "string" && _condition[_condition.length-1] === "*") {
        return buildESPrefixFilter(_key, _condition);
    } else if (typeof _condition === "string" || typeof  _condition === "number" || typeof  _condition === "boolean") {
        return buildESTermFilter(_key, _condition);
    } else if (_condition.from !== undefined || _condition.to !== undefined) {
        return buildESRangeFilter(_key, _condition.from, _condition.to);
    }
}

class ESQueryUtil {
    static buildFilterQuery(_filters) {
        let filters = [];
        for(let field in _filters) {
            if (field !== undefined && _filters[field] !== undefined) {
                const filterObject = buildESFilter(field, _filters[field]);
                if (filterObject !== undefined && filterObject !== null) {
                    filters.push(filterObject);
                }
            }
        }
        return filters;
    }
}

export default ESQueryUtil;