
import * as Config from "../config";

function format2(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return _num;
}

const severityLookup = ["DEBUG","INFO","WARNING","ERROR"];
function doLog(_type, _severity, ...args) {
    if (Config.isDebug === true) {
        let isAllowed = true;
        if (Config.loggerSettings[_type] !== undefined && _severity < Config.loggerSettings[_type]) {
            isAllowed = false;
        }

        if (isAllowed) {
            const now = new Date();
            const date = format2(format2(now.getHours())+":"+format2(now.getMinutes())+":"+format2(now.getSeconds()));
            console.log("["+date+"] ["+_type+"] ["+severityLookup[_severity]+"]",...args);
        }
    }

}

class Logger {
    logDebug (_type, ...args) {
        doLog(_type, 0, ...args);
    }

    logInfo (_type, ...args) {
        doLog(_type, 1, ...args);
    }

    logWarn (_type, ...args) {
        doLog(_type, 2, ...args);
    }

    logErr (_type, ...args) {
        doLog(_type, 3, ...args);
    }
}

const instance = new Logger();
export default instance;