import LRUMemCache from "./LRUMemCache";
import Logger from "./Logger";
import * as Config from "../config";

const objectHash = require("object-hash");

let globalAsyncCallbacks = {};

let lruDataCache = new LRUMemCache("Analytics-Cache", 50*1024 * 1024); // 50MB

function AsnycContext(_sendFn, _cancelFn) {
    let wrapper = {};

    let tempData = {};
    let callback = null;

    let inflightRequests = [];

    let forceUncached = false;

    wrapper.disableCache = () => {
        forceUncached = true;
    }

    function checkForCompletion() {
        if (callback === null) {
            return;
        }
        for(let key in tempData) {
            if (tempData[key].isRunning === true) {
                return;
            }
        }
        for(let key in tempData) {
            if (tempData[key].error) {
                return callback(tempData[key].error);
            }
        }

        return callback(null, tempData);
    }

    wrapper.sendAsync = (_id, ..._params) => {
        return wrapper.sendAsync_internal(_id, Config.useCaching, ..._params);
    }

    wrapper.sendAsyncUncached = (_id, ..._params) => {
        return wrapper.sendAsync_internal(_id, false, ..._params);
    }

    wrapper.sendAsync_internal = (_id, _cached, ..._params) => {
        if (forceUncached === true) {
            _cached = false;
        }
        tempData[_id] = {isRunning : true};
        let hash = objectHash(_params);
        let needsSend = false;
        if (!globalAsyncCallbacks[hash]) {
            globalAsyncCallbacks[hash] = {requestId: null, callbacks: []};
            needsSend = true;
        }
        let callback =  (_err, _res) => {
            tempData[_id] = {isRunning: false, error: _err, result : _res};
            checkForCompletion();
        };
        inflightRequests.push({hash: hash, callback: callback});
        globalAsyncCallbacks[hash].callbacks.push(callback);
        if (needsSend) {
            const handler = (_err, _res) => {
                if (globalAsyncCallbacks[hash] === undefined) {
                    Logger.logDebug("Async-API","Ignoring cancelled callbacks : ", hash);
                    return;
                }
                let callbackList = globalAsyncCallbacks[hash].callbacks;
                Logger.logDebug("Async-API","Calling "+callbackList.length+" callbacks : ", hash);
                delete globalAsyncCallbacks[hash];
                for(let i = 0; i < callbackList.length; i++) {
                    let errClone;
                    if (_err !== undefined) {
                        try {
                            errClone = JSON.parse(JSON.stringify(_err));
                        } catch (ex) {
                            Logger.logErr("Async-API", "Could not clone ErrorResponse: ",_err);
                        }
                    }
                    let resClone;
                    if (_res !== undefined) {
                        try {
                            resClone = JSON.parse(JSON.stringify(_res));
                        } catch (ex) {
                            Logger.logErr("Async-API", "Could not clone Response: ",_res);
                        }
                    }
                    callbackList[i](errClone, resClone);
                }
            }
            if (_cached !== true) {
                _params.noCache = true;
            }
            const cached = lruDataCache.get(hash);
            if (cached && _params.noCache !== true) {
                return handler(null, cached);
            }
            Logger.logDebug("Async-API","Sending request: ", hash);
            const requestId = _sendFn(..._params, (_err, _res) => {
                if (_err) {
                    return handler(_err, _res);
                }
                lruDataCache.store(hash, _res);
                return handler(_err, _res);
            });
            if (globalAsyncCallbacks[hash]) {
                globalAsyncCallbacks[hash].requestId = requestId;
            }
            return;
        } else {
            Logger.logDebug("Async-API","Attached to active request: ", hash);
        }
    };

    wrapper.cancelAll = () => {
        for(let i = 0; i < inflightRequests.length; i++) {
            const current = inflightRequests[i];
            if (globalAsyncCallbacks[current.hash]) {
                let idx = globalAsyncCallbacks[current.hash].callbacks.indexOf(current.callback);
                if (idx >= 0) {
                    globalAsyncCallbacks[current.hash].callbacks.splice(idx,1);
                }
                if (globalAsyncCallbacks[current.hash].callbacks.length === 0) {
                    _cancelFn(globalAsyncCallbacks[current.hash].requestId);
                    delete globalAsyncCallbacks[current.hash];
                }
            }
        }
    }

    wrapper.waitForCompletion = (_cb) => {
        callback = _cb;
        checkForCompletion();
    }

    return wrapper;
}

export default AsnycContext;