import React from "react";
import {HelpBlock, Input, Button, Modal, InputGroup} from "rsuite";

class NewESRepositoryModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            bucket: "",
            region: "",
            role:""
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    show(_callback) {
        return this.setState(() => {
            return {
                open: true,
                name: "",
                bucket: "",
                region: "",
                role:"",
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onValueChanged(_id, _val) {
        return this.setState(()=> {
            let obj = {};
            obj[_id] = _val;
            return obj;
        });
    }

    onSave() {
        if(typeof this.state.callback === "function") {
            let config = {
                name: this.state.name,
                bucket : this.state.bucket,
                region : this.state.region,
                role_arn : this.state.role
            };

            this.state.callback(true, config);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    render() {
        const nameWidth = "55px";
        return (
            <Modal show={this.state.open} size={"xs"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        New Repository
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        Please enter the configuration of the repository.
                    </HelpBlock>
                    <InputGroup>
                        <InputGroup.Addon><div style={{width:nameWidth}}>Name</div></InputGroup.Addon>
                        <Input placeholder={"Enter Repository Name..."} type={"text"} value={this.state.name} onChange={(_val) => {this.onValueChanged("name", _val)}} />
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroup.Addon><div style={{width:nameWidth}}>S3 Bucket</div></InputGroup.Addon>
                        <Input placeholder={"Enter S3 Bucket..."} type={"text"} value={this.state.bucket} onChange={(_val) => {this.onValueChanged("bucket", _val)}} />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Addon><div style={{width:nameWidth}}>Region</div></InputGroup.Addon>
                        <Input placeholder={"Enter Region..."} type={"text"} value={this.state.region} onChange={(_val) => {this.onValueChanged("region", _val)}} />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Addon><div style={{width:nameWidth}}>Role ARN</div></InputGroup.Addon>
                        <Input placeholder={"Enter Role ARN..."} type={"text"} value={this.state.role} onChange={(_val) => {this.onValueChanged("role", _val)}} />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onSave} appearance="primary">
                        Register Repository
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default NewESRepositoryModal;