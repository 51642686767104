import React, {Fragment} from "react";
import {Notification, Button, Table, HelpBlock, Panel, Icon, IconButton} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import * as TimeUtil from "../../common/TimeUtil";
import TablePagination from "rsuite/es/Table/TablePagination";
import SnapshotNameModal from "./SnapshotNameModal";
import DeleteSnapshotModal from "./DeleteSnapshotModal";
import RestoreSnapshotModal from "./RestoreSnapshotModal";
import AnalyticsConnection from "../AnalyticsConnection";

class ESSnapshotUI extends React.Component {
    static getDerivedStateFromProps(_props, _state) {
        if (_state.selectedRepo !== _props.repository) {
            if (_props.repository === null || _props.repository === undefined) {
                return {
                    selectedRepo : _props.repository,
                    fetchedRepo : null,
                    snapshots: null,
                    page: 1
                };
            } else {
                return {
                    selectedRepo : _props.repository,
                    snapshots: null,
                    page: 1
                };
            }
        }
        return null;
    }

    constructor(_props) {
        super(_props);

        this.state = {};
        this.progressUI = React.createRef();
        this.snapshotNameModal = React.createRef();
        this.restoreSnapshotModal = React.createRef();
        this.deleteSnapshotModal = React.createRef();

        this.loadSnapshots = this.loadSnapshots.bind(this);
        this.onRefreshSnapshots = this.onRefreshSnapshots.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.onCreateSnapsotButton = this.onCreateSnapsotButton.bind(this);
        this.onRestoreSnapshotButton = this.onRestoreSnapshotButton.bind(this);
        this.onDeleteSnapshotButton = this.onDeleteSnapshotButton.bind(this);
    }

    componentDidUpdate() {
        this._isMounted = true;
        if (this.state.selectedRepo && this.state.selectedRepo !== this.state.fetchedRepo) {
            this.loadSnapshots();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onPageChanged(_val) {
        return this.setState(() => {
            return {page: _val}
        });
    }

    onCreateSnapsotButton() {
        this.snapshotNameModal.current.show((_save, _name) => {
            if (_save) {
                const repoName = this.state.selectedRepo;
                return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
                    method: "PUT", path:"/_snapshot/"+repoName+"/"+_name, contentType:"Application/json", payload:"{}"}, (_err, _res) => {
                    if (!this._isMounted) { return; }
                    if (_err) {
                        Notification.error({title: "Error", description: _res.errorText});
                        return;
                    }
                    Notification.success({title: "Success", description: "Snapshot initialized."});
                    this.loadSnapshots(true);
                });
            }
        });
    }

    onRestoreSnapshotButton(_snapshot) {
        this.restoreSnapshotModal.current.show(_snapshot.snapshot, _snapshot.indices, (_delete, _indices) => {
            if (_delete) {
                const repoName = this.state.selectedRepo;
                let payload = {
                    indices : _indices.join(",")
                };
                return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
                    method: "POST", path:"/_snapshot/"+repoName+"/"+_snapshot.snapshot+"/_restore", contentType:"Application/json", payload:JSON.stringify(payload)}, (_err, _res) => {
                    if (!this._isMounted) { return; }
                    Notification.success({title: "Success", description: "Snapshot restore started."});
                    this.loadSnapshots(true);
                });
            }
        });
    }

    onDeleteSnapshotButton(_snapshot) {
        this.deleteSnapshotModal.current.show(_snapshot.snapshot, (_delete) => {
            if (_delete) {
                const repoName = this.state.selectedRepo;
                return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
                    method: "DELETE", path:"/_snapshot/"+repoName+"/"+_snapshot.snapshot, contentType:"Application/json", payload:"{}"}, (_err, _res) => {
                    if (!this._isMounted) { return; }
                    Notification.success({title: "Success", description: "Snapshot deleted."});
                    this.loadSnapshots(true);
                });
            }
        });
    }

    onRefreshSnapshots() {
        this.loadSnapshots(true);
    }

    loadSnapshots(_force) {
        if (_force === true || (this.state.selectedRepo !== null && this.state.selectedRepo !== undefined)) {

            this.progressUI.current.start("Retrieving Snapshots...");
            const repoName = this.state.selectedRepo;
            return AnalyticsConnection.sendAuthenticated({type:"admin",subType: "esRequest",
                method:"GET", path:"/_snapshot/"+repoName+"/_all", contentType:"Application/json", payload:"{}"}, (_err, _res) => {
                if (!this._isMounted) { return; }
                if (_err) {
                    this.progressUI.current.error(_err);
                    return;
                }
                this.progressUI.current.stop();
                let snapshots = _res.result.snapshots;
                for(let i = 0; i < snapshots.length; i++) {
                    let d = new Date(snapshots[i].start_time);
                    if (d) {
                        snapshots[i].start_time = TimeUtil.formatLocalDateAndTime(d);
                    }
                }
                snapshots.sort((_a, _b) => {return _b.start_time_in_millis - _a.start_time_in_millis;});
                this.setState(() => {
                    return {
                        fetchedRepo : repoName,
                        snapshots : snapshots
                    };
                })
            });
        }
    }

    render() {
        const pStart = 10 * (this.state.page-1);
        const pEnd = pStart+10;
        return(
            <Panel bordered shaded header={<h6>Elastic Search Snapshots</h6>}>
                <SnapshotNameModal ref={this.snapshotNameModal}/>
                <RestoreSnapshotModal ref={this.restoreSnapshotModal}/>
                <DeleteSnapshotModal ref={this.deleteSnapshotModal}/>
                <SimpleProgresUI ref={this.progressUI}/>
                {(()=>{
                    if (this.state.selectedRepo === null || this.state.selectedRepo === undefined) {
                        return (<HelpBlock>Please select an Elastic Search Repository.</HelpBlock>);
                    } else if (this.state.snapshots){
                        let filtered = this.state.snapshots.filter((v,i) => {return i >= pStart && i < pEnd;});
                        return(
                            <Fragment>
                                <Button appearance={"primary"} href={"/analytics/db/backup/"+this.state.selectedRepo+"/create"}><Icon icon={"save"}/>&nbsp;Create New Snapshot</Button>
                                &nbsp;&nbsp;
                                <Button appearance={"primary"} onClick={this.onRefreshSnapshots}><Icon icon={"refresh2"}/></Button>
                                <br/>
                                <Table data={filtered} autoHeight={true}>
                                    <Table.Column resizable width={150}>
                                        <Table.HeaderCell>Time</Table.HeaderCell>
                                        <Table.Cell dataKey={"start_time"}/>
                                    </Table.Column>
                                    <Table.Column resizable>
                                        <Table.HeaderCell>State</Table.HeaderCell>
                                        <Table.Cell dataKey={"state"}/>
                                    </Table.Column>
                                    <Table.Column resizable width={300}>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.Cell dataKey={"snapshot"}/>
                                    </Table.Column>
                                    <Table.Column resizable>
                                        <Table.HeaderCell>ES Version</Table.HeaderCell>
                                        <Table.Cell dataKey={"version"}/>
                                    </Table.Column>
                                    <Table.Column width={200}>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                        <Table.Cell>
                                            {(_rowData)=> {
                                                return (
                                                    <Fragment>
                                                        <span>
                                                            <IconButton color="red" size="xs" icon={<Icon icon={"trash"}/>} onClick={() => {this.onDeleteSnapshotButton(_rowData)}}/>
                                                        </span>
                                                        <span>
                                                            <Button color={"green"} size="xs" style={{marginLeft:"10px"}} onClick={() => {this.onRestoreSnapshotButton(_rowData)}}>Restore</Button>
                                                        </span>
                                                    </Fragment>
                                                );
                                            }}
                                        </Table.Cell>
                                    </Table.Column>
                                </Table>
                                <TablePagination displayLenth={10} total={this.state.snapshots.length}
                                                 page={this.state.page} showLengthMenu={false}
                                                 onChangePage={this.onPageChanged} activePage={this.state.page}
                                />
                            </Fragment>
                        );
                    }
                })()}
            </Panel>
        );
    }

}

export default ESSnapshotUI;