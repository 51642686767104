import React, {Fragment} from "react";
import {Button, Container, Input, InputGroup, Panel, Table} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import TablePagination from "rsuite/es/Table/TablePagination";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";

class ESSQLPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            query : "",
            page: 1,
            pageLength: 10,
        };

        this.progressUI = React.createRef();

        this.onQueryChanged = this.onQueryChanged.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.onQueryButton = this.onQueryButton.bind(this);
        this.renderResultTable = this.renderResultTable.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onQueryChanged(_val) {
        return this.setState(() => {
            return {query: _val};
        });
    }

    onPageChanged(_val) {
        return this.setState(() => {
            return {page: _val};
        });
    }

    onQueryButton() {
        this.progressUI.current.start("Processing query...");
        let payload = {query: this.state.query};
        return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
            method: "POST", path:"/_opendistro/_sql", contentType:"Application/json", payload: JSON.stringify(payload)}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            this.progressUI.current.stop();
            let colNames = [];
            for (let i = 0; i < _res.result.schema.length; i++) {
                colNames.push(_res.result.schema[i].name);
            }
            let queryResult = [];
            for(let i = 0; i < _res.result.datarows.length; i++) {
                let current = _res.result.datarows[i];
                let dataObj = {};
                for (let j = 0; j < current.length; j++) {
                    dataObj[colNames[j]] = current[j];
                }
                queryResult.push(dataObj);
            }
            this.setState(() => {
                return {
                    columns: colNames,
                    queryResult: queryResult
                };
            });
        })
    }

    renderResultTable() {
        let columns = [];
        if (this.state.columns) {
            for (let i = 0; i < this.state.columns.length; i++) {
                const colName = this.state.columns[i];
                columns.push(
                    <Table.Column resizable width={200}>
                        <Table.HeaderCell>{colName}</Table.HeaderCell>
                        <Table.Cell dataKey={colName}/>
                    </Table.Column>
                );
            }
        }
        if (columns.length > 0) {
            let start = (this.state.page-1) * this.state.pageLength;
            let end = start + this.state.pageLength;
            let filtered = this.state.queryResult.slice(start, end);
            return (
                <Fragment>
                    <Table data={filtered} autoHeight>
                        {columns}
                    </Table>
                    <TablePagination displayLength={this.state.pageLength} total={this.state.queryResult.length}
                                     page={this.state.page} showLengthMenu={false}
                                     onChangePage={this.onPageChanged} activePage={this.state.page}
                    />
                </Fragment>
        )
        }
        return null;
    }

    render() {
        return (
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>SQL Query</h4>}>
                    <Panel bordered shaded header={<h6>Query</h6>} style={{width:"1000px"}}>
                        <InputGroup>
                            <InputGroup.Addon>Query</InputGroup.Addon>
                            <Input value={this.state.query} onChange={this.onQueryChanged}/>
                        </InputGroup>
                    </Panel>
                    <br/>
                    <Button appearance={"primary"} onClick={this.onQueryButton}>Send Query</Button>
                </Panel>
                <SimpleProgresUI ref={this.progressUI}/>
                {this.renderResultTable()}
            </Container>
        );
    }
}

export default
    withNavigation(
        withPermission(()=>{return AnalyticsConnection.hasPermission("admin")},
            ESSQLPage
        )
    )
;