import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import more from 'highcharts/modules/sankey';
import Highcharts from "highcharts";
import {SelectPicker} from "rsuite";
more(Highcharts);

function removeDuplicateEvents(_list) {
    let memory = {};
    let result = [];
    for (let i = 0; i < _list.length; i++) {
        const name = _list[i].name;
        if (!memory[name]) {
            memory[name] = true;
            result.push(_list[i]);
        }
    }
    return result;
}

function addTransition(_transitions, _from, _to) {
    if (!_transitions[_from]) {
        _transitions[_from] = {count :0, next: {} };
    }
    _transitions[_from].count += 1;
    if (!_transitions[_from].next[_to]) {
        _transitions[_from].next[_to] = 0;
    }
    _transitions[_from].next[_to] += 1;
}

class EventFlow {
    constructor() {
        this.setGraphV2 = this.setGraphV2.bind(this);
        this.getGraphData = this.getGraphData.bind(this);
        this.onEventChanged = this.onEventChanged.bind(this);
    }

    static getUUID() {
        return "523d1b6f-e2c0-463a-add4-3f0b810bf3cf";
    }

    getId() {
        return EventFlow.getUUID();
    }

    setGraphV2(_graph) {
        this.graph = _graph;
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"events", name:"Event Value List", type:"json"},
            {id:"filter", name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 2;
    }

    static getHeightRatio() {
        return "50%";
    }

    onEventChanged(_event) {
        let state = this.graph.getComponentState();
        if (state) {
            let outgoing = state.transitions[_event];
            let incoming = state.reverseTransitions[_event];
            let list = [];
            let nodes = {};
            for (let key in incoming.next) {
                list.push({from:"I_"+key, to:"__"+_event, name: key, weight: incoming.next[key], percentage: incoming.next[key]/incoming.count});
                nodes["I_"+key] = {id: "I_"+key, name: key, weight: incoming.next[key], percentage: incoming.next[key]/incoming.count};
            }
            for (let key in outgoing.next) {
                list.push({from:"__"+_event, to:"O_"+key, name: key, weight: outgoing.next[key], percentage: outgoing.next[key]/outgoing.count});
                nodes["O_"+key] = {id: "O_"+key, name: key, weight: outgoing.next[key], percentage: outgoing.next[key]/outgoing.count};
            }
            let nodeList = [];
            for (let key in nodes) {
                nodeList.push(nodes[key]);
            }
            nodeList.push({id:"__"+_event, name:_event, percentage: 1, weight: outgoing.count});
            list.sort((_a,_b) => {return _b.weight-_a.weight;});
            let graphData = {
                chart : {type: "sankey"},
                series : [{data: list, nodes: nodeList}],
            };
            this.graph.setGraphData(graphData);
        }
    }

    renderExtraUI() {
        let state = this.graph.getComponentState();
        let data = [];
        if (state && state.events) {
            for(let i = 0; i < state.events.length; i++) {
                data.push({value: state.events[i], label: state.events[i]});
            }
        }
        return (<SelectPicker data={data} block placeholder={"Please select an Event"} onChange={this.onEventChanged}/>);
    }

    getGraphData(_props, _cb) {
        let events = _props.events;
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        filter.name = events;
        ESContext.retrieveDocuments("event_v4", filter,["cfid","name","timestamp"], (_err, _documents) => {
            if (_err) {
                return _cb(_err);
            }
            let userData = {};
            for (let i = 0; i < _documents.length; i++) {
                const current = _documents[i];
                if (!userData[current.cfid]) {
                    userData[current.cfid] = [];
                }
                userData[current.cfid].push(current);
            }
            let transitions = {};
            let reverseTransitions = {};
            for(let userId in userData) {
                let current = userData[userId];
                current.sort((_a,_b) => {return _a.timestamp - _b.timestamp;});
                current = removeDuplicateEvents(current);
                for (let i = 0; i < current.length; i++) {
                    let startEvent = "Init";
                    if (i > 0) {
                        startEvent = current[i-1].name;
                    }
                    let endEvent = current[i].name;
                    addTransition(transitions, startEvent, endEvent);
                    addTransition(reverseTransitions, endEvent, startEvent);
                }
                if (current.length > 0) {
                    addTransition(transitions, current[current.length-1].name, "end");
                    addTransition(reverseTransitions,"end", current[current.length-1].name);
                }
            }
            let events = AnalyticsUtil.getDistinct(_documents, "name");
            events.sort();
            this.graph.updateComponentState((_prev) => {
                _prev.transitions = transitions;
                _prev.reverseTransitions = reverseTransitions;
                _prev.events = events;
                return _prev;
            });
            return _cb();
        });

    }

    static getXAxis() {}

    static getYAxis() {}

    static getTooltip() {
        return {
            formatter : function() {
                console.log(this);
                if (this.point && this.point.options && this.point.options.percentage && this.point.options.name) {
                    return this.point.options.name+": "+(Math.round(this.point.options.percentage*10000)/100)+"% ("+this.point.options.weight+" Users)";
                }
                return null;
            }
        };
    }
}

export default CachedGraphV2(EventFlow, HighChartsGraph);