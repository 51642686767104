import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";

class Installs_PieChart {
    static getUUID() {
        return "68172872-1264-41df-88a6-aaf0befbbabd";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"limitResults", name:"Limit to 10", type:"boolean"},
            {id:"withLegend",name:"Show Legend", type: "boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 0.5;
    }

    static getHeightRatio() {
        return "100%";
    }

    static calcInstalls(_installsData, _groupName, _groupValue) {
        let installData = _installsData;
        if (_groupName && _groupValue !== undefined) {
            installData = AnalyticsUtil.filterBy(_installsData, (_e) => _e[_groupName] === _groupValue);
        }
        let installCount = 0;
        for (let i = 0; i < installData.length; i++) {
            const current = installData[i];
            installCount += current.installs;
        }
        return {name: _groupValue, y: installCount, text: installCount+" Users"};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        ctx.sendAsync("installs","user_v4",["cardinality:user_id:installs"],[_props.group], filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installsData = _data.installs.result;
                let groups = AnalyticsUtil.getDistinct(installsData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = Installs_PieChart.calcInstalls(installsData, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = Installs_PieChart.calcInstalls(installsData);
                    series.push(data);
                }
                series.sort((a,b) => {return b.y-a.y;});
                let MAX_RESULTS = 10;
                if (_props.limitResults === true && series.length >= MAX_RESULTS) {
                    let otherCount = 0;
                    let sum = 0;
                    for (let i = 0; i < series.length; i++) {
                        sum += series[i].y;
                        if (i >= (MAX_RESULTS)) {
                            otherCount += series[i].y;
                        }
                    }
                    series = series.slice(0,MAX_RESULTS);
                    let percent = Math.round((otherCount/sum)*10000)/100;
                    series.push({name: "other", y: otherCount, text: percent+"% ("+otherCount+" Users)"});
                }
                let graphData = {
                    chart : {type: "pie"},
                    plotOptions: { pie : { showInLegend: _props.withLegend}},
                    legend: {enabled: groups.length > 1},
                    series : [{data: series}],
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {}

    static getYAxis() {}

    static getTooltip() {
        return {
            formatter : function() {
                let percentage = (Math.round(this.point.percentage*100)/100)+"%";
                return "<b>"+this.point.name+":</b> "+percentage+" ("+this.point.text+")";
            }
        };
    }
}

export default CachedGraphV2(Installs_PieChart, HighChartsGraph);