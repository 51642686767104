import React, {Fragment} from "react";
import {Button, Col, Dropdown, Grid, Icon, IconButton, Panel, Popover, Row, Whisper} from "rsuite";
import EditGraphConfigModal from "./graphs/EditGraphConfigModal";
import ConditionalFragment from "../common/ConditionalFragment";
import {CreateNode, DeleteNode, GetNode, PushBackward, PushForward, SetNodeConfig} from "./UIModel";
import {GetGraphById, GetGraphList} from "./charts/ChartsUtil";

const objectHash = require("object-hash");

const NewElementPopover =  ({ onSelect, pullLeft, ...rest }) => {
    let graphs = GetGraphList();
    let menuItems = [];
    menuItems.push(<Dropdown.Item eventKey={{name: "panel"}}>Group</Dropdown.Item>)
    menuItems.push(<Dropdown.Item divider/>)
    for(let i = 0; i < graphs.length; i++) {
        const current = graphs[i];
        let children = [];
        for(let j = 0; j < current.charts.length; j++) {
            const chart = current.charts[j];
            children.push(
                <Dropdown.Item eventKey={chart}>
                    {chart.icon} {chart.name}
                </Dropdown.Item>
            )
        }
        menuItems.push(
            <Dropdown.Menu title={current.name} pullLeft={pullLeft}>
                {children}
            </Dropdown.Menu>
        );
    }
    return (
        <Popover {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                {menuItems}
            </Dropdown.Menu>
        </Popover>
    );
}

const NewElementButton = ({style, onSelect, pullLeft, ...rest}) => {
    const triggerRef = React.createRef();
    function handleSelectMenu(eventKey, event) {
        triggerRef.current.hide();
        if (typeof onSelect === "function") {
            onSelect(eventKey, event);
        }
    }
    return (
        <Whisper
            placement="bottomStart"
            trigger="click"
            triggerRef={triggerRef}
            speaker={<NewElementPopover onSelect={handleSelectMenu} pullLeft={pullLeft}/>}
        >
            <IconButton appearance={"primary"} icon={<Icon icon={"plus"}/>} style={style}/>
        </Whisper>
    );
}

class DynamicGraphUI extends React.Component {
    static getDerivedStateFromProps(_props, _state) {
        if (_props.uiLayout) {
            let hash = objectHash(_props.uiLayout);
            if (_state.uiLayoutHash !== hash) {
                return {
                    model : _props.uiLayout,
                    original : JSON.parse(JSON.stringify(_props.uiLayout)),
                    uiLayoutHash : hash,
                    hasChanges : false
                };
            }
        }
        return null;
    }

    constructor(_props) {
        super(_props);

        this.state = {};

        this.references = {};

        this.editGraphConfigModal = React.createRef();

        this.renderUIList = this.renderUIList.bind(this);
        this.renderPanel = this.renderPanel.bind(this);
        this.onPanelEdit = this.onPanelEdit.bind(this);
        this.onPanelAdd = this.onPanelAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onNewButton = this.onNewButton.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onDiscard = this.onDiscard.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onPushBackward = this.onPushBackward.bind(this);
        this.onPushForward = this.onPushForward.bind(this);
    }

    onSave() {
        if (typeof this.props.onSave === "function") {
            this.props.onSave(this.state.model)
        }
    }

    onDiscard() {
        this.setState((_state, _props) => {
            return {
                model : JSON.parse(JSON.stringify(_state.original)),
                hasChanges : false
            };
        })
    }

    onNewButton(_parentId, _val) {
        if (_val && _val.name && _val.name === "panel") {
            this.onPanelAdd(_parentId);
            return;
        }
        const graphId = _val.graph.prototype.getGraphId();
        const propertyList = _val.graph.prototype.getProps();
        this.editGraphConfigModal.current.show(graphId, propertyList, {}, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    let copy = {};
                    if (_state.model) {
                        copy = JSON.parse(JSON.stringify(_state.model));
                    }
                    return {
                        model : CreateNode(copy, _parentId, graphId, _config),
                        hasChanges : true
                    };
                });
            }
        });
    }

    onEdit(_id) {
        let uiNode = GetNode(this.state.model, _id);
        let graph = GetGraphById(uiNode.type);
        const propertyList = graph.prototype.getProps();
        this.editGraphConfigModal.current.show(uiNode.type,propertyList, uiNode.config, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    let copy = {};
                    if (_state.model) {
                        copy = JSON.parse(JSON.stringify(_state.model));
                    }
                    return {
                        model : SetNodeConfig(copy, _id, _config),
                        hasChanges: true
                    };
                });
            }
        });
    }

    onPanelAdd(_parentId) {
        this.editGraphConfigModal.current.show("Group",["title"], {}, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    let copy = {};
                    if (_state.model) {
                        copy = JSON.parse(JSON.stringify(_state.model));
                    }
                    return {
                        model : CreateNode(copy, _parentId, "panel", _config),
                        hasChanges: true
                    };
                });
            }
        });
    }

    onPanelEdit(_id, _config) {
        this.editGraphConfigModal.current.show("Group",["title"], _config, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    let copy = {};
                    if (_state.model) {
                        copy = JSON.parse(JSON.stringify(_state.model));
                    }
                    return {
                        model : SetNodeConfig(copy, _id, _config),
                        hasChanges: true
                    };
                });
            }
        });
    }

    onDelete(_id) {
        return this.setState((_state, _props) => {
            let copy = {};
            if (_state.model) {
                copy = JSON.parse(JSON.stringify(_state.model));
            }
            return {
                model : DeleteNode(copy, _id),
                hasChanges: true
            };
        });
    }

    onPushForward(_id) {
        return this.setState((_state, _props) => {
            let copy = {};
            if (_state.model) {
                copy = JSON.parse(JSON.stringify(_state.model));
            }
            return {
                model : PushForward(copy, _id),
                hasChanges: true
            };
        });
    }

    onPushBackward(_id) {
        return this.setState((_state, _props) => {
            let copy = {};
            if (_state.model) {
                copy = JSON.parse(JSON.stringify(_state.model));
            }
            return {
                model : PushBackward(copy, _id),
                hasChanges: true
            };
        });
    }

    renderPanel(_panel) {
        let header = (<h4>{_panel.config.title}</h4>);
        if (this.props.editMode) {
            header = (
                <h4>
                    {_panel.config.title}
                    <IconButton appearance={"primary"} icon={<Icon icon={"edit2"} />} onClick={()=>{this.onPanelEdit(_panel.id, _panel.config)}} style={{marginLeft:20}}/>
                    <IconButton appearance={"primary"} icon={<Icon icon={"backward"} />} onClick={()=>{this.onPushBackward(_panel.id)}} style={{marginLeft:20}}/>
                    <IconButton appearance={"primary"} icon={<Icon icon={"forward"} />} onClick={()=>{this.onPushForward(_panel.id)}} style={{marginLeft:20}}/>
                    <IconButton color="red" icon={<Icon icon={"trash2"} />} onClick={() => {this.onDelete(_panel.id)}} style={{float: "right", marginRight:20}}/>
                    <NewElementButton style={{float: "right", marginRight:10}} onSelect={(_graph) => {this.onNewButton(_panel.id, _graph);}} pullLeft/>
                </h4>);
        }
        return (
            <Fragment>
                <Panel bordered header={header}>
                    {this.renderUIList(_panel)}
                </Panel>
                <br/>
            </Fragment>
        );
    }

    renderUIList(_object) {
        let uiElems = [];
        if (_object.uiList) {
            for(let i = 0; i < _object.uiList.length; i++) {
                const current = _object.uiList[i];
                if (current.type === "panel") {
                    uiElems.push(
                        <Col xs={24} sm={24} md={24} lg={24}>
                            {this.renderPanel(current)}
                        </Col>
                    );
                } else {
                    const graph = GetGraphById(current.type);
                    if (graph !== null) {
                        if (!this.references[current.id]) {
                            this.references[current.id] = React.createRef();
                        }
                        const uiElem = React.createElement(graph, {
                            style: {marginBottom: 10},
                            config: current.config,
                            editMode: this.props.editMode,
                            onDelete: () => {this.onDelete(current.id)},
                            onEdit: () => {this.onEdit(current.id)},
                            onPushForward: () => {this.onPushForward(current.id)},
                            onPushBackward: () => {this.onPushBackward(current.id)},
                        });
                        let sizeFactor = graph.prototype.getGraphSize();
                        uiElems.push(
                            <Col xs={24*sizeFactor} sm={24*sizeFactor} md={12*sizeFactor} lg={12*sizeFactor}>
                                {uiElem}
                            </Col>
                        );
                    }
                }
            }
        }
        if (uiElems.length > 0) {
            return (
                <Grid fluid>
                    <Row>
                        {uiElems}
                    </Row>
                </Grid>
            )
        }
        return null;
    }

    render() {
        if (this.state.model) {

            return (
                <Fragment>
                    <EditGraphConfigModal ref={this.editGraphConfigModal}/>
                    <ConditionalFragment condition={this.props.editMode}>
                        <NewElementButton onSelect={(_graph) => {this.onNewButton(null, _graph);}} />
                        <ConditionalFragment condition={this.state.hasChanges}>
                            <Button appearance={"primary"} style={{marginLeft:30}} onClick={this.onSave}>Save Changes</Button>
                            <Button style={{marginLeft:10}} onClick={this.onDiscard}>Discard Changes</Button>
                        </ConditionalFragment>
                        <br/><br/>
                    </ConditionalFragment>
                    {this.renderUIList(this.state.model)}
                </Fragment>
            );
        }
        return null;
    }
}

export default DynamicGraphUI;