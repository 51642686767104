import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import LoginPage from "../meteor/LoginPage";
import MeteorContext from "../meteor/MeteorContext";
import EventBus from "./EventBus";
import MeteorRoutes from "./MeteorRoutes";
import DefaultPage from "../DefaultPage";
import ChangePasswordPage from "../meteor/ChangePasswordPage";

const PrivateRoute = ({ component: Component, extraParams: ExtraParams, ...rest }) => (
    <Route {...rest} render={(props) => (
        MeteorContext.isAuthenticated() === true
            ? <Component {...props} {...ExtraParams} />
            : <Redirect to='/pages/login' />
    )} />
)

class AppRoutes extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {updateCounter : 1}
        this.onNavigationChanged = this.onNavigationChanged.bind(this);
    }

    onNavigationChanged(event) {
        this.setState((_oldState, _props) => {
            return { updateCounter : _oldState.updateCounter +1};
        });
    }

    componentDidMount() {
        EventBus.registerHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    componentWillUnmount() {
        EventBus.unregisterHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    render() {
        let defaultPage = null;
        if (!MeteorContext.isAuthenticated()) {
            defaultPage = <Route component={LoginPage} />;
        } else {
            defaultPage = <Route component={DefaultPage} />;
        }

        let routes = [];
        let pureRoutes = MeteorRoutes.getRoutes();
        for (let i = 0; i < pureRoutes.length; i++) {
            const entry = pureRoutes[i];
            if (entry.isPublic === true) {
                routes.push(<Route key={"routepure_"+i} path={entry.path} exact component={entry.component} />)             ;
            } else {
                routes.push(<PrivateRoute key={"routepure_"+i} path={entry.path} exact component={entry.component} />);
            }
        }

        return (
            <Switch>
                <PrivateRoute path={"/account/changePassword"} exact component={ChangePasswordPage} />
                {routes}
                {defaultPage}
            </Switch>
        );
    }
}

export default AppRoutes;