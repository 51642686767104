import React, {Fragment} from "react";
import {Col, Dropdown, Grid, Icon, IconButton, Panel, Popover, Row, Whisper, Notification, Toggle} from "rsuite";
import EditGraphConfigModal from "./graphs/EditGraphConfigModal";
import ConditionalFragment from "../common/ConditionalFragment";
import {
    CopyLayout,
    CreateNode,
    DeleteNode,
    GetNode,
    PasteNodeDefinition,
    PushBackward,
    PushForward,
    SetNodeConfig
} from "./UIModel";
import {GetGraphById, GetGraphList, GetGraphUUID} from "./charts/ChartsUtil";
import Logger from "../common/Logger";
import ColdFireReactRef from "../common/ColdFireReactRef";

function buildMenu(_list, _pullLeft) {
    let children = [];
    for (let i = 0; i < _list.length; i++) {
        const current = _list[i];
        if (current.charts) {
            children.push(
                <Dropdown.Menu title={current.name} pullLeft={_pullLeft}>
                    {buildMenu(current.charts, _pullLeft)}
                </Dropdown.Menu>
            )
        } else {
            children.push(
                <Dropdown.Item eventKey={current}>
                    {current.icon} {current.name}
                </Dropdown.Item>
            )
        }
    }
    return children;
}

const NewElementPopover =  ({ onSelect, pullLeft, ...rest }) => {
    let graphs = GetGraphList();
    let menuItems = [];
    menuItems.push(<Dropdown.Item eventKey={{name: "panel"}}>Group</Dropdown.Item>)
    menuItems.push(<Dropdown.Item divider/>);
    menuItems.push(<Dropdown.Item eventKey={{name: "clipboard"}}>from Clipboard</Dropdown.Item>)
    menuItems.push(<Dropdown.Item divider/>);
    let graphMenu = buildMenu(graphs, pullLeft);
    menuItems = menuItems.concat(graphMenu);
    return (
        <Popover {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                {menuItems}
            </Dropdown.Menu>
        </Popover>
    );
}

const NewElementButton = ({style, onSelect, pullLeft, ...rest}) => {
    const triggerRef = React.createRef();
    function handleSelectMenu(eventKey, event) {
        triggerRef.current.hide();
        if (typeof onSelect === "function") {
            onSelect(eventKey, event);
        }
    }
    return (
        <Whisper
            placement="bottomStart"
            trigger="click"
            triggerRef={triggerRef}
            speaker={<NewElementPopover onSelect={handleSelectMenu} pullLeft={pullLeft}/>}
        >
            <IconButton appearance={"primary"} icon={<Icon icon={"plus"}/>} style={style}/>
        </Whisper>
    );
}

class DynamicGraphUIStateless extends React.Component {
    static getReference() {
        return new ColdFireReactRef(["exportPage"]);
    }
    constructor(_props) {
        super(_props);

        this.state = { panelStates : {}};

        this.references = {};

        this.editGraphConfigModal = React.createRef();

        this.renderUIList = this.renderUIList.bind(this);
        this.renderPanel = this.renderPanel.bind(this);
        this.onPanelEdit = this.onPanelEdit.bind(this);
        this.setPanelConfig = this.setPanelConfig.bind(this);
        this.onPanelAdd = this.onPanelAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onNewButton = this.onNewButton.bind(this);

        this.onEdit = this.onEdit.bind(this);
        this.onPushBackward = this.onPushBackward.bind(this);
        this.onPushForward = this.onPushForward.bind(this);
        this.onCopy = this.onCopy.bind(this);

        this.notifyUpdate = this.notifyUpdate.bind(this);

        this.getPanelState = this.getPanelState.bind(this);
        this.setPanelState = this.setPanelState.bind(this);
    }

    getPanelState(_id) {
        if(this.state.panelStates[_id] !== undefined) {
            return this.state.panelStates[_id];
        }
        return null;
    }

    setPanelState(_id, _val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.panelStates));
            copy[_id] = _val;
            return { panelStates : copy};
        });
    }

    notifyUpdate(_model) {
        if (typeof this.props.onUpdate === "function") {
            this.props.onUpdate(_model);
        }
    }

    onNewButton(_parentId, _val) {
        if (_val && _val.name && _val.name === "panel") {
            this.onPanelAdd(_parentId);
            return;
        }
        if (_val && _val.name && _val.name === "clipboard") {
            return navigator.clipboard.readText().then((_clipboard) => {
                let json = null;
                try {
                    json = JSON.parse(_clipboard);
                } catch (ex) {
                }
                if (json === null) {
                    Notification.error({title:"Couldn't parse JSON"})
                    return;
                } else {
                    return this.setState((_state, _props) => {
                        const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
                        const model = PasteNodeDefinition(copy, _parentId, json);
                        this.notifyUpdate(model);
                    });
                }

            });
        }
        const graphId = _val.graph.prototype.getGraphId();
        const propertyList = _val.graph.prototype.getProps();
        this.editGraphConfigModal.current.show(graphId, propertyList, {}, (_save, _config) => {
            if (_save) {
                const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
                const model = CreateNode(copy, _parentId, graphId, _config);
                this.notifyUpdate(model);
            }
        });
    }

    onEdit(_id) {
        let uiNode = GetNode(this.props.uiLayout, _id);
        let graph = GetGraphById(uiNode.type);
        const propertyList = graph.prototype.getProps();
        this.editGraphConfigModal.current.show(uiNode.type,propertyList, uiNode.config, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
                    const model = SetNodeConfig(copy, _id, _config);
                    this.notifyUpdate(model);
                });
            }
        });
    }

    onPanelAdd(_parentId) {
        this.editGraphConfigModal.current.show("Group",[{id:"title", name:"Title", type:"string"}], {}, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
                    const model = CreateNode(copy, _parentId, "panel", _config);
                    this.notifyUpdate(model);
                });
            }
        });
    }

    onPanelEdit(_id, _config) {
        this.editGraphConfigModal.current.show("Group",[{id:"title", name:"Title", type:"string"}], _config, (_save, _config) => {
            if (_save) {
                return this.setState((_state, _props) => {
                    const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
                    const model = SetNodeConfig(copy, _id, _config);
                    this.notifyUpdate(model);
                });
            }
        });
    }

    setPanelConfig(_id, _property, _value) {
        return this.setState((_state, _props) => {
            const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
            const config = GetNode(copy, _id).config;
            config[_property] = _value;
            const model = SetNodeConfig(copy, _id, config);
            this.notifyUpdate(model);
        });
    }

    onDelete(_id) {
        return this.setState((_state, _props) => {
            const copy =  JSON.parse(JSON.stringify(this.props.uiLayout));
            const model = DeleteNode(copy, _id);
            this.notifyUpdate(model);
        });
    }

    onPushForward(_id) {
        return this.setState((_state, _props) => {
            const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
            const model = PushForward(copy, _id);
            this.notifyUpdate(model);
        });
    }

    onPushBackward(_id) {
        return this.setState((_state, _props) => {
            const copy = JSON.parse(JSON.stringify(this.props.uiLayout));
            const model = PushBackward(copy, _id);
            this.notifyUpdate(model);
        });
    }

    onCopy(_id) {
        const layout = CopyLayout(this.props.uiLayout, _id);
        navigator.clipboard.writeText(JSON.stringify(layout));
        Notification.success({title: "Copied Layout"});
    }

    renderPanel(_panel) {
        let doRender = false;
        if (this.props.editMode) {
            doRender = true;
        } else {
            doRender = this.getPanelState(_panel.id);
            if (doRender === null) {
                doRender = _panel.config.defaultOpen;
            }
        }
        let header = (<h4>
            <Toggle checked={doRender} onChange={(_val) => {this.setPanelState(_panel.id, _val);}}/> &nbsp;
            {_panel.config.title}
        </h4>);
        if (this.props.editMode) {
            header = (
                <h4>
                    <Toggle checked={_panel.config.defaultOpen === true} onChange={(_val) => {this.setPanelConfig(_panel.id, "defaultOpen", _val);}}/> &nbsp;
                    {_panel.config.title}
                    <IconButton appearance={"primary"} icon={<Icon icon={"edit2"} />} onClick={()=>{this.onPanelEdit(_panel.id, _panel.config)}} style={{marginLeft:20}}/>
                    <IconButton appearance={"primary"} icon={<Icon icon={"backward"} />} onClick={()=>{this.onPushBackward(_panel.id)}} style={{marginLeft:20}}/>
                    <IconButton appearance={"primary"} icon={<Icon icon={"forward"} />} onClick={()=>{this.onPushForward(_panel.id)}} style={{marginLeft:20}}/>
                    <IconButton appearance={"primary"} icon={<Icon icon={"copy-o"} />} onClick={()=>{this.onCopy(_panel.id)}} style={{marginLeft:20}}/>
                    <IconButton color="red" icon={<Icon icon={"trash2"} />} onClick={() => {this.onDelete(_panel.id)}} style={{float: "right", marginRight:20}}/>
                    <NewElementButton style={{float: "right", marginRight:10}} onSelect={(_graph) => {this.onNewButton(_panel.id, _graph);}} pullLeft/>
                </h4>);
        }


        return (
            <Fragment>
                <Panel bordered header={header}>
                    <ConditionalFragment condition={doRender}>
                        {this.renderUIList(_panel)}
                    </ConditionalFragment>
                </Panel>
                <br/>
            </Fragment>
        );
    }

    renderUIList(_object) {
        let uiElems = [];
        if (_object.uiList) {
            let counter = 0;
            let rowElems = [];
            for(let i = 0; i < _object.uiList.length; i++) {
                const current = _object.uiList[i];
                if (current.type === "panel") {
                    if (rowElems.length > 0) {
                        uiElems.push(
                            <Row key={"idx_"+uiElems.length}>
                                {rowElems}
                            </Row>
                        );
                        rowElems = [];
                        counter = 0;
                    }
                    uiElems.push(
                        <Col key={"idx_"+uiElems.length} xs={24} sm={24} md={24} lg={24}>
                            {this.renderPanel(current)}
                        </Col>
                    );
                } else {
                    const graph = GetGraphById(current.type);
                    if (graph !== null) {
                        if (!this.references[current.id]) {
                            this.references[current.id] = React.createRef();
                        }
                        const uiElem = React.createElement(graph, {
                            style: {marginBottom: 10},
                            config: current.config,
                            parentFilter: this.props.filter,
                            dateSelection: this.props.dateSelection,
                            editMode: this.props.editMode,
                            onDelete: () => {this.onDelete(current.id)},
                            onEdit: () => {this.onEdit(current.id)},
                            onCopy: () => {this.onCopy(current.id)},
                            onPushForward: () => {this.onPushForward(current.id)},
                            onPushBackward: () => {this.onPushBackward(current.id)},
                        });
                        let sizeFactor = graph.prototype.getGraphSize();
                        counter += sizeFactor;
                        if (counter > 3) {
                            uiElems.push(
                                <Row key={"idx_"+uiElems.length}>
                                    {rowElems}
                                </Row>
                            );
                            rowElems = [];
                            counter = sizeFactor;
                        }
                        rowElems.push(
                            <Col key={"idx_"+rowElems.length} xs={16*sizeFactor} sm={16*sizeFactor} md={8*sizeFactor} lg={8*sizeFactor}>
                                {uiElem}
                            </Col>
                        );
                    } else {
                        Logger.logErr("DynamicUI","Unknown GraphId: "+current.type);
                    }
                }
            }
            if (rowElems.length > 0) {
                uiElems.push(
                    <Row key={"idx_"+uiElems.length}>
                        {rowElems}
                    </Row>
                );
                rowElems = [];
                counter = 0;
            }
        }
        if (uiElems.length > 0) {
            return (
                <Grid fluid>
                    <Row>
                        {uiElems}
                    </Row>
                </Grid>
            )
        }
        return null;
    }

    render() {
        if (this.props.uiLayout) {

            return (
                <Fragment>
                    <EditGraphConfigModal ref={this.editGraphConfigModal}/>
                    <ConditionalFragment condition={this.props.editMode}>
                        <NewElementButton onSelect={(_graph) => {this.onNewButton(null, _graph);}} />
                        <br/><br/>
                    </ConditionalFragment>
                    {this.renderUIList(this.props.uiLayout)}
                </Fragment>
            );
        }
        return null;
    }
}

export default DynamicGraphUIStateless;