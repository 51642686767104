import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber, YAxisFormatTime} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";
import {formatSeconds} from "../../common/TimeUtil";

class ScreenViews_Retention {
    static getUUID() {
        return "a06802c8-097f-428d-a6e6-c43dc297ed08";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"onlyPIL", name:"Per PIL", type:"boolean"},
            {id:"filterOutlier", name: "Filter Outlier", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcViewRetention(_viewData, _userData, _props, _groupValue) {
        let viewData = _viewData;
        let userData = _userData;
        if (_props.group && _groupValue) {
            viewData = AnalyticsUtil.filterBy(_viewData, (_e) => _e[_props.group] === _groupValue);
            userData = AnalyticsUtil.filterBy(_userData, (_e) => _e[_props.group] === _groupValue);
        }
        let series = [];
        let viewsPilData = AnalyticsUtil.addPil(viewData);
        if (_props.onlyPIL) {
            userData = AnalyticsUtil.addPil(userData);
        }
        for (let pil = 0; pil <= 28; pil++) {
            let filtered = [];
            if (_props.onlyPIL) {
                filtered = AnalyticsUtil.filterBy(viewsPilData,(_e) => _e.pil === pil);
            } else {
                filtered = AnalyticsUtil.filterBy(viewsPilData,(_e) => _e.pil >= 0 && _e.pil <= pil && _e.maxPil >= pil);
            }

            let userCount = 0;
            for (let i = 0; i < userData.length; i++) {
                const current = userData[i];
                if (_props.onlyPIL) {
                    if (current.pil === pil) {
                        userCount += current.dau;
                    }
                } else {
                    const maxPil = AnalyticsUtil.calcMaxPil(current.install_date);
                    if (maxPil >= pil) {
                        userCount += current.installs;
                    }
                }
            }
            if (_props.filterOutlier) {
                let userData = {};
                for (let i = 0; i < filtered.length; i++) {
                    const current = filtered[i];
                    if (!userData[current.user_id]) {
                        userData[current.user_id] = 0;
                    }
                    if (typeof current.views === "number") {
                        userData[current.user_id] += current.views;
                    }
                }
                let views = [];
                for (let userId in userData) {
                    views.push(userData[userId]);
                }
                while (views.length < userCount) {
                    views.push(0);
                }
                let outlierResult = OutlierUtil.filterOutlier(views);
                let avg = StatisticsUtil.avg(outlierResult.cleaned);
                let outlierPercentage = outlierResult.outlier.length / (outlierResult.outlier.length + outlierResult.cleaned.length);
                const outlierText = "Outliers: "+outlierResult.outlier.length+"("+Math.round(outlierPercentage*10000)/100+"%)";
                if (views.length > 10) {
                    series.push({name: _groupValue, x: pil, y: avg, text: (Math.round(avg*10)/10)+" ("+outlierResult.cleaned.length+" Users) "+outlierText});
                }
            } else {
                let views = 0;
                for(let i = 0; i < filtered.length; i++) {
                    if (typeof filtered[i].views === "number") {
                        views += filtered[i].views;
                    }
                }
                let normalized = views/userCount;
                if (userCount > 10) {
                    series.push({name: _groupValue, x: pil, y: normalized, text: (Math.round(normalized*10)/10)+" ("+userCount+" Users)"});
                }

            }
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        const grouping = ["install_date","day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        let userFilter = JSON.parse(JSON.stringify(filter));
        if (userFilter.screen !== undefined) {
            delete userFilter.screen;
        }
        if (_props.onlyPIL) {
            ctx.sendAsync("users","session_duration_v4",["value_count:user_id:dau"],["install_date","day", _props.group], userFilter);
        } else {
            ctx.sendAsync("users","user_v4",["value_count:user_id:installs"],["install_date", _props.group], userFilter);
        }
        ctx.sendAsync("views","screen_duration_v4",["value_count:duration:views"],grouping, filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installData = _data.users.result;
                let viewsData = _data.views.result;
                let groups = AnalyticsUtil.getDistinct(viewsData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = ScreenViews_Retention.calcViewRetention(viewsData, installData, _props, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = ScreenViews_Retention.calcViewRetention(viewsData, installData, _props);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {labels: {format: "D{value}"}, allowDecimals: false, crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = ["D"+this.x];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(ScreenViews_Retention, HighChartsGraph);