import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";

class Notification_Retention {
    static getUUID() {
        return "f114c366-8fe0-4a16-afbc-2ab96e3afe6c";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"filterOutlier", name: "Filter Outlier", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcEventRetention(_eventData, _installData, _props, _groupValue) {
        let eventData = _eventData;
        let installData = _installData;
        if (_props.group && _groupValue) {
            eventData = AnalyticsUtil.filterBy(_eventData, (_e) => _e[_props.group] === _groupValue);
            installData = AnalyticsUtil.filterBy(_installData, (_e) => _e[_props.group] === _groupValue);
        }
        let name  = _groupValue ? " - "+_groupValue : "";
        let series = [];
        let eventPilData = AnalyticsUtil.addPil(eventData);
        for (let pil = 0; pil <= 28; pil++) {
            const filtered = AnalyticsUtil.filterBy(eventPilData,(_e) => _e.pil >= 0 && _e.pil <= pil && _e.maxPil >= pil);
            let installCount = 0;
            for (let i = 0; i < installData.length; i++) {
                const current = installData[i];
                const maxPil = AnalyticsUtil.calcMaxPil(current.install_date);
                if (maxPil >= pil) {
                    installCount += current.installs;
                }
            }
            if (_props.filterOutlier) {
                let userData = {};
                for (let i = 0; i < filtered.length; i++) {
                    const current = filtered[i];
                    if (!userData[current.user_id]) {
                        userData[current.user_id] = 0;
                    }
                    if (typeof current.value === "number") {
                        userData[current.user_id] += current.value;
                    }
                }
                let eventData = [];
                for (let userId in userData) {
                    eventData.push(userData[userId]);
                }
                while (eventData.length < installCount) {
                    eventData.push(0);
                }

                let outlierResult = OutlierUtil.filterOutlier(eventData);
                let avg = StatisticsUtil.avg(outlierResult.cleaned);
                let outlierPercentage = outlierResult.outlier.length / (outlierResult.outlier.length + outlierResult.cleaned.length);
                const outlierText = "Outliers: "+outlierResult.outlier.length+"("+Math.round(outlierPercentage*10000)/100+"%)";
                if (eventData.length > 10) {

                    series.push({name: name, x: pil, y: avg, text: (Math.round(avg*10)/10)+" ("+outlierResult.cleaned.length+" Users) "+outlierText});
                }
            } else {
                let eventData = 0;
                for(let i = 0; i < filtered.length; i++) {
                    if (typeof filtered[i].value === "number") {
                        eventData += filtered[i].value;
                    }
                }
                let normalized = eventData/installCount;
                if (installCount > 10) {
                    series.push({name: name, x: pil, y: normalized, text: (Math.round(normalized*10)/10)+" ("+installCount+" Users)"});
                }

            }
        }
        return {name: name, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        let eventFilter = JSON.parse(JSON.stringify(filter));
        const grouping = ["install_date","day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        ctx.sendAsync("installs","user_v4",["value_count:user_id:installs"],["install_date", _props.group], filter);
        ctx.sendAsync("events","notification_v4",["value_count:user_id:value"],grouping, eventFilter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installData = _data.installs.result;
                let eventData = _data.events.result;
                let groups = AnalyticsUtil.getDistinct(installData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = Notification_Retention.calcEventRetention(eventData, installData, _props, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = Notification_Retention.calcEventRetention(eventData, installData, _props);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: true},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {labels: {format: "D{value}"}, allowDecimals: false, crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = ["D"+this.x];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(Notification_Retention, HighChartsGraph);