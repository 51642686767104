import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatPercent} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import {calculateSignificance} from "../chiTest";

class EventFunnel_Rate {
    static getUUID() {
        return "9a4043a3-6ae8-44ff-9f96-cd3ebb15ef3e";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"events", name:"Event List", type:"json"},
            {id:"group",name:"Group by", type:"string"},
            {id:"withSignificance", name:"Show Significance", type:"boolean"},
            {id:"refGroup", name:"ReferenceGroup", type:"string", showOnly: (_props) => {return _props.withSignificance === true}},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcEventFunnel(_eventData, _installData, _events, _groupName, _groupValue) {
        if (!Array.isArray(_events)) {
            _events = [];
        }
        let eventData = _eventData;
        let installData = _installData;
        if (_groupName && _groupValue) {
            eventData = AnalyticsUtil.filterBy(_eventData, (_e) => _e[_groupName] === _groupValue);
            installData = AnalyticsUtil.filterBy(_installData, (_e) => _e[_groupName] === _groupValue);
        }
        let installCount = 0;
        for (let i = 0; i < installData.length; i++) {
            installCount += installData[i].installs;
        }
        let events = {};
        for (let i = 0; i < eventData.length; i++) {
            const current = eventData[i];
            if (!events[current.name]) {
                events[current.name] = 0;
            }
            events[current.name] += current.eventCount;
        }
        let series = [];
        for (let i = 0; i < _events.length; i++) {
            const eventName = _events[i];
            let eventCount = 0;
            if (events[eventName]) {
                eventCount = events[eventName];
            }
            let percentage = 0;
            if (installCount > 0) {
                percentage = 100*(eventCount / installCount);
            }

            series.push({name: eventName ,x: i, y: percentage, installCount: installCount, userCount: eventCount, text :(Math.round(percentage*100)/100)+"% "+eventCount+" Events"});
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        let eventFilter = JSON.parse(JSON.stringify(filter));
        eventFilter.name = _props.events;
        ctx.sendAsync("installs","user_v4",["value_count:user_id:installs"],[_props.group], filter);
        ctx.sendAsync("events","event_v4",["value_count:value:eventCount"],["name", _props.group], eventFilter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installData = _data.installs.result;
                let eventData = _data.events.result;
                let groups = AnalyticsUtil.getDistinct(eventData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = EventFunnel_Rate.calcEventFunnel(eventData, installData, _props.events, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = EventFunnel_Rate.calcEventFunnel(eventData, installData, _props.events);
                    series.push(data);
                }
                if (_props.withSignificance && _props.refGroup) {
                    calculateSignificance(series, _props.refGroup);
                }
                let graphData = {
                    chart : {type: "column"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {type:"category", labels:{rotation:0}, crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatPercent();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let category = "";
                if (this.points && this.points.length > 0) {
                    category = this.points[0].key;
                }
                let result = [category];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(EventFunnel_Rate, HighChartsGraph);