import React from "react";
import {HelpBlock, Button, Modal, SelectPicker} from "rsuite";

class ReindexNameModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            name: "",
            indexList: []
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onNameChanged = this.onNameChanged.bind(this);
    }

    show(_srcName, _indexList, _callback) {
        let indexListData = [];
        for (let i = 0; i < _indexList.length; i++) {
            indexListData.push({label: _indexList[i], value: _indexList[i]});
        }
        return this.setState(() => {
            return {
                open: true,
                srcName: _srcName,
                name: "",
                indexList: indexListData,
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onNameChanged(_val) {
        return this.setState(()=> {
            return {name: _val};
        });
    }

    onSave() {
        if(typeof this.state.callback === "function") {
            this.state.callback(true, this.state.name);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    render() {
        return (
            <Modal show={this.state.open} size={"xs"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        Reindex {this.state.srcName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        Please select the name of the Destination Index
                    </HelpBlock>
                    <SelectPicker data={this.state.indexList} value={this.state.name} onSelect={this.onNameChanged} style={{width:200}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onSave} appearance="primary">
                        Start Reindex
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default ReindexNameModal;