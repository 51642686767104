
const quantile = (arr, q) => {
    const sorted = arr;
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

class StatisticsUtil {
    static avg(_list) {
        let sum = 0;
        for (let i = 0; i < _list.length; i++) {
            sum += _list[i];
        }
        return sum / _list.length;
    }

    static getStdDeviation(_list) {
        const avg = this.avg(_list);
        let sqDev = 0;
        for(let i = 0; i < _list.length; i++) {
            const dev = _list[i]-avg;
            sqDev += (dev*dev);
        }
        sqDev /= _list.length;
        return Math.sqrt(sqDev);
    }

    static getQuantils(_list) {
        _list.sort((_a,_b) => {return _a-_b;});


        return {
            low: _list[0],
            q1: quantile(_list, 0.25),
            median: quantile(_list, 0.50),
            q3: quantile(_list, 0.75),
            high: _list[_list.length-1]
        };
    }
}

export default StatisticsUtil;