
const chi = require("chi-squared");

function sqr(a) {
    return a*a;
}

function getSignificance(_refConverted, _refTotal, _testConverted, _testTotal) {
    let a = _refConverted;
    let b = _refTotal-_refConverted;
    let c = _testConverted;
    let d = _testTotal-_testConverted;
    let sum = a+b+c+d;
    let ae = ((a+b)*(a+c))/sum;
    let be = ((a+b)*(b+d))/sum;
    let ce = ((c+d)*(a+c))/sum;
    let de = ((c+d)*(b+d))/sum;
    let x2 = sqr(a-ae)/ae;
    x2 += sqr(b-be)/be;
    x2 += sqr(c-ce)/ce;
    x2 += sqr(d-de)/de;
    return chi.cdf(x2,1);
}

function calculateSignificance(_series, _referenceName) {
    let refSeries = _series.find(_e => _e.name === _referenceName);
    if (refSeries) {
        for (let seriesIdx = 0; seriesIdx < _series.length; seriesIdx++) {
            if (_series[seriesIdx].name !== _referenceName) {
                let data = _series[seriesIdx].data;
                for (let i = 0; i < data.length; i++) {
                    let current = data[i];
                    let referenceData = refSeries.data.find((_e) => _e.x === current.x);
                    if (current !== undefined && current.installCount > 0 && referenceData !== undefined && referenceData.installCount > 0) {
                        let significance = getSignificance(referenceData.userCount, referenceData.installCount, current.userCount, current.installCount);
                        if (!isNaN(significance)) {
                            current.text += " p="+Math.round((1-significance)*1000)/10+"% Χ²";
                        }
                    }
                }
            }
        }
    }
}

export { getSignificance, calculateSignificance }