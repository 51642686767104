import {Icon} from "rsuite";
import Installs_LineChart from "./Installs_LineChart";
import DAU_LineChart from "./DAU_LineChart";
import Retention_LineChart from "./Retention_LineChart";
import Installs_PieChart from "./Installs_PieChart";
import SessionLength_Daily from "./SessionLength_Daily";
import SessionLength_Retention from "./SessionLength_Retention";
import AdImpression_Retention from "./AdImpression_Retention";
import FPS_Daily from "./FPS_Daily";
import Currency_Retention from "./Currency_Retention";
import EventFunnel_Rate from "./EventFunnel_Rate";

import Logger from "../../common/Logger";
import LTV from "./LTV";
import Revenue_Daily from "./Revenue_Daily";
import AdECPM_Daily from "./AdECPM_Daily";
import EventFunnel_Value from "./EventFunnel_Value";
import Event_Retention from "./Event_Retention";
import EventValue_Pie from "./EventValue_Pie";
import EventGraph from "./EventFlow";
import AdImpression_Daily from "./AdImpression_Daily";
import AdLatency_Daily from "./AdLatency_Daily";
import AdImpression_PieChart from "./AdImpression_PieChart";
import AdViewRate_PieChart from "./AdViewRate_PieChart";
import ScreenDuration_Retention from "./ScreenDuration_Retention";
import SceneDuration_Retention from "./SceneDuration_Retention";
import ScreenViews_Retention from "./ScreenViews_Retention";
import SceneViews_Retention from "./SceneViews_Retention";
import Notification_Retention from "./Notification_Retention";
import Event_Daily_LineChart from "./Event_Daily_LineChart";
import Revenue_Cohort from "./Revenue_Cohort";

const graphList = [
    {
        name: "User",
        charts: [
            { name: "Installs", charts : [
                { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: Installs_LineChart},
                { icon : <Icon icon = "pie-chart"/>, name: "All", graph: Installs_PieChart},
            ]},
            { name: "DAU", charts : [
                { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: DAU_LineChart},
                { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: Retention_LineChart},
            ]},
            { name: "Session Length", charts : [
                { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: SessionLength_Daily},
                { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: SessionLength_Retention},
            ]},
        ]
    },
    {
        name: "Revenue",
        charts : [
            { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: Revenue_Daily},
            { icon : <Icon icon = "line-chart"/>, name: "Cohort", graph: Revenue_Cohort},
            { icon : <Icon icon = "line-chart"/>, name: "LTV", graph: LTV},
        ]
    },
    {
        name: "Ads",
        charts : [
            { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: AdImpression_Retention},
            { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: AdImpression_Daily},
            { icon : <Icon icon = "pie-chart"/>, name: "All", graph: AdImpression_PieChart},
            { icon : <Icon icon = "pie-chart"/>, name: "View-Rate", graph: AdViewRate_PieChart},
            { icon : <Icon icon = "line-chart"/>, name: "ECPM", graph: AdECPM_Daily},
            { icon : <Icon icon = "line-chart"/>, name: "Latency", graph: AdLatency_Daily},
        ]
    },
    {
        name: "FPS",
        charts : [
            { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: FPS_Daily},
        ]
    },
    {
        name: "Events",
        charts : [
            { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: Event_Retention},
            { icon : <Icon icon = "line-chart"/>, name: "Daily", graph: Event_Daily_LineChart},
            { icon : <Icon icon = "bar-chart"/>, name: "Funnel (Unique)", graph: EventFunnel_Rate},
            { icon : <Icon icon = "bar-chart"/>, name: "Funnel (Value)", graph: EventFunnel_Value},
            { icon : <Icon icon = "pie-chart"/>, name: "Value", graph: EventValue_Pie},
            { icon : <Icon icon = "flow"/>, name: "Flow", graph: EventGraph},
        ]
    },
    {
        name: "Currency",
        charts : [
            { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: Currency_Retention},
        ]
    },
    {
        name: "Screens",
        charts : [
            {name:"Duration", charts:[
                    { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: ScreenDuration_Retention},
            ]},
            {name:"Views", charts:[
                    { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: ScreenViews_Retention},
                ]}
        ]
    },
    {
        name: "Scenes",
        charts : [
            {name:"Duration", charts:[
                    { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: SceneDuration_Retention},
                ]},
            {name:"Views", charts:[
                    { icon : <Icon icon = "line-chart"/>, name: "Retention", graph: SceneViews_Retention},
                ]}
        ]
    },
    {
        name: "Notification",
        charts : [
            {icon : <Icon icon = "line-chart"/>, name: "Retention", graph: Notification_Retention}
        ]
    }
];

function GetGraphList() {
    return graphList;
}

let graphIdLookup = null;

function ensureGraphIdLookup() {
    if (graphIdLookup === null) {
        graphIdLookup = {};
        extractGraphs("",graphList);
    }
}

function extractGraphs(_prefixName, _list) {
    for (let i = 0; i < _list.length; i++) {
        if(_list[i].graph) {
            if (_list[i].graph.prototype.getGraphId) {
                const uuid = _list[i].graph.prototype.getGraphId();
                if (graphIdLookup[uuid]) {
                    Logger.logErr("Charts","Multiple Chart using UUID: "+uuid);
                }
                graphIdLookup[uuid] = {graph: _list[i].graph, name: _prefixName+"-"+_list[i].name};
            }
        }
        if (_list[i].charts) {
            let newName = _list[i].name;
            if (_prefixName.length > 0) {
                newName = _prefixName+"-"+_list[i].name;
            }
            extractGraphs(newName, _list[i].charts);
        }
    }
}

function GetGraphById(_id) {
    ensureGraphIdLookup();
    if (graphIdLookup[_id]) {
        return graphIdLookup[_id].graph;
    }
    return null;
}

function GetGraphNameById(_id) {
    ensureGraphIdLookup();
    if (graphIdLookup[_id]) {
        return graphIdLookup[_id].name;
    }
    return null;
}



export { GetGraphList, GetGraphById, GetGraphNameById };