import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";
import {formatGermanDate} from "../../common/TimeUtil";

class Event_Daily_LineChart {
    static getUUID() {
        return "06adb079-0328-4c2e-b78a-ff06d29838b4";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"eventName",name:"Event Name", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"minThreshold",name:"Min. Threshold",type:"number", default: 10},
            {id:"perUser", name: "Per User", type:"boolean"},
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcGraph(_eventData, _dauData, _props, _groupValue) {
        let eventData = _eventData;
        let dauData = _dauData;
        if (_props.group && _groupValue) {
            eventData = AnalyticsUtil.filterBy(_eventData, (_e) => _e[_props.group] === _groupValue);
            dauData = AnalyticsUtil.filterBy(_dauData, (_e) => _e[_props.group] === _groupValue);
        }
        let series = [];
        let aggregated = {};
        for (let i = 0; i < dauData.length; i++) {
            const current = dauData[i];
            if (!aggregated[current.day]) {
                aggregated[current.day] = {events: 0, dau: 0};
            }
            aggregated[current.day].dau += current.dau;
        }
        for (let i = 0; i < eventData.length; i++) {
            const current = eventData[i];
            if (!aggregated[current.day]) {
                aggregated[current.day] = {events: 0, dau: 0};
            }
            aggregated[current.day].events += current.value;
        }
        for (let day in aggregated) {
            const current = aggregated[day];
            if (current.dau >= _props.minThreshold) {
                let val = current.events;
                if (_props.perUser === true) {
                    val = current.events / current.dau;
                }
                series.push({name: _groupValue, x: new Date(day).getTime(), y: val, text: val + " ("+current.dau+" Users)"});
            }
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        let eventFilter = JSON.parse(JSON.stringify(filter));
        eventFilter.name = _props.eventName;
        const grouping = ["day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        ctx.sendAsync("dau","session_duration_v4",["cardinality:user_id:dau"],["day", _props.group], filter);
        ctx.sendAsync("events","event_v4",["sum:value:value"],grouping, eventFilter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let dauData = _data.dau.result;
                let eventData = _data.events.result;
                let groups = AnalyticsUtil.getDistinct(dauData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = Event_Daily_LineChart.calcGraph(eventData, dauData, _props, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = Event_Daily_LineChart.calcGraph(eventData, dauData, _props);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: true},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {type: "datetime", crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = [formatGermanDate(new Date(this.x))];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(Event_Daily_LineChart, HighChartsGraph);