import EventBus from "./EventBus";

class HierarchyNode {
    constructor(_name, _icon, _link) {
        this._name = _name;
        this._icon = _icon;
        this._link = _link;
        this._childs = [];
        this._isSyncing = false
        this._isDivider = false;
    }

    getName() { return this._name; }
    hasIcon() { return this._icon !== undefined && this._icon !== null; }
    getIcon() { return this._icon; }

    isSyncing() { return this._isSyncing; }
    getLink() { return this._link; }
    isDivider() { return this._isDivider; }

    addNode(_name, _icon, _link) {
        const subNode = new HierarchyNode(_name, _icon, _link);
        this._childs.push(subNode);
        return subNode;
    }

    addDivider() {
        const subNode = new HierarchyNode(null,null, null);
        subNode._isDivider = true;
        this._childs.push(subNode);
    }

    getNode(_name) {
        for(let i = 0; i < this._childs.length; i++) {
            if (this._childs[i]._name === _name) {
                return this._childs[i];
            }
        }
        return null;
    }

    redrawUI()  {
        EventBus.notify("UPDATE_NAVIGATION", {});
    }

    clearChilds() {
        this._childs = [];
    }

    hasChilds() {
        return this._childs.length > 0;
    }

    getChilds() {
        return this._childs;
    }

    setSyncMode(_val) {
        this._isSyncing = _val === true;
        EventBus.notify("UPDATE_NAVIGATION", {});
    };
}

const instance = new HierarchyNode("root");
export default instance;