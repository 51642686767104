
import * as Config from "../config";
import WSConnection from "../common/wss/WSConnection";
import EventBus from "../common/EventBus";
import jwt from "jsonwebtoken";
import MeteorNavigation from "../common/MeteorNavigation";
import {ReactComponent as IconMeteor} from "../icons/meteor-solid.svg";
import MeteorRoutes from "../common/MeteorRoutes";
import UserManagementPage from "./UserManagementPage";
import AddUserPage from "./AddUserPage";
import {Icon} from "rsuite";

class MeteorContext {
    constructor() {
        let url = "wss://meteor-api.coldfire.io/test";
        if (Config.meteor.useLiveEnvironment === true) {
            url = "wss://meteor-api.coldfire.io/live";
        }
        this._connection = new WSConnection(url);

        this.updateNavigation();
    }

    getToken() {
        return localStorage.getItem("meteor_auth_token");
    }

    setToken(_token) {
        localStorage.setItem("meteor_auth_token", _token);
    }

    getPermissions() {
        const token = this.getToken();
        if (token) {
            const decoded = jwt.decode(token);
            if (decoded && decoded.permissions) {
                return decoded.permissions;
            }
        }
        return {};
    }

    hasPermission(_permission) {
        const permissions = this.getPermissions();
        return permissions && permissions[_permission] === true;
    }

    isAuthenticated() {
        const token = this.getToken();
        if (token) {
            const decoded = jwt.decode(token);
            const now = Math.floor(new Date().getTime()/1000);
            if (decoded && decoded.exp && decoded.exp > now) {
                return true;
            }
        }
        return false
    };

    updateNavigation() {
        let node = MeteorNavigation.getNode("Meteor");
        if (!node) {
            node = MeteorNavigation.addNode("Meteor", <i className="rs-icon"><IconMeteor/></i>);
        }
        node.clearChilds();
        MeteorRoutes.addRoute("/meteor/userMgmt", UserManagementPage);
        MeteorRoutes.addRoute("/meteor/addUser", AddUserPage);
        if (this.hasPermission("admin")) {
            node.addNode("Add User",<Icon icon={"user-plus"}/>, "/meteor/addUser");
            node.addNode("Manage Users",<Icon icon={"group"}/>, "/meteor/userMgmt");
        }
        MeteorNavigation.redrawUI();
    }

    authenticate(_user, _pass, _cb) {
        return this._connection.send({type : "auth", subType : "login", userId : _user, password : _pass}, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            if (_response.type === "auth" && _response.subType === "authToken" && _response.token) {
                this.setToken(_response.token);
                EventBus.notify("METEOR_AUTHENTICATION_CHANGED", {token: _response.token});
                //EventBus.notify("UPDATE_NAVIGATION", {});
            }
            this.updateNavigation();
            return _cb(null, true);
        });
    };

    sendAuthenticated(_data, _cb) {
        let data = JSON.parse(JSON.stringify(_data));
        data.authToken = this.getToken();
        return this._connection.send(data, (_response) => {
            if (_response.type === "error") {
                return _cb({code: _response.code, message: _response.errorText});
            }
            return _cb(null, _response);
        });
    }

    logout() {
        this.setToken("");
        this.updateNavigation();
        EventBus.notify("METEOR_AUTHENTICATION_CHANGED", {});
    };
}

const instance = new MeteorContext();
export default instance;