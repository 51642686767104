import React from "react";
import {Button, Container, Divider, Icon, IconButton, Input, InputGroup, Panel, Tag} from "rsuite";
import ConditionalFragment from "../common/ConditionalFragment";

function format2(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return ""+_num;
}

class BuildComp extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {
            editMode : false
        };

        this.onEditButton = this.onEditButton.bind(this);
        this.onEditChange = this.onEditChange.bind(this);
        this.onCancelEditButton = this.onCancelEditButton.bind(this);
        this.onApplyEditButton = this.onApplyEditButton.bind(this);
        this.onDeleteButton = this.onDeleteButton.bind(this);
    }

    onEditButton() {
        this.setState((_prevState, _props) => {
            return {
                editMode : true,
                text : _props.build.comment
            };
        })
    }

    onEditChange(_value) {
        this.setState((_prevState, _props) => {
            return {text: _value};
        });
    }

    onCancelEditButton() {
        this.setState(() => {
            return {
                editMode : false
            };
        })
    }

    onApplyEditButton() {
        if (this.props.onCommentChanged && typeof this.props.onCommentChanged === "function") {
            this.props.onCommentChanged(this.state.text);
        }
        this.setState(() => {
            return {
                editMode : false
            };
        });
    }

    onDeleteButton() {
        if (this.props.onDelete && typeof this.props.onDelete === "function") {
            this.props.onDelete();
        }
    }

    render() {
        let build = this.props.build;
        let project = this.props.project;
        if (build) {
            let canDelete = build.canDelete === true;
            if (this.props.onDelete === undefined || typeof this.props.onDelete !== "function") {
                canDelete = false;
            }
            let canEdit = build.canEdit === true;
            let timestamp = new Date(build.timestamp*1000);
            let date = format2(timestamp.getDate())+"."+format2(timestamp.getMonth()+1)+"."+timestamp.getFullYear();
            let time = format2(timestamp.getHours())+":"+format2(timestamp.getMinutes())+":"+format2(timestamp.getSeconds());
            let buttons = [];
            if (build.iosLink && build.iosLink !== "") {
                let link = "itms-services://?action=download-manifest&url="+project.s3Bucket+build.iosPlist;
                buttons.push(<IconButton size="lg" appearance="primary" style={{marginRight:"10px"}} icon={<Icon icon="apple" />} href={link}/>)
            } else {
                buttons.push(<IconButton size="lg" style={{marginRight:"10px"}} icon={<Icon icon="apple" />}/>)
            }
            if (build.androidLink && build.androidLink !== "") {
                let link = project.s3Bucket+build.androidLink;
                buttons.push(<IconButton size="lg" appearance="primary" style={{marginRight:"10px"}} icon={<Icon icon="android" />} href={link}/>)
            } else {
                buttons.push(<IconButton size="lg" style={{marginRight:"10px"}} icon={<Icon icon="android" />}/>)
            }
            if (build.androidBundle && build.androidBundle !== "") {
                let link = project.s3Bucket+build.androidBundle;
                buttons.push(<Button size="lg" appearance="primary" style={{marginRight:"10px"}} href={link}><Icon icon="android" style={{marginRight:"10px"}}/><Icon icon="download" /> </Button>)
            }
            if (build.androidSymbol && build.androidSymbol !== "") {
                let link = project.s3Bucket+build.androidSymbol;
                buttons.push(<Button size="lg" appearance="primary" style={{marginRight:"10px"}} href={link}><Icon icon="android" style={{marginRight:"10px"}}/><Icon icon="bug" /> </Button>)
            }

            let commentComp = null;
            let comment = (<i>No Comment</i>);
            if (build.comment && build.comment !== "") {
                comment = build.comment;
            }
            if (this.state.editMode === true) {
                commentComp = (<InputGroup>
                    <Input value={this.state.text} onChange={this.onEditChange} />
                    <InputGroup.Button color="green" style={{float:"right",marginRight:"10px"}} onClick={this.onApplyEditButton}><Icon icon={"check-circle"}/> </InputGroup.Button>
                    <InputGroup.Button color="red" style={{float:"right"}} onClick={this.onCancelEditButton}><Icon icon={"close-circle"}/></InputGroup.Button>
                </InputGroup>);
            } else {
                commentComp = (<p>
                    <Tag visible>{comment}</Tag>
                    <ConditionalFragment condition={canEdit === true}>
                        <IconButton color="green" style={{float:"right"}} icon={<Icon icon={"edit"}/>} onClick={()=>{this.onEditButton();}}/>
                    </ConditionalFragment>
                </p>);
            }
            let externalLink = "/firebuild/project/"+build.projectId+"/build/"+build.buildId;
            return (
                <Container>
                    <Panel shaded bordered header={<p>
                        <Icon icon={"gitlab"}/> {build.version} ({build.buildType})
                        <ConditionalFragment condition={this.props.withLink}>
                            <IconButton href={externalLink} icon={<Icon icon="external-link-square"/>} style={{marginLeft:"10px"}} />
                        </ConditionalFragment>
                        <ConditionalFragment condition={canDelete === true}>
                            <IconButton color="red" style={{float:"right"}} icon={<Icon icon={"trash"}/>} onClick={()=>{this.onDeleteButton();}}/>
                        </ConditionalFragment>
                    </p>}>
                        <p><Icon icon={"calendar"}/> {date} &nbsp;&nbsp;<Icon icon={"clock-o"}/> {time}</p>
                        <p><Icon icon={"code-fork"}/> {build.branchName} &nbsp;&nbsp;<Icon icon={"gitlab"}/> {build.buildNumber}</p>
                        <p><Icon icon={"user"}/> {build.owner} &nbsp;&nbsp;<Icon icon={"hashtag"}/> {build.scmHash}</p>
                        <br/>
                        {commentComp}
                        <br/>
                        <Divider/>
                        {buttons}
                    </Panel>
                    <br/>
                </Container>);
        }
        return null;
    }
}

export default BuildComp;