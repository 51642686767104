import AnalyticsConnection from "./AnalyticsConnection";
import {getUnixTime} from "../common/TimeUtil";

function create() {
    let wrapper = {};

    wrapper.clearCache = () => {
        window.sessionStorage.setItem("cf.analytics.gamectx",null);
    }

    let getGameCallbacks = [];

    wrapper.getGames = (_cb) => {
        let cachedData = window.sessionStorage.getItem("cf.analytics.gamectx");
        let cached = null;
        try {
            cached = JSON.parse(cachedData);
        } catch (ex) {};
        if (cached && cached.ttl >= getUnixTime()) {
            return _cb(null, cached.data);
        }
        if (getGameCallbacks.length > 0) {
            getGameCallbacks.push(_cb);
            return;
        }
        getGameCallbacks.push(_cb);
        return AnalyticsConnection.sendAuthenticated({type:"game",subType:"listConfig"}, (_err, _result) => {
            if (_err) {
                let cbList = getGameCallbacks;
                getGameCallbacks = [];
                for (let i = 0; i < cbList.length; i++) {
                    cbList[i](_err);
                }
                return;
            }
            let result = [];
            if (_result && _result.games) {
                let cache = {
                    data: _result.games,
                    ttl: getUnixTime()+ 60*60
                };
                window.sessionStorage.setItem("cf.analytics.gamectx", JSON.stringify(cache));
                result = cache.data;
            }
            let cbList = getGameCallbacks;
            getGameCallbacks = [];
            for(let i = 0; i < cbList.length; i++) {
                _cb(null, result);
            }
        });
    }

    wrapper.resolveGameName = (_id, _cb) => {
        return wrapper.getGames((_err, _list) => {
            if (_err) {
                return _cb(_err);
            }
            for (let i = 0; i < _list.length; i++) {
                if (_list[i].id === _id) {
                    return _cb(null, _list[i].name);
                }
            }
            return _cb(null, _id);
        });
    }

    return wrapper;
}

const instance = create();
export default instance;

