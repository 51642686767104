import React from "react";
import {Loader, Nav} from "rsuite";
import GamePagesContext from "./GamePagesContext";
import {Redirect} from "react-router";

class PageNavigation extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {
            navs : []
        };
        this.updatePageNavigation = this.updatePageNavigation.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.updatePageNavigation();
    }

    onSelect(_key) {
        for(let i = 0; i< this.state.navs.length; i++) {
            const current = this.state.navs[i];
            if (current.id === _key) {
                return this.setState(() => {
                    return {redirect : "/analytics/game/"+current.gameId+"/page/"+current.id};
                });
            }
        }
    }

    updatePageNavigation() {
        return GamePagesContext.getPages(this.props.gameId, (_err, _pages) => {
            if (_pages) {
                let type = null;
                for(let i = 0; i < _pages.length; i++) {
                    if (_pages[i].id === this.props.pageId) {
                        type = _pages[i].type;
                    }
                }
                let navs = [];
                for (let i = 0; i < _pages.length; i++) {
                    if (_pages[i].type === type && _pages[i].visible === true) {
                        navs.push(_pages[i]);
                    }
                }
                if (type === "abTest") {
                    navs.sort((_a, _b) => {return _b.order - _a.order;});
                }
                return this.setState(() => {
                   return { navs : navs };
                });
            }
        });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>);
        }
        if (this.state.navs.length === 0) {
            return (<Nav appearance="subtle">
                <Nav.Item><Loader/> Loading...</Nav.Item>
            </Nav>);
        } else {
            let navItems = [];
            for (let i = 0; i < this.state.navs.length; i++) {
                const current = this.state.navs[i];
                if (current.id === this.props.pageId) {
                    navItems.push(<Nav.Item eventKey={current.id} active>{current.name}</Nav.Item>);
                } else {
                    navItems.push(<Nav.Item eventKey={current.id}>{current.name}</Nav.Item>);
                }
            }
            return (
                <Nav appearance="subtle" onSelect={this.onSelect}>
                    {navItems}
                </Nav>
            )
        }
    }
}

export default PageNavigation;