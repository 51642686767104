import React from "react";
import withNavigation from "../common/SiteWithNavigation";
import withURLParams from "../common/SiteWithURLParams";
import {Button, Container, Divider, Icon, IconButton, Input, InputGroup, Panel, Tag, Tooltip} from "rsuite";
import BuildNavigation from "./BuildNavigation";
import SimpleProgresUI from "../common/SimpleProgresUI";
import BuildContext from "./BuildContext";
import ConditionalFragment from "../common/ConditionalFragment";
import DeleteBuildModal from "./DeleteBuildModal";
import SimpleProgressModal from "../common/SimpleProgressModal";
import BuildComp from "./BuildComp";

class BuildsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
        };

        this.progressUI = SimpleProgresUI.getReference();
        this.progressModal = SimpleProgressModal.getReference();
        this.deleteBuildModal = DeleteBuildModal.getReference();

        this.loadBuilds = this.loadBuilds.bind(this);
        this.renderBuild = this.renderBuild.bind(this);
        this.onApplyEditButton = this.onApplyEditButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBuilds();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBuilds() {
        this.progressUI.start("Retrieving Build...");
        BuildContext.getProjectDetails(this.props.urlParams.projectId, (_err, _projectDetails) => {
            if (!this._isMounted) {return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.setState(() => {
                return {
                    projectDetail : _projectDetails
                };
            });
            BuildContext.getBuild(this.props.urlParams.buildId, (_err, _build) => {
                if (!this._isMounted) {return; }
                if (_err) {
                    this.progressUI.error(_err);
                    return;
                }
                this.progressUI.stop();
                this.setState(() => {
                    return {
                        build : _build,
                    };
                });
            })

        });
    }

    onApplyEditButton(_build, _comment) {
        this.progressModal.show("Updating Build");
        this.progressModal.startTask("Updating Build");
        return BuildContext.updateBuildComment(_build, _comment, (_err, _build) => {
            if (_err) {
                this.progressModal.error(_err);
            } else {
                this.progressModal.complete();
                this.setState(() => {
                    return {
                        builds: _build,
                        editBuild : {}
                    };
                });
            }
        });
    }

    renderBuild(_build) {
        return (<BuildComp build={_build} project={this.state.projectDetail}
                           onDelete={() => {this.onDeleteBuildButton(_build)}}
                           onCommentChanged={(_comment) => {this.onApplyEditButton(_build, _comment)}}/>);
    }

    render() {
        let displayName = "";
        if (this.state.projectDetail) {
            displayName = this.state.projectDetail.displayName;
        }
        let renderedBuild = null;
        if (this.state.build) {
            renderedBuild= (<BuildComp project={this.state.projectDetail} build={this.state.build} onCommentChanged={(_comment) => {this.onApplyEditButton(this.state.build, _comment);}}/>)
        }
        return (
            <Container style={{margin:"10px"}}>
                <DeleteBuildModal ref={this.deleteBuildModal.getRef()}/>
                <SimpleProgressModal ref={this.progressModal.getRef()} size="xs"/>
                <h4>{displayName}</h4><br/>
                {renderedBuild}
            </Container>
        );
    }

}

export default withNavigation(
    withURLParams(
        BuildsPage
    )
);