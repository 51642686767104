import React from "react";
import {Loader, Message} from "rsuite";
import ColdFireReactRef from "./ColdFireReactRef";

class SimpleProgressUtil extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {};

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.error = this.error.bind(this);
        this.isVisible = this.isVisible.bind(this);
    }

    static getReference() {
        return new ColdFireReactRef(["start","stop","error","isVisible"]);
    }

    isVisible() {
        return this.state.isLoading || this.state.error;
    }

    start(_text) {
        this.setState(() => {
            return {
                isLoading: true,
                loadingText: _text,
                error: null
            };
        })
    }

    stop() {
        this.setState(() => {
            return {
                isLoading: false,
            };
        });
    }

    error(_err) {
        this.setState(() => {
            return {
                isLoading: false,
                error: _err
            };
        });
    }

    render() {
        if (this.state.isLoading) {
            return (<Message type="info" description={<Loader content={this.state.loadingText}/>}/>);
        } else if (this.state.error) {
            let errorText = "";
            if (this.state.error.code) {
                errorText += "Code "+this.state.error.code+": ";
            }
            if (this.state.error.message) {
                errorText += this.state.error.message;
            }
            return (<Message type="error" title="Error" description={<p>{errorText}</p>}/>);
        }
        return null;
    }
}

export default SimpleProgressUtil;