import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";

class AdImpression_PieChart {
    static getUUID() {
        return "99f53c69-c9b6-4032-a326-1ef429247847";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"limitResults", name:"Limit to 10", type:"boolean"},
            {id:"withLegend",name:"Show Legend", type: "boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 0.5;
    }

    static getHeightRatio() {
        return "100%";
    }

    static calcImpressions(_impressionData, _groupName, _groupValue) {
        let impressionData = _impressionData;
        if (_groupName && _groupValue) {
            impressionData = AnalyticsUtil.filterBy(_impressionData, (_e) => _e[_groupName] === _groupValue);
        }
        let impressionCount = 0;
        for (let i = 0; i < impressionData.length; i++) {
            const current = impressionData[i];
            impressionCount += current.impressions;
        }
        return {name: _groupValue, y: impressionCount, text: impressionCount+" Impressions"};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        filter.result = 0;
        ctx.sendAsync("impressions","ad_impression_v4",["value_count:placement:impressions"],[_props.group], filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let impressionData = _data.impressions.result;
                let groups = AnalyticsUtil.getDistinct(impressionData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = AdImpression_PieChart.calcImpressions(impressionData, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = AdImpression_PieChart.calcImpressions(impressionData);
                    series.push(data);
                }
                series.sort((a,b) => {return b.y-a.y;});
                let MAX_RESULTS = 10;
                if (_props.limitResults === true && series.length >= MAX_RESULTS) {
                    let otherCount = 0;
                    let sum = 0;
                    for (let i = 0; i < series.length; i++) {
                        sum += series[i].y;
                        if (i >= (MAX_RESULTS)) {
                            otherCount += series[i].y;
                        }
                    }
                    series = series.slice(0,MAX_RESULTS);
                    let percent = Math.round((otherCount/sum)*10000)/100;
                    series.push({name: "other", y: otherCount, text: percent+"% ("+otherCount+" Users)"});
                }
                let graphData = {
                    chart : {type: "pie"},
                    plotOptions: { pie : { showInLegend: _props.withLegend}},
                    legend: {enabled: groups.length > 1},
                    series : [{data: series}],
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {}

    static getYAxis() {}

    static getTooltip() {
        return {
            formatter : function() {
                let percentage = (Math.round(this.point.percentage*100)/100)+"%";
                return "<b>"+this.point.name+":</b> "+percentage+" ("+this.point.text+")";
            }
        };
    }
}

export default CachedGraphV2(AdImpression_PieChart, HighChartsGraph);