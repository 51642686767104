import {getUnixTime} from "../common/TimeUtil";
import AnalyticsConnection from "./AnalyticsConnection";
import { v4 as uuidv4 } from 'uuid';
import AnalyticsContext from "./AnalyticsContext";

function create() {

    let wrapper = {};

    wrapper.clearCache = () => {

    };

    let gamePagesCache = [];
    let ttl = 0;

    function filterByGame(_gameId, _list) {
        let result = [];
        for(let i = 0; i < _list.length; i++) {
            if (_list[i].gameId === _gameId) {
                result.push(_list[i]);
            }
        }
        result.sort((_a,_b) => {
            if (_a.type !== _b.type) {
                return _a.type.localeCompare(_b.type);
            }
            return _a.order - _b.order;
        });
        return result;
    }

    let getterCallbacks = [];

    wrapper.getPages = (_gameId, _cb) => {
        if (gamePagesCache.length > 0 && ttl >= getUnixTime()) {
            let result = filterByGame(_gameId, gamePagesCache);
            return _cb(null, result);
        }
        if (getterCallbacks.length > 0) {
            getterCallbacks.push({gameId: _gameId, cb: _cb});
            return;
        }
        getterCallbacks.push({gameId: _gameId, cb: _cb});
        return AnalyticsConnection.sendAuthenticated({type: "game", subType: "listPages"}, (_err, _res) => {
            if (_err) {
                let cbList = getterCallbacks;
                getterCallbacks = [];
                for (let i = 0; i < cbList.length; i++) {
                    cbList[i].cb(_err);
                }
                return;
            }
            for (let i = 0; i < _res.pages.length; i++) {
                let current = _res.pages[i];
                if (current.type === undefined || current.type === null) {
                    current.type = "";
                }
                if (typeof current.order !== "number") {
                    if (typeof current.order === "string") {
                        let num = parseInt(current.order, 10);
                        if (isNaN(num)) {
                            num = 0;
                        }
                        current.order = num;
                    } else {
                        current.order = 0;
                    }

                }
            }
            gamePagesCache = _res.pages;
            ttl = getUnixTime() + (60*60);
            let cbList = getterCallbacks;
            getterCallbacks = [];
            for(let i = 0; i < cbList.length; i++) {
                let result = filterByGame(cbList[i].gameId, gamePagesCache);
                cbList[i].cb(null, result);
            }
        });
    }

    wrapper.getPage = (_gameId, _pageId, _cb) => {
        return wrapper.getPages(_gameId, (_err, _pages) => {
            if (_err) {
                return _cb(_err);
            }
            for (let i = 0; i < _pages.length; i++) {
                if (_pages[i].id === _pageId) {
                    return _cb(null, _pages[i]);
                }
            }
            return _cb(null, null);
        });
    }

    wrapper.createNewPage = (_gameId, _name, _cb) => {
        let data = {
            id: uuidv4(),
            gameId: _gameId,
            name: _name
        };
        AnalyticsConnection.sendAuthenticated({type: "game",subType:"updatePage", page: data}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            gamePagesCache.push(data);
            AnalyticsContext.updateNavigation();
            return _cb();
        });
    }

    wrapper.updatePage = (_page, _cb) => {
        AnalyticsConnection.sendAuthenticated({type: "game",subType:"updatePage", page: _page}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            let idx = -1;
            for (let i = 0; i < gamePagesCache.length; i++) {
                if (gamePagesCache[i].id === _page.id) {
                    idx = i;
                    break;
                }
            }
            if (idx >= 0) {
                gamePagesCache[idx] = JSON.parse(JSON.stringify(_page));
            } else {
                gamePagesCache.push(_page);
                AnalyticsContext.updateNavigation();
            }
            return _cb();
        });
    }

    wrapper.deletePage = (_id, _cb) => {
        AnalyticsConnection.sendAuthenticated({type: "game",subType:"deletePage", pageId: _id}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            let idx = -1;
            for (let i = 0; i < gamePagesCache.length; i++) {
                if (gamePagesCache[i].id === _id) {
                    idx = i;
                    break;
                }
            }
            if (idx >= 0) {
                gamePagesCache.splice(idx, 1);
            }
            AnalyticsContext.updateNavigation();
            return _cb();
        });
    }

    return wrapper;
};

const instance = create();
export default instance;