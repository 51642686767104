import React from "react";
import withURLParams from "../common/SiteWithURLParams";
import withNavigation from "../common/SiteWithNavigation";
import SimpleProgresUI from "../common/SimpleProgresUI";
import GamePagesContext from "./GamePagesContext";
import {Button, Container, DateRangePicker, HelpBlock, Icon, IconButton, Panel, Toggle} from "rsuite";
import SimpleProgressModal from "../common/SimpleProgressModal";
import ConditionalFragment from "../common/ConditionalFragment";
import AnalyticsConnection from "./AnalyticsConnection";
import {ceilToDayUTC, getDayWithOffset} from "../common/TimeUtil";
import FilterTags from "./FilterTags";
import DynamicGraphUIStateless from "./DynamicGraphUIStateless";
import GameContext from "./GameContext";
import PageNavigation from "./PageNavigation";

function dateToUnixTime(_ts) {
    let d = Date.UTC(_ts.getFullYear(), _ts.getMonth(), _ts.getDate());
    return Math.ceil(d/1000);
}

class CustomAnalyticsPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            gameName: this.props.urlParams.gameId,
            pageName: "Loading Page ...",
            dateRange: [ceilToDayUTC(getDayWithOffset(-28)), ceilToDayUTC(getDayWithOffset(0))],
        };

        this.progressUI = SimpleProgresUI.getReference();
        this.progressModal = SimpleProgressModal.getReference();

        this.loadGameName = this.loadGameName.bind(this);
        this.loadPageData = this.loadPageData.bind(this);

        this.onEditButton = this.onEditButton.bind(this);
        this.onSaveButton = this.onSaveButton.bind(this);
        this.onDiscardButton = this.onDiscardButton.bind(this);

        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onDateSelectionChanged = this.onDateSelectionChanged.bind(this);
        this.onDateSelectionToggle = this.onDateSelectionToggle.bind(this);
        this.onLayoutUpdate = this.onLayoutUpdate.bind(this);

        this.renderFilterUI = this.renderFilterUI.bind(this);
    }

    loadGameName() {
        return GameContext.resolveGameName(this.props.urlParams.gameId, (_err, _gameName) => {
            if (!this._isMounted) {return; }
            if (_gameName) {
                this.setState(() => {
                    return {gameName: _gameName};
                });
            }
        })
    }

    loadPageData() {
        this.progressUI.start("Retrieving Page Data...");
        return GamePagesContext.getPage(this.props.urlParams.gameId, this.props.urlParams.pageId, (_err, _page) => {
            if (!this._isMounted) {return; }
            if (_err) {
                this.progressUI.error(_err);
                return;
            }
            this.progressUI.stop();
            if (!_page.pageFilter) {
                _page.pageFilter = {};
            }
            if (_page.allowDateSelection === undefined) {
                _page.allowDateSelection = true;
            }
            this.setState(() => {
                return {
                    pageName: _page.name,
                    pageData: JSON.parse(JSON.stringify(_page)),
                    pageBackup: JSON.parse(JSON.stringify(_page))
                };
            });
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadGameName();
        this.loadPageData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onEditButton() {
        this.setState((_prevState, _props) => {
            return {
                editMode: true,
                pageData : JSON.parse(JSON.stringify(_prevState.pageBackup))
            };
        });
    }

    onSaveButton() {
        this.progressModal.show("Saving Page "+this.state.pageName);
        this.progressModal.startTask("Saving page ...");
        return GamePagesContext.updatePage(this.state.pageData, (_err) => {
            if (!this._isMounted) {return;}
            if (_err) {
                this.progressModal.error(_err);
                return;
            }
            this.progressModal.complete();
            this.setState((_prevState, _props) => {
                return {
                    editMode: false,
                    pageBackup: JSON.parse(JSON.stringify(_prevState.pageData))
                };
            });
        });
    }

    onDiscardButton() {
        this.setState((_prevState, _props) => {
            return {
                editMode: false,
                pageData : JSON.parse(JSON.stringify(_prevState.pageBackup))
            };
        });
    }

    onDateSelectionChanged(_val) {
        return this.setState((_prevState, _props) => {
            return { dateRange : _val };
        });
    }

    onDateSelectionToggle(_val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.pageData));
            copy.allowDateSelection = _val;
            return { pageData : copy };
        });
    }

    onFilterChanged(_newFilter) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.pageData));
            copy.pageFilter = _newFilter;
            return { pageData : copy };
        });
    }

    onLayoutUpdate(_layout) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.pageData));
            copy.layout = _layout;
            return { pageData : copy };
        })
    }

    renderFilterUI() {
        const page = this.state.pageData;
        if (!page) {return null;}
        if (this.state.editMode === true) {
            return (
                <Panel bordered shaded header={<h6>Filters</h6>} >
                    <HelpBlock>
                        <Toggle checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />} checked={page.allowDateSelection} onChange={this.onDateSelectionToggle}/>
                        &nbsp;
                        Allow Date Selection
                    </HelpBlock>
                    <br/>
                    <FilterTags filter={page.pageFilter} onChange={this.onFilterChanged}/>
                </Panel>
            );
        } else {
            return (
                <Panel bordered shaded header={<h6>Filters</h6>} >
                    <ConditionalFragment condition={page.allowDateSelection}>
                        <HelpBlock>
                            Select Date Range:
                        </HelpBlock>
                        <DateRangePicker value={this.state.dateRange} onChange={this.onDateSelectionChanged} isoWeek cleanable={false}/>
                        <br/><br/>
                    </ConditionalFragment>
                    <FilterTags filter={page.pageFilter} onChange={this.onFilterChanged}/>
                </Panel>
            );
        }
    }


    render() {
        const isAdmin = AnalyticsConnection.hasPermission("admin");
        let layout = null;
        let pageFilter = {};
        let dateSelection = undefined;
        if (this.state.pageData) {
            layout = this.state.pageData.layout ? this.state.pageData.layout : {};
            pageFilter = this.state.pageData.pageFilter ? this.state.pageData.pageFilter : {};
            if (this.state.pageData.allowDateSelection && this.state.dateRange.length === 2) {
                dateSelection = {start: dateToUnixTime(this.state.dateRange[0]), end: dateToUnixTime(this.state.dateRange[1])};
            }
        }
        let pageName = this.state.pageName;
        if (this.state.pageData && this.state.pageData.type === "abTest") {
            pageName = this.state.gameName+" AB-Test: "+pageName;
        }
        if (this.state.pageData && this.state.pageData.type === "page") {
            pageName = this.state.gameName+": "+pageName;
        }
        return (
            <Container style={{margin:"10px"}}>
                <SimpleProgresUI ref={this.progressUI.getRef()}/>
                <SimpleProgressModal ref={this.progressModal.getRef()}/>
                <PageNavigation gameId={this.props.urlParams.gameId} pageId={this.props.urlParams.pageId}/>
                <br/>
                <Panel bordered shaded header={
                    <h4>{pageName}
                        <ConditionalFragment condition={isAdmin && this.state.editMode !== true}>
                            <IconButton appearance={"primary"} icon={<Icon icon={"pencil"}/>} onClick={this.onEditButton} style={{float:"right", marginLeft:10}} />
                        </ConditionalFragment>
                        <ConditionalFragment condition={isAdmin && this.state.editMode === true}>
                            <Button style={{marginLeft:10, float:"right"}} onClick={this.onDiscardButton}>Discard Changes</Button>
                            <Button appearance={"primary"} style={{marginLeft:30, float:"right"}} onClick={this.onSaveButton}>Save Changes</Button>
                        </ConditionalFragment>
                    </h4>
                }>
                    <ConditionalFragment condition={layout !== null}>
                        {this.renderFilterUI()}
                        <br/>
                        <DynamicGraphUIStateless uiLayout={layout} editMode={this.state.editMode} onUpdate={this.onLayoutUpdate} filter={pageFilter} dateSelection={dateSelection}/>
                    </ConditionalFragment>
                </Panel>
            </Container>
        );
    }
}

export default withNavigation(
    withURLParams(
        CustomAnalyticsPage
    )
);