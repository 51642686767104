import React from "react";
import {Button, Icon, IconButton, Panel, SelectPicker} from "rsuite";
import SimpleProgresUI from "../common/SimpleProgresUI";
import NewABTestNameModal from "./NewABTestNameModal";
import ABTestContext from "./ABTestContext";
import ConditionalFragment from "../common/ConditionalFragment";
import DeleteABTestModal from "./DeleteABTestModal";
import AnalyticsConnection from "./AnalyticsConnection";

class ABTestSelectorUI extends React.Component {
    constructor(_props) {
        super(_props);

        this.progressUI = React.createRef();
        this.newABTestNameModal = React.createRef();
        this.deleteABTestModal = React.createRef();

        this.state = {
            abTests : []
        }

        this.loadABTests = this.loadABTests.bind(this);
        this.onNewButton = this.onNewButton.bind(this);
        this.onEditButton = this.onEditButton.bind(this);
        this.onDeleteButton = this.onDeleteButton.bind(this);
        this.onABTestSelected = this.onABTestSelected.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadABTests(false);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadABTests(_forceRefresh) {
        this.progressUI.current.start("Retrieving game config...");
        return ABTestContext.getABTests(this.props.gameId, _forceRefresh,(_err, _abTests) => {
            if (!this._isMounted) {return;}
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            this.progressUI.current.stop();
            let abTestNames = [];
            for (let i = 0; i < _abTests.length; i++) {
                abTestNames.push({label: _abTests[i], value: _abTests[i]});
            }
            return this.setState(() => {
                return {abTestNames: abTestNames};
            });
        });
    }

    onABTestSelected(_val) {
        this.setState(() => {
            return {selectedABTest : _val};
        });
        if (typeof this.props.onSelect === "function") {
            this.props.onSelect(_val);
        }
    }

    onEditButton() {
        if (typeof this.props.onEdit === "function") {
            this.props.onEdit(this.state.selectedABTest);
        }
    }

    onNewButton() {
        return this.newABTestNameModal.current.show((_create,_name) => {
            if (_create) {
                this.progressUI.current.start("Creating new AB-Test...");
                return ABTestContext.updateABTest(this.props.gameId, _name, {}, (_err, _res) => {
                    if(!this._isMounted) {return;}
                    if (_err) {
                        this.progressUI.current.error(_err);
                        return;
                    }
                    this.progressUI.current.stop();
                    this.loadABTests(true);
                });
            }
        });
    }

    onDeleteButton() {
        const name = this.state.selectedABTest;
        return this.deleteABTestModal.current.show(name, (_delete) => {
            if (_delete) {
                this.progressUI.current.start("Deleting new AB-Test...");
                return ABTestContext.deleteABTest(this.props.gameId, name, (_err, _res) => {
                    if(!this._isMounted) {return;}
                    if (_err) {
                        this.progressUI.current.error(_err);
                        return;
                    }
                    this.progressUI.current.stop();
                    this.loadABTests(true);
                });
            }
        });
    }

    render() {
        const isNotLoading = this.progressUI.current && !this.progressUI.current.isVisible();
        const isAdmin = AnalyticsConnection.hasPermission("admin");
        return (
            <Panel bordered shaded header={<h6>Select AB Test</h6>}>
                <NewABTestNameModal ref={this.newABTestNameModal}/>
                <DeleteABTestModal ref={this.deleteABTestModal}/>
                <SimpleProgresUI ref={this.progressUI}/>
                <ConditionalFragment condition={isNotLoading}>
                    <SelectPicker data={this.state.abTestNames} value={this.state.selectedABTest} onChange={this.onABTestSelected} style={{width:400}}/>
                    <IconButton appearance={"primary"} icon={<Icon icon={"refresh2"}/>} onClick={()=>{this.loadABTests(true)}} style={{marginLeft:10}}/>
                    <ConditionalFragment condition={isAdmin}>
                        <IconButton color={"red"} icon={<Icon icon={"trash2"}/>} onClick={this.onDeleteButton} style={{float:"right", marginLeft:10}}/>
                        <IconButton appearance={"primary"} icon={<Icon icon={"plus"}/>} onClick={this.onNewButton} style={{float:"right", marginLeft:10}}/>
                        <Button appearance={"primary"} style={{float:"right", marginLeft:10}} onClick={this.onEditButton}>Edit Page</Button>
                    </ConditionalFragment>
                </ConditionalFragment>
            </Panel>
        );
    }
}

export default ABTestSelectorUI;