import React from "react";
import withNavigation from "../common/SiteWithNavigation";
import withURLParams from "../common/SiteWithURLParams";
import {Container, Panel} from "rsuite";
import ABTestSelectorUI from "./ABTestSelectorUI";
import DynamicGraphUI from "./DynamicGraphUI";
import ABTestContext from "./ABTestContext";
import SimpleProgressModal from "../common/SimpleProgressModal";
import GameContext from "./GameContext";

class ABTestPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            editMode : false,
            gameName : this.props.urlParams.gameId
        };

        this.progressModal = React.createRef();

        this.onEditMode = this.onEditMode.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        GameContext.resolveGameName(this.props.urlParams.gameId, (_err, _gameName) => {
            if (!this._isMounted) {return;}
            if (_err) {
                return;
            }
            return this.setState(() => {
                return {gameName : _gameName};
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onEditMode(_abTest) {
        this.setState(() => {
            return {
                editMode : true,
                editModeTest : _abTest
            };
        });

    }

    onSelect(_abTest) {
        this.setState(() => {
            return {
                selectedTest : _abTest,
                editMode: false
            };
        });
    }


    onSave(_config) {
        this.setState(() => {
            return {
                editMode: false
            };
        });
        this.progressModal.current.show("Save Page Layout.");
        this.progressModal.current.startTask("Saving Layout...");
        return ABTestContext.updateABTest(this.props.urlParams.gameId, this.state.selectedTest, _config, (_err, _res) => {
            if (!this._isMounted) {return;}
            this.progressModal.current.complete();
        });
    }

    render() {
        let graphConfig = ABTestContext.getABTest(this.props.urlParams.gameId, this.state.selectedTest);
        return (
            <Container style={{margin:"10px"}}>
                <SimpleProgressModal ref={this.progressModal}/>
                <Panel bordered shaded header={<h4>AB Tests: {this.state.gameName}</h4>}>
                    <ABTestSelectorUI gameId={this.props.urlParams.gameId} onEdit={this.onEditMode} onSelect={this.onSelect}/>
                    <br/>
                    <DynamicGraphUI uiLayout={graphConfig} editMode={this.state.editMode} onSave={this.onSave}/>
                </Panel>
            </Container>
        );
    }
}

export default
    withNavigation(
        withURLParams(
            ABTestPage
        )
    )
;