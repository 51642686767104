import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";

class AdViewRate_PieChart {
    static getUUID() {
        return "682223e3-bc49-4c5b-b605-a29876e51129";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"withLegend",name:"Show Legend", type: "boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 0.5;
    }

    static getHeightRatio() {
        return "100%";
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        ctx.sendAsync("impressions","ad_impression_v4",["value_count:placement:hits"],[_props.group,"result"], filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let impressionData = _data.impressions.result;
                let series = [];
                let impressionCount = 0;
                let discardCount = 0;
                for (let i = 0; i < impressionData.length; i++) {
                    const current = impressionData[i];
                    if (current.result === 0) {
                        impressionCount += current.hits;
                    } else {
                        discardCount += current.hits;
                    }
                }
                series.push({name: "Impressions", y: impressionCount, text: impressionCount})
                series.push({name: "Discards", y: discardCount, text: discardCount})
                let graphData = {
                    chart : {type: "pie"},
                    plotOptions: { pie : { showInLegend: _props.withLegend}},
                    legend: {enabled: _props.withLegend},
                    series : [{data: series}],
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {}

    static getYAxis() {}

    static getTooltip() {
        return {
            formatter : function() {
                let percentage = (Math.round(this.point.percentage*100)/100)+"%";
                return "<b>"+this.point.name+":</b> "+percentage+" ("+this.point.text+")";
            }
        };
    }
}

export default CachedGraphV2(AdViewRate_PieChart, HighChartsGraph);