const config = {
    version : "v2.2.7",
    isDebug : false,
    useCaching : true,
    loggerSettings : {
        "Graph-Cache" : 0,
        "Async-API" : 0,
        "WSS": 0,
        "Analytics-Cache" : 1,
        "Analytics-Util":0
    },
    meteor:{
        useLiveEnvironment : true,
    },
    analytics:{
        useLiveEnvironment : true,
    },
    firebuild: {
        useLiveEnvironment : true
    }
}

module.exports = config;