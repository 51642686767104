import React from "react";
import AnalyticsContext from "../AnalyticsContext";
import ESRepositoryUI from "./ESRepositoryUI";
import {Container, Panel} from "rsuite";
import ESSnapshotUI from "./ESSnapshotUI";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";

class DBBackupPage extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {
            isLoading: true
        }
        this.ref = React.createRef();

        this.onRepoSelected = this.onRepoSelected.bind(this);
    }

    onRepoSelected(_repo) {
        return this.setState(() => {
            return {selectedRepo : _repo};
        });
    }

    render() {
        return(
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>DB Backups</h4>}>
                    <ESRepositoryUI onSelected={this.onRepoSelected}/>
                    <br/>
                    <ESSnapshotUI repository={this.state.selectedRepo ? this.state.selectedRepo.id : null}/>
                </Panel>
            </Container>
        );
    }
}

export default
    withNavigation(
        withPermission(()=> {return AnalyticsConnection.hasPermission("admin")},
            DBBackupPage
        )
    )
;