import React from "react";
import {HelpBlock, Button, Icon, IconStack, Modal} from "rsuite";
import ColdFireReactRef from "../common/ColdFireReactRef";

function format2(_num) {
    if (_num < 10) {
        return "0"+_num;
    }
    return ""+_num;
}

class DeleteBuildModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            build: {}
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    static getReference() {
        return new ColdFireReactRef(["show"]);
    }

    show(_build, _callback) {
        return this.setState(() => {
            return {
                open: true,
                build: _build,
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onDelete() {
        if(typeof this.state.callback === "function") {
            this.state.callback(true);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    render() {
        let timestamp = new Date(this.state.build.timestamp*1000);
        let date = format2(timestamp.getDate())+"."+format2(timestamp.getMonth())+"."+timestamp.getFullYear();
        let time = format2(timestamp.getHours())+":"+format2(timestamp.getMinutes())+":"+format2(timestamp.getSeconds());
        return (
            <Modal show={this.state.open} size={"xs"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        <IconStack size="lg">
                            <Icon icon="square" stack="2x" style={{color:"red"}}/>
                            <Icon icon="trash" stack="1x" />
                        </IconStack>
                        &nbsp;Delete Build {this.state.build.scmHash}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        <p><Icon icon={"gitlab"}/> {this.state.build.version} ({this.state.build.buildType})</p>
                        <p><Icon icon={"calendar"}/> {date} &nbsp;&nbsp;<Icon icon={"clock-o"}/> {time}</p>
                        <p><Icon icon={"code-fork"}/> {this.state.build.branchName} &nbsp;&nbsp;<Icon icon={"gitlab"}/> {this.state.build.buildNumber}</p>
                        <p><Icon icon={"user"}/> {this.state.build.owner} &nbsp;&nbsp;<Icon icon={"hashtag"}/> {this.state.build.scmHash}</p>
                    </HelpBlock>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onDelete} color={"red"}>
                        Delete Build
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeleteBuildModal;