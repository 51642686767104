import React from "react";
import {Dropdown, Icon, Nav, Sidebar, Sidenav} from "rsuite";
import {ReactComponent as IconMeteor} from "../icons/meteor-solid.svg";
import {ReactComponent as IconLogout} from "../icons/power-off-solid.svg";
import MeteorNavigation from "./MeteorNavigation";
import EventBus from "./EventBus";
import MeteorContext from "../meteor/MeteorContext";
import {Link} from "react-router-dom";
import * as Config from "../config";
import ConditionalFragment from "./ConditionalFragment";

let tempState = null;

class MeteorNavigationBar extends React.Component {
    constructor(_props) {
        super(_props);
        let openKeys = this.getOpenKeys();
        if (openKeys === null) {
            openKeys = [];
        }
        this.state = {
            updateCounter: 0,
            width: 0, height: 0,
            openKeys: openKeys
        };
        this.onOpenChange = this.onOpenChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onNavigationChanged = this.onNavigationChanged.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    getOpenKeys() {
        let openKeys = localStorage.getItem("meteor_navigation");
        if (openKeys) {
            try {
                let keys = JSON.parse(openKeys);
                return keys;
            } catch (ex) {}
        }
        return null;
    }

    componentDidMount() {
        //console.log("mount!");
        let lastState = null;
        if (tempState !== null) {
            lastState = JSON.parse(tempState);
        }
        if (lastState !== null) {
            this.setState(() => {
                return lastState;
            });
        }
        let openKeys = this.getOpenKeys()
        if (openKeys) {
            this.setState(() => {
                return {openKeys: openKeys};
            });
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        EventBus.registerHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    componentWillUnmount() {
        //tempState = JSON.stringify(this.state);
        //console.log("unmount!");
        window.removeEventListener('resize', this.updateWindowDimensions);
        EventBus.unregisterHandler("UPDATE_NAVIGATION", this.onNavigationChanged);
    }

    onNavigationChanged(event) {
        this.setState((_oldState, _props) => {
            return { updateCounter : _oldState.updateCounter +1};
        });
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    onSelect(_event) {
        //console.log("bbb",_event);
        this.setState((_prevState, _props) => {
            // close others Menus
            //console.log("here",_event);
            let newOpen = [];
            for (let i = 0; i < _prevState.openKeys.length; i++) {
                if (_event.indexOf(_prevState.openKeys[i]) === 0) {
                    newOpen.push(_prevState.openKeys[i]);
                }
            }
            //console.log(newOpen);
            return {
                selectedItem: _event,
                //openKeys : newOpen,
                updateCounter : _prevState.updateCounter +1
            };
        });
        //console.log("bbb done");
    }

    getSubNavItems(_prefix, _nodes) {
        let result = [];
        for(let i = 0; i < _nodes.length; i++) {
            const currentNode = _nodes[i];
            let icon = currentNode.getIcon();
            if (currentNode.isSyncing()) {
                icon = (<Icon icon={"refresh2"} spin/>);
            }
            if (currentNode.hasChilds()) {
                let subnodes = this.getSubNavItems(_prefix+"_"+i, currentNode.getChilds());
                result.push(
                    <Dropdown.Menu key={_prefix+"_"+i} eventKey={_prefix+"_"+i} title={currentNode.getName()} icon={icon}>
                        {subnodes}
                    </Dropdown.Menu>
                );
            } else {
                if (currentNode.isDivider() === true) {
                    result.push(<Dropdown.Item divider />);
                }
                else if (currentNode.getLink() === undefined) {
                    result.push(
                        <Dropdown.Item key={_prefix+"_"+i} eventKey={_prefix+"_"+i} icon={icon}>
                            {currentNode.getName()}
                        </Dropdown.Item>
                    )
                } else {
                    let key = _prefix+"_"+i;
                    let active = this.state.selectedItem === key;
                    result.push(
                        <Dropdown.Item componentClass={Link} key={key} eventKey={key} to={currentNode.getLink()} onSelect={this.onSelect} icon={icon} active={active}>
                            {currentNode.getName()}
                        </Dropdown.Item>
                    )
                }
            }
        }
        return result;
    }

    onLogout() {
        MeteorContext.logout();
    }

    onOpenChange(_keys) {
        localStorage.setItem("meteor_navigation", JSON.stringify(_keys));
        return this.setState(() => {
            return {openKeys : _keys};
        });
    }

    render() {
        if (this.state.width === 0) {
            return null;
        }
        let expand = this.state.width > 767;
        let entries = [];
        let childs = MeteorNavigation.getChilds();
        for (let i = 0; i< childs.length; i++) {
            const child = childs[i];
            let icon = child.getIcon();
            if (child.isSyncing()) {
                icon = (<Icon icon={"refresh2"} spin/>);
            }
            let entry = (
                <Dropdown key={"nav_"+i} eventKey={"nav_"+i} trigger={['click', 'hover']} placement={"rightStart"}
                          title={child.getName()} icon={icon}>
                    {this.getSubNavItems("nav_"+i, child.getChilds())}
                </Dropdown>
            );
            entries.push(entry);
        }
        return (
            <Sidebar
                style={{ display: 'flex', flexDirection: 'column' }}
                width={expand ? 260 : 56}
                collapsible
            >
                <Sidenav expanded={expand} appearance="default" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange}>
                    <Sidenav.Header>
                        <div style={{padding:20, color: ' #fff'}}>
                            <i className="rs-icon-size-2x"><IconMeteor/></i>
                            <ConditionalFragment condition={expand}>
                            &nbsp;&nbsp;&nbsp;Meteor {Config.version}
                            </ConditionalFragment>
                        </div>
                    </Sidenav.Header>
                    <Sidenav.Body>
                        <Nav>
                            {entries}
                            <Dropdown eventKey="user" trigger={['click', 'hover']} placement="rightStart" title="Account" icon={
                                <i className="rs-icon"><IconLogout/></i>
                            }>
                                <Dropdown.Item eventKey="user_password" componentClass={Link} to={"/account/changePassword"}>Change Password</Dropdown.Item>
                                <Dropdown.Item eventKey="user_logout" onSelect={this.onLogout}>Logout</Dropdown.Item>
                            </Dropdown>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </Sidebar>
        );
    }
}

export default MeteorNavigationBar;