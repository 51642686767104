import React, {Fragment} from "react";
import {IconButton, Input, Tag, TagGroup, Icon, Tooltip} from "rsuite";

class FilterTag extends React.Component {
    static filerCount(_filters) {
        let counter = 0;
        if (_filters) {
            for (let key in _filters) {
                counter += 1;
            }
        }
        return counter;
    }

    constructor(_props) {
        super(_props);

        this.state = {
            typing: false,
            inputValue: ''
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputConfirm = this.handleInputConfirm.bind(this);
        this.handleTagRemove = this.handleTagRemove.bind(this);
    }

    handleButtonClick() {
        this.setState(
            {
                typing: true
            },
            () => {
                this.input.focus();
            }
        );
    }
    handleInputChange(inputValue) {
        this.setState({ inputValue });
    }

    parseFilterExpression(_text) {
        let tokens = _text.split("=");
        if (tokens.length !== 2) {
            return {error: "Expression does not match: Field = value"};
        }
        let field = tokens[0].trim();
        let val = null;
        let jsonParseError = true;
        try {
            val = JSON.parse(tokens[1]);
            jsonParseError = false;
        } catch (ex) {}
        if (jsonParseError) {
            return {error: "Couldn't parse Value Expression. Has to be json."};
        }
        return {field: field, value: val};
    }

    handleInputConfirm() {
        if (this.state.inputValue.trim() === "") {
            return this.setState(() => {
                return {
                    typing: false,
                    inputValue: "",
                    error: null
                };
            });
        }
        let result = this.parseFilterExpression(this.state.inputValue);
        if (result.error) {
            return this.setState(() => {
                return {error: result.error};
            });
        }
        this.setState(() => {
            return {
                typing: false,
                inputValue: "",
                error: null
            }
        });
        let newFilter = JSON.parse(JSON.stringify(this.props.filter));
        newFilter[result.field] = result.value;
        if (typeof this.props.onChange === "function") {
            this.props.onChange(newFilter);
        }
    }

    handleTagRemove(tag) {
        let newFilter = JSON.parse(JSON.stringify(this.props.filter));
        delete newFilter[tag];
        if (typeof this.props.onChange === "function") {
            this.props.onChange(newFilter);
        }
    }

    renderInput() {
        if (this.state.typing) {
            return (
                <Fragment>
                    <Input
                        className="tag-input"
                        inputRef={ref => {
                            this.input = ref;
                        }}
                        size="xs"
                        style={{ width: 200 }}
                        value={this.state.inputValue}
                        onChange={this.handleInputChange}
                        //onBlur={this.handleInputChange}
                        onPressEnter={this.handleInputConfirm}
                    />
                    <Tooltip visible={this.state.error}>{this.state.error}</Tooltip>
                </Fragment>
            );
        }

        return (
            <IconButton
                className="tag-add-btn"
                onClick={this.handleButtonClick}
                icon={<Icon icon="plus" />}
                appearance="ghost"
                size="xs"
                style={{marginLeft: 10}}
            />
        );
    }

    render() {
        let tags = [];
        let sortedFilters = [];
        for(let field in this.props.filter) {
            sortedFilters.push({field: field, value : this.props.filter[field]});
        }
        sortedFilters.sort((_a, _b) => _a.field.localeCompare(_b.field));
        for (let i = 0; i < sortedFilters.length; i++) {
            let text = sortedFilters[i].field+" = "+JSON.stringify(sortedFilters[i].value);
            tags.push(<Tag key={"filter_"+i} closable onClose={() => {this.handleTagRemove(sortedFilters[i].field)}} >{text}</Tag>);
        }
        return (
            <TagGroup>
                {tags}
                {this.renderInput()}
            </TagGroup>
        )
    }
}

export default FilterTag;