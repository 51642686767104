import React, {Fragment} from "react";
import {Button, Container, Icon, IconButton, Panel, Table, Notification} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import DeleteIndexModal from "./DeleteIndexModal";
import ReindexNameModal from "./ReindexNameModal";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";

class ESIndicesPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {};

        this.progressUI = React.createRef();
        this.reindexNameModal = React.createRef();
        this.deleteIndexModal = React.createRef();

        this.reloadIndices = this.reloadIndices.bind(this);
        this.onReindexButton = this.onReindexButton.bind(this);
        this.onDeleteButton = this.onDeleteButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.reloadIndices();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    reloadIndices() {
        this.progressUI.current.start("Retrieving indices...");
        return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
            method: "GET", path: "/_stats", contentType : "Application/json", payload: "{}"}, (_err, _stats) => {
            if(!this._isMounted) { return; }
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            return AnalyticsConnection.sendAuthenticated({type: "admin", subType: "esRequest",
                method: "GET", path: "/_alias", contentType : "Application/json", payload: "{}"}, (_err, _aliases) => {
                if (!this._isMounted) { return; }
                if (_err) {
                    this.progressUI.current.error(_err);
                    return;
                }
                this.progressUI.current.stop();
                let idxLUT = {};
                if (_stats.result && _stats.result.indices) {
                    for(let key in _stats.result.indices) {
                        if (!idxLUT[key]) {
                            idxLUT[key] = {name : key};
                        }
                        const current = _stats.result.indices[key];
                        if (current && current.total && current.total.docs) {
                            idxLUT[key].docCount = current.total.docs.count.toLocaleString();
                        }
                    }
                }
                if (_aliases.result) {
                    for(let key in _aliases.result) {
                        if (!idxLUT[key]) {
                            idxLUT[key] = {name : key};
                        }
                        let aliasList = [];
                        const current = _aliases.result[key];
                        for(let aliasName in current.aliases) {
                            aliasList.push(aliasName);
                        }
                        idxLUT[key].alias = aliasList.join(",");
                    }
                }
                let indices = [];
                for(let key in idxLUT) {
                    indices.push(idxLUT[key]);
                }
                indices.sort((_a, _b) => {return _a.name.localeCompare(_b.name);});

                this.setState(() => {
                    return {indices : indices};
                })
            });
        });
    }

    onReindexButton(_indexName) {
        let indexNames = [];
        for (let i = 0; i < this.state.indices.length; i++) {
            indexNames.push(this.state.indices[i].name);
        }
        this.reindexNameModal.current.show(_indexName, indexNames, (_copy, _newName) => {
            if (_copy === true) {
                let payload = {
                    source : { index: _indexName },
                    dest : { index: _newName }
                }
                return AnalyticsConnection.sendAuthenticated({type: "admin", subType:"esRequest",
                    method: "POST", path: "/_reindex?wait_for_completion=false&refresh=true", contentType: "Application/json", payload:JSON.stringify(payload)}, (_err, _res) => {
                    if(!this._isMounted) { return; }
                    if (_err) {
                        return;
                    }
                    this.reloadIndices();
                });
            }
        });
    }

    onDeleteButton(_indexName) {
        this.deleteIndexModal.current.show(_indexName, (_delete) => {
            if (_delete) {
                return AnalyticsConnection.sendAuthenticated({type: "admin", subType : "esRequest",
                    method: "DELETE", path: "/"+_indexName, contentType: "Application/json", payload: "{}"}, (_err, _res) => {
                    if(!this._isMounted) { return; }
                    if(_err) {
                        return;
                    }
                    Notification.success({title: "Success", description: "Succesfully deleted Index."});
                    this.reloadIndices();
                });
            }
        });
    }



    render() {
        return (
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>Indices</h4>}>
                    <ReindexNameModal ref={this.reindexNameModal}/>
                    <DeleteIndexModal ref={this.deleteIndexModal}/>
                    <SimpleProgresUI ref={this.progressUI}/>
                    <Table data={this.state.indices} autoHeight>
                        <Table.Column resizable width={200}>
                            <Table.HeaderCell>Index</Table.HeaderCell>
                            <Table.Cell dataKey={"name"}/>
                        </Table.Column>
                        <Table.Column resizable width={200}>
                            <Table.HeaderCell>Alias</Table.HeaderCell>
                            <Table.Cell dataKey={"alias"}/>
                        </Table.Column>
                        <Table.Column resizable width={150} align={"right"}>
                            <Table.HeaderCell>Documents</Table.HeaderCell>
                            <Table.Cell dataKey={"docCount"}/>
                        </Table.Column>
                        <Table.Column width={200}>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <Table.Cell>
                                {(_rowData)=> {
                                    return (
                                        <Fragment>
                                            <span>
                                                <Button color={"green"} size="xs" style={{marginLeft:"10px"}} href={"/analytics/db/indices/"+_rowData.name}>Details</Button>
                                            </span>
                                            <span>
                                                <IconButton appearance={"primary"} icon={<Icon icon={"copy-o"}/>} size="xs" style={{marginLeft:"10px"}} onClick={()=> {this.onReindexButton(_rowData.name);}} />
                                            </span>
                                            <span>
                                                <IconButton color={"red"} icon={<Icon icon={"trash"}/>} size="xs" style={{marginLeft:"10px"}} onClick={()=> {this.onDeleteButton(_rowData.name);}} />
                                            </span>
                                        </Fragment>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </Panel>
            </Container>
        );
    }
}

export default
    withNavigation(
        withPermission(() => {return AnalyticsConnection.hasPermission("admin")},
            ESIndicesPage
        )
    );