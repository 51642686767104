import * as React from "react";
import {
    tooltipFormat,
    XAxisFormatRetention,
    XAxisFormatTime,
    YAxisFormatEuro,
    YAxisFormatNumber,
    YAxisFormatPercent,
    YAxisFormatTime
} from "./GraphFormatting";

import Highcharts from 'highcharts'
import more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official'
import Theme from "./HighChartsTheme";
import "./loading.css";
import {addSum} from "./DataUtil";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

more(Highcharts);

class LineGraph extends React.Component {
    constructor(_props) {
        super(_props);
    }

    render() {

        Highcharts.theme = Theme;
        Highcharts.setOptions(Highcharts.theme);

        let options = this.props.chartOptions;
        return (<HighchartsReact highcharts={Highcharts} options={options} ref={this._chartRef}/>);
    }
}

export default LineGraph;