import ESQueryUtil from "./ESQueryUtil";
import AsyncContext from "../common/AsyncContext";
import AnalyticsConnection from "./AnalyticsConnection";

const indexProperties = {
    "user" : ["ab_group", "country", "device_model", "game_id", "install_date", "install_version", "is_payer", "platform", "ua_type"],
    "session_duration_agg" : ["ab_group", "country", "device_model", "game_id", "install_date", "day", "install_version", "is_payer", "platform", "ua_type"]
}

function normalizeFilters(_indexName, _filters) {
    if (indexProperties[_indexName]) {
        let normalized = {};
        for (let property in _filters) {
            if (indexProperties[_indexName].indexOf(property) >= 0) {
                normalized[property] = _filters[property];
            }
        }
        return normalized;
    }
    return _filters;
}

function convertDateTimeFields(_obj) {
    for (let key in _obj) {
        if (key === "install_date" || key === "day") {
            let date = new Date(_obj[key] * 1000);
            _obj[key] = date;
        }
    }
    return _obj;
}

function parseAggregation(_text) {
    let tokens = _text.split(":");
    const type = tokens[0];
    const field = tokens[1];
    let alias = tokens[2];
    if (alias === undefined) {
        alias = field;
    }
    if (tokens.length >= 2) {
        return {
            field : field,
            type : type,
            alias : alias
        };
    }
    return null;
}

function buildAggregation(_fields) {
    let aggregate = {};
    let count = 0;
    if (_fields) {
        for (let i = 0; i < _fields.length; i++) {
            const current = parseAggregation(_fields[i]);
            if (current !== null) {
                aggregate[current.alias] = {};
                aggregate[current.alias][current.type] = {field: current.field};
                if (current.type === "cardinality") {
                    aggregate[current.alias][current.type].precision_threshold = 40000;
                }
                count += 1;
            }
        }
    }
    return aggregate;
}

function buildCompositeAggregation(_groups) {
    let sources = [];
    if (_groups) {
        for( let i = 0; i < _groups.length; i++) {
            const fieldName = _groups[i];
            if (fieldName !== undefined && fieldName !== null && fieldName !== "") {
                let termObj = { terms: { field: fieldName /*, missing_bucket: true*/}};
                let obj = {};
                obj[fieldName] = termObj;
                sources.push(obj);
            }
        }
    }
    return sources;
}

function retrieveDocuments_internal(_index, _query, _data, _cb) {
    return AnalyticsConnection.sendAuthenticated({type:"analytics_v3", subType:"es_search_v3", index: _index, query: _query}, (_err, _response) => {
        if (_err) {
            return _cb(_err);
        }
        let json = null;
        try {
            json = JSON.parse(_response.result);
        } catch (ex) {};
        if (json === null) {
            return _cb("Unparsable JSON: "+_response.result);
        }
        let searchAfter = null;
        if (json && json.hits && json.hits.hits) {
            let hits = json.hits.hits;
            for(let i = 0; i < hits.length; i++) {
                _data.push(hits[i]._source);
                searchAfter = hits[i].sort;
            }
        }
        if (searchAfter !== null) {
            let queryCopy = JSON.parse(JSON.stringify(_query));
            queryCopy.search_after = searchAfter;
            return retrieveDocuments_internal(_index, queryCopy, _data, _cb);
        }
        return _cb(null, _data);
    });
}

function queryAggregation(_index, _query, _data, _cb) {
    return AnalyticsConnection.sendAuthenticated({type: "analytics_v3",subType: "es_search_v3", index: _index, query: _query}, (_err, _response) => {
        if (_err) {
            return _cb(_err);
        }
        if (_response.type === "analytics_v3" && _response.subType === "es_search_v3" && _response.result) {
            let json = null;
            try {
                json = JSON.parse(_response.result);
            } catch (ex) {};
            if (json === null) {
                return _cb("Unparsable JSON: "+_response.result);
            }
            if (json.aggregations && !json.aggregations.aggregation_result) { // query without buckets
                let obj = {};
                let entry = json.aggregations;
                for (let keyName in entry) {
                    if (keyName === "key") {
                        continue;
                    }
                    if (keyName === "doc_count") {
                        obj.doc_count = entry[keyName];
                    }
                    if (typeof entry[keyName] === "object" && entry[keyName].value) {
                        obj[keyName] = entry[keyName].value;
                    }
                }
                convertDateTimeFields(obj);
                _data.push(obj);
                return _cb(null, _data);
            }
            if (json.aggregations && json.aggregations.aggregation_result && json.aggregations.aggregation_result.buckets) {
                const buckets = json.aggregations.aggregation_result.buckets;
                for(let i = 0; i < buckets.length; i++) {
                    const entry = buckets[i];
                    let obj = {};
                    for (let keyName in entry) {
                        if (keyName === "key") {
                            continue;
                        }
                        if (keyName === "doc_count") {
                            obj.doc_count = entry[keyName];
                        }
                        if (typeof entry[keyName] === "object" && entry[keyName].value) {
                            obj[keyName] = entry[keyName].value;
                        }
                    }
                    for(let keyName in entry.key) {
                        obj[keyName] = entry.key[keyName];
                    }
                    convertDateTimeFields(obj);
                    _data.push(obj);
                }
                if (buckets.length >= 1 && json.aggregations.aggregation_result.after_key) {
                    // resend
                    let cloneQuery = JSON.parse(JSON.stringify(_query));
                    cloneQuery.aggs.aggregation_result.composite.after = json.aggregations.aggregation_result.after_key;
                    return queryAggregation(_index, cloneQuery, _data, _cb);
                }
                else {
                    return _cb(null, _data);
                }
            }
        }
    });
}

class ESContext {
    constructor() {
        this.queryAggregation = this.queryAggregation.bind(this);
    }

    getAsyncContext() {
        return AsyncContext(this.queryAggregation, AnalyticsConnection.cancelRequest);
    }

    retrieveDocuments(_index, _filter, _sorting, _cb) {
        const filter = ESQueryUtil.buildFilterQuery(_filter);
        let sort = [];
        if (!Array.isArray(_sorting)) {
            _sorting = [_sorting];
        }
        for (let i =0; i < _sorting.length; i++) {
            let obj = {};
            obj[_sorting[i]] = "asc";
            sort.push(obj);
        }
        let query = {
            size: 10000,
            query: {
                bool : {
                    filter: filter
                }
            },
            sort: sort
        };
        return retrieveDocuments_internal(_index, query, [] ,_cb);
    }

    queryAggregation(_index, _fields, _groups, _filter, _cb) {
        const filter = ESQueryUtil.buildFilterQuery(normalizeFilters(_index,_filter));
        const aggregations = buildAggregation(_fields);
        const groupingSources = buildCompositeAggregation(_groups);
        if (aggregations === null) {
            return _cb("ERR: Invalid aggregation query: Missing Fields");
        }
        if (groupingSources === null) {
            return _cb("ERR: Invalid aggregation query: Missing Groups");
        }
        let query = {
            size: 0,
            query: {
                bool: {
                    filter: filter
                }
            }
        }
        if (groupingSources.length === 0) {
            query.aggs = aggregations;
        } else {
            query.aggs = {
                aggregation_result: {
                    composite: {
                        sources: groupingSources,
                        size: 250
                    },
                    aggs: aggregations
                }
            };
        }
        return queryAggregation(_index, query, [], _cb);
    }
}

const instance = new ESContext();
export default instance;