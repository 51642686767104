import React, {Fragment} from "react";
import {
    Button,
    ButtonToolbar,
    Container,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Message,
    Panel,
    Schema
} from "rsuite";
import MeteorNavigationBar from "../common/MeteorNavigationBar";
import MeteorContext from "./MeteorContext";
import {Redirect} from "react-router";
import ColdFireReactRef from "../common/ColdFireReactRef";
import SimpleProgressModal from "../common/SimpleProgressModal";

const model = Schema.Model( {
    username: Schema.Types.StringType().isRequired("Username is required"),
    password: Schema.Types.StringType().isRequired("Password is required")
})

class AddUserPage extends React.Component {

    constructor(_props) {
        super(_props);

        this.state = {
            isCreating :false
        };
        this.setFormData = this.setFormData.bind(this);
        this.onCreateButton = this.onCreateButton.bind(this);

        this.progressUI = new ColdFireReactRef();


    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setFormData(_val) {
        return this.setState(() => {
            return { createForm : _val};
        });
    }

    onCreateButton() {
        if (!this.state.createForm || !this.state.createForm.username || !this.state.createForm.password) {
            return this.setState(() => {
                return {
                    error : {
                        title: "Create User failed!",
                        description: "Username and Password required!"
                    }
                }
            })
        }
        this.progressUI.call("show","Create Meteor User");
        this.progressUI.call("startTask","Creating User...");
        this.setState(() => {
            return {isCreating: true};
        });
        return MeteorContext.sendAuthenticated({type:"auth", subType:"createUser", userId: this.state.createForm.username, password: this.state.createForm.password}, (_err, _res ) =>
        {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.call("error", _err);

                return this.setState(() => {
                    return {
                        isCreating : false,
                        error : null
                    };
                });
            }
            this.progressUI.call("complete");
            return this.setState(() => {
                return { redirect : "/meteor/userMgmt" };
            });
        });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>);
        }
        let error = null;
        if (this.state.error) {
            error = (
                <Fragment>
                    <Message showIcon type={"error"} title={this.state.error.title} description={this.state.error.description} />
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Container>
                    <MeteorNavigationBar/>
                    <Container style={{margin:"10px"}}>
                        <SimpleProgressModal ref={this.progressUI.getRef()}/>
                        <br/>
                        <Panel style={{margin:"10px"}} header={<h3>Add Meteor User</h3>} bordered>
                            <br/>
                            <Form fluid model={model} onChange={this.setFormData} style={{maxWidth:"350px"}}>
                                <FormGroup>
                                    <ControlLabel>Username</ControlLabel>
                                    <FormControl name="username" placeholder="username" disabled={this.state.isCreating}/>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Password</ControlLabel>
                                    <FormControl name="password" type="password" placeholder="password" disabled={this.state.isCreating}/>
                                </FormGroup>
                                {error}
                                <br/>
                                <FormGroup>
                                    <ButtonToolbar>
                                        <Button loading={this.state.isCreating} active={!this.state.isCreating} block appearance="primary" onClick={this.onCreateButton}>Create User</Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </Form>
                        </Panel>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default AddUserPage;