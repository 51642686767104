import React from "react";


class ColdFireReactRef {
    constructor(_methodNames) {
        this._ref = React.createRef();
        this._cmdQueue = [];
        this._timerHandle = null;
        this._deadCounter = 20;

        this.getRef = this.getRef.bind(this);
        this.call = this.call.bind(this);
        this._processQueued = this._processQueued.bind(this);
        this._reset = this._reset.bind(this);
        if (_methodNames) {
            for (let i = 0; i < _methodNames.length; i++) {
                let methodName = _methodNames[i];
                this[methodName] = (...args) => {
                    const name = methodName;
                    return this.call(name, ...args);
                };
                this[methodName] = this[methodName].bind(this);
            }
        }
    }

    _reset() {
        if (this._timerHandle !== null) {
            clearInterval(this._timerHandle);
            this._timerHandle = null;
        }
        this._ref = React.createRef();
        this._cmdQueue = [];
    }

    _processQueued() {
        this._deadCounter -= 1;
        if (this._ref.current !== null) {
            let list = this._cmdQueue;
            this._cmdQueue = [];
            clearInterval(this._timerHandle);
            this._timerHandle = null;
            for (let i = 0; i < list.length; i++) {
                const current = list[i];
                this._ref.current[current.name](...current.params);
            }
        }
        if (this._deadCounter < 0) {
            this._reset();
        }
    }

    getRef() {
        return this._ref;
    }

    call(_name, ..._params) {
        if (this._ref.current !== null) {
            this._ref.current[_name](..._params);
        } else {
            this._cmdQueue.push({name: _name, params: _params});
            if (this._timerHandle === null) {
                this._timerHandle = setInterval(this._processQueued, 200);
            }
        }

    }
}

export default ColdFireReactRef;