import {getUnixTime} from "../TimeUtil";

class CallbackStore {
    constructor() {
        this._callbacks = {};
    }

    storeCallback(_id, _cb) {
        this._callbacks[_id] = { id: _id, callback: _cb, time: getUnixTime()};
    }

    extendTimeout(_id) {
        if (this._callbacks[_id]) {
            this._callbacks[_id].time = getUnixTime();
        }
    }

    getCallback(_id) {
        const entry = this._callbacks[_id];
        if (entry) {
            let cb = this._callbacks[_id].callback;
            delete this._callbacks[_id];
            return cb;
        }
        return null;
    }

    getCount() {
        let count = 0;
        for (let key in this._callbacks) {
            if (key) {
                count += 1;
            }
        }
        return count;
    }

    getTimedOut(_ts) {
        let timeoutList = [];
        for (let key in this._callbacks) {
            if(this._callbacks[key].time < _ts) {
                timeoutList.push(key);
            }
        }
        let callbackList = [];
        for(let i = 0; i < timeoutList.length; i++) {
            const key = timeoutList[i];
            const entry = this._callbacks[key];
            if (entry) {
                callbackList.push({key: key, callback: entry.callback});
                delete this._callbacks[key];
            }
        }
        return callbackList;
    }


}

export default CallbackStore;