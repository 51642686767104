import AnalyticsConnection from "./AnalyticsConnection";

function extractABTestNames(_data) {
    let list = [];
    for(let i = 0; i < _data.abTests.length; i++) {
        list.push(_data.abTests[i].name);
    }
    list.sort();
    return list;
}

function extractABTest(_data, _name) {
    for(let i = 0; i < _data.abTests.length; i++) {
        if (_data.abTests[i].name === _name) {
            return _data.abTests[i].config
        }
    }
    return null;
}

class ABTestContext {
    constructor() {
        this._abTests = {};

        this.updateABTest = this.updateABTest.bind(this);
        this.deleteABTest = this.deleteABTest.bind(this);
        this.retrieveABTests = this.retrieveABTests.bind(this);
        this.getABTests = this.getABTests.bind(this);
        this.getABTest = this.getABTest.bind(this);
    }

    updateABTest(_gameId, _name, _config, _cb) {
        return AnalyticsConnection.sendAuthenticated({type: "game", subType: "updateABConfig", gameId: _gameId, name: _name, config : _config}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            return this.retrieveABTests(_gameId, _cb);
        });
    }

    deleteABTest(_gameId, _name, _cb) {
        return AnalyticsConnection.sendAuthenticated({type: "game", subType: "deleteABConfig", gameId: _gameId, name: _name}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            return _cb();
        });
    }

    retrieveABTests(_gameId, _cb) {
        return AnalyticsConnection.sendAuthenticated({type: "game", subType: "getABConfig", gameId: _gameId}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            this._abTests[_gameId] = _res.config;
            return _cb(null, this._abTests[_gameId]);
        });
    }

    getABTests(_gameId, _forceRefresh,  _cb) {

        if (!this._abTests[_gameId] || _forceRefresh) {
            return this.retrieveABTests(_gameId, (_err) => {
                if(_err) {
                    return _cb(_err);
                }
                return _cb(null, extractABTestNames(this._abTests[_gameId]));
            });
        }
        return _cb(null, extractABTestNames(this._abTests[_gameId]));
    }

    getABTest(_gameId, _name) {
        if (this._abTests && this._abTests[_gameId]) {
            return extractABTest(this._abTests[_gameId], _name);
        }
        return null;
    }



}

const instance = new ABTestContext();
export default instance;