import * as Config from "../config";
import WSConnection from "../common/wss/WSConnection";
import EventBus from "../common/EventBus";
import jwt from "jsonwebtoken";
import MeteorContext from "../meteor/MeteorContext";
import Logger from "../common/Logger";
import AsnycContext from "../common/AsyncContext";

import { v4 as uuidv4 } from 'uuid';

class AnalyticsConnection {
    constructor() {
        let url = "wss://analytics.coldfire.io/test";
        if (Config.analytics.useLiveEnvironment === true) {
            url = "wss://analytics.coldfire.io/live";
        }
        this._connection = new WSConnection(url);
        this._authCallbackQueue = [];

        this.onMeteorAuthenticationChanged = this.onMeteorAuthenticationChanged.bind(this);
        this.getAsyncContext = this.getAsyncContext.bind(this);
        this.sendAuthenticated = this.sendAuthenticated.bind(this);
        this.ensureAuthentication = this.ensureAuthentication.bind(this);
        this.cancelRequest = this.cancelRequest.bind(this);

        this.inFlightRequests = {};

        EventBus.registerHandler("METEOR_AUTHENTICATION_CHANGED", this.onMeteorAuthenticationChanged);
    }

    getToken() {
        return localStorage.getItem("meteor_analytics_auth_token");
    }

    setToken(_token) {
        localStorage.setItem("meteor_analytics_auth_token", _token);
        EventBus.notify("ANALYTICS_AUTHENTICATION_CHANGED", {token: _token});
    }

    getPermissions() {
        const token = this.getToken();
        if (token) {
            const decoded = jwt.decode(token);
            const now = Math.floor(new Date().getTime()/1000);
            if (decoded && decoded.exp && decoded.exp > now && decoded.permissions) {
                return decoded.permissions;
            }
        }
        return {};
    }

    hasPermission(_permission) {
        const permissions = this.getPermissions();
        return permissions && permissions[_permission] === true;
    }

    isAuthenticated() {
        const token = this.getToken();
        if (token) {
            const decoded = jwt.decode(token);
            const now = Math.floor(new Date().getTime()/1000);
            if (decoded && decoded.exp && decoded.exp > now) {
                return true;
            }
        }
        return false
    };

    authenticate(_cb) {
        if(MeteorContext.isAuthenticated()) {
            if (this._authCallbackQueue.length > 0) {
                this._authCallbackQueue.push(_cb);
                return;
            }
            this._authCallbackQueue.push(_cb);
            const meteorToken = MeteorContext.getToken();
            return this._connection.send({type: "auth", subType: "loginMeteor", authToken: meteorToken}, (_response) => {
                if (_response.type === "error") {
                    return _cb({code: _response.errorCode, message: _response.errorText});
                }
                if (_response.type === "auth" && _response.subType === "authToken" && _response.token) {
                    this.setToken(_response.token);
                }
                let callbacks = this._authCallbackQueue;
                this._authCallbackQueue = [];
                for (let i = 0; i < callbacks.length; i++) {
                    callbacks[i]();
                }
            });
        }
        return _cb({code: "401", message: "No Meteor Token available. Please login with Meteor first."});
    }

    ensureAuthentication(_cb) {
        if (this.isAuthenticated()) {
            return _cb();
        } else {
            return this.authenticate(_cb);
        }
    }

    sendAuthenticated(_params, _cb) {
        const requestId = uuidv4();
        this.ensureAuthentication((_err) => {
            if (_err) {
                return _cb(_err);
            }
            let params = JSON.parse(JSON.stringify(_params));
            params.authToken = this.getToken();
            let t0 = Date.now();
            const connectionId = this._connection.send(params, (_response, _meta) => {
                delete this.inFlightRequests[requestId];
                let t1 = Date.now();
                Logger.logDebug("AnalyticsUtil", "Response is:", _response);
                let json = null;
                try {
                    json = JSON.parse(_response.result);
                } catch (ex) {}
                if (json && json.took) {
                    //console.log("### ES-Overhead:"+((t1-t0)-json.took)+"ms  Length:"+(_response.result.length)+"  Multi:"+_meta.multipart);
                }
                if (_response.type === "error") {
                    return _cb({code: _response.errorCode, message: _response.errorText});
                }
                return _cb(null, _response);
            });
            this.inFlightRequests[requestId] = connectionId;
        });
        return requestId;
    }

    cancelRequest(_requestId) {
        if (this.inFlightRequests[_requestId]) {
            const connectionId = this.inFlightRequests[_requestId];
            delete this.inFlightRequests[_requestId];
            this._connection.cancelRequest(connectionId);
        }
    }

    getAsyncContext() {
        return AsnycContext(this.sendAuthenticated);
    }

    onMeteorAuthenticationChanged(_data) {
        this.setToken(null);
        if (_data.token) {
            return this.authenticate(()=>{});
        }
    }

}

const instance = new AnalyticsConnection();
export default instance;