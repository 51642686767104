
function addRouteInternal(_routes, _path, _component, _isPublic) {
    let found = false;
    for (let i = 0; i < _routes.length; i++) {
        if (_routes[i].path === _path) {
            found = true;
            break;
        }
    }
    if (!found) {
        if (_isPublic === true) {
            _routes.push({path : _path, component : _component, isPublic: true});
        } else {
            _routes.push({path : _path, component : _component});
        }
    }
}

class Routes {
    constructor() {
        this._routes = [];
    }

    addRoute(_path, _component) {
        addRouteInternal(this._routes, _path, _component, false);
    }

    addPublicRoute(_path, _component) {
        addRouteInternal(this._routes, _path, _component, true);
    }

    getRoutes() {
        let result = [];
        for (let i = 0; i < this._routes.length; i++) {
            const entry = this._routes[i];
            result.push({path: entry.path, component: entry.component, isPublic: entry.isPublic});
        }
        return result;
    }
}

const instance = new Routes();
export default instance;