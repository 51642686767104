
import { v4 as uuidv4 } from 'uuid';

function rewriteIds(_config) {
    if (_config.id) {
        _config.id = uuidv4();
    }
    if (_config.uiList) {
        for (let i = 0; i < _config.uiList.length; i++) {
            rewriteIds(_config.uiList[i]);
        }
    }
}

function pushItem(_config, _forward,  _id) {
    if (_config.uiList) {
        let idx = -1;
        for(let i = 0; i < _config.uiList.length; i++) {
            const current = _config.uiList[i];
            if (current.id === _id) {
                idx = i;
                break;
            }
        }
        if (idx >= 0) {
            if (_forward) {
                if (idx < _config.uiList.length -1) {
                    let item = _config.uiList.splice(idx, 1);
                    _config.uiList.splice(idx+1, 0 , item[0]);
                }
            }
            if (!_forward) { // backward
                if (idx > 0) {
                    let item = _config.uiList.splice(idx, 1);
                    _config.uiList.splice(idx-1, 0 , item[0]);
                }
            }
            return true;
        }
        for(let i = 0; i < _config.uiList.length; i++) {
            const current = _config.uiList[i];
            if (current.uiList) {
                let result = pushItem(current, _forward, _id);
                if (result === true) {
                    return true;
                }
            }
        }
    }
    return false;
}

function getIdNode(_config, _id) {
    if (_config.uiList) {
        for(let i = 0; i < _config.uiList.length; i++) {
            const current = _config.uiList[i];
            if (current.id === _id) {
                return current;
            }
            if (current.uiList) {
                let result = getIdNode(current, _id);
                if (result !== null) {
                    return result;
                }
            }
        }
    }
    return null;
}

function deleteNode_internal(_config, _id) {
    if (_config.uiList) {
        for(let i = 0; i < _config.uiList.length; i++) {
            const current = _config.uiList[i];
            if (current.id === _id) {
                _config.uiList.splice(i,1);
                return;
            }
            if (current.uiList) {
                deleteNode_internal(current, _id);
            }
        }
    }
}

function ensureUiList(_obj) {
    if (!_obj) {
        _obj = {};
    }
    if (!_obj.uiList) {
        _obj.uiList = [];
    }
    return _obj;
}

function CreateNode(_model, _parentId, _type, _config) {
    let model = ensureUiList(_model);
    let parent = model.uiList;
    if (_parentId !== null && _parentId !== undefined) {
        let node = getIdNode(model, _parentId);
        if (node) {
            parent = node.uiList;
        }
    }
    let object = {id : uuidv4(), type: _type, config: _config};
    if (_type === "panel") {
        object.uiList = [];
    }
    parent.push(object);
    return model;
}

function PasteNodeDefinition(_model, _parentId, _uiDefinition) {
    rewriteIds(_uiDefinition);
    let model = ensureUiList(_model);
    let parent = model.uiList;
    if (_parentId !== null && _parentId !== undefined) {
        let node = getIdNode(model, _parentId);
        if (node) {
            parent = node.uiList;
        }
    }
    parent.push(_uiDefinition);
    return model;
}

function SetNodeConfig(_model, _id, _config) {
    let model = ensureUiList(_model);
    let node = getIdNode(model, _id);
    if (node != null) {
        node.config = _config;
    }
    return model;
}

function DeleteNode(_model, _id) {
    let model = ensureUiList(_model);
    deleteNode_internal(model, _id);
    return model;
}

function GetNode(_model, _id) {
    let model = ensureUiList(_model);
    return getIdNode(model, _id);
}

function PushForward(_model, _id) {
    let model = ensureUiList(_model);
    pushItem(model, true, _id);
    return model;
}

function PushBackward(_model, _id) {
    let model = ensureUiList(_model);
    pushItem(model, false, _id);
    return model;
}

function CopyLayout(_model, _id) {
    let model = ensureUiList(_model);
    let node = getIdNode(model, _id);
    let clone = JSON.parse(JSON.stringify(node));
    rewriteIds(clone);
    return clone;
}


export {CreateNode, SetNodeConfig, DeleteNode, GetNode, PushForward, PushBackward, CopyLayout, PasteNodeDefinition};