import React, {Fragment} from 'react';
import {Notification, FlexboxGrid, Container, Panel, List, Icon, IconButton} from "rsuite";
import MeteorNavigationBar from "../common/MeteorNavigationBar";
import MeteorContext from "./MeteorContext";
import MeteorUserUI from "./MeteorUserUI";
import DeleteUserModal from "./DeleteUserModal";
import SimpleProgressUI from "../common/SimpleProgresUI";
import ColdFireReactRef from "../common/ColdFireReactRef";
import SimpleProgressModal from "../common/SimpleProgressModal";

function UserListEntry(_props) {
    return(
        <List.Item >
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={2}>
                    <Icon icon={"user"}/>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={15}>
                    {_props.name}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2}>
                    <IconButton size="xs" color="primary" icon={<Icon icon={"pencil"}/>} onClick={_props.onEdit}/>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2}/>
                <FlexboxGrid.Item colspan={2}>
                    <IconButton size="xs" color="red" icon={<Icon icon={"trash"}/>} onClick={_props.onDelete} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </List.Item>
    );
}

class UserManagementPage extends React.Component {
    constructor(_props) {
        super(_props);
        this.state = {
            isLoading: true
        };

        this.progressUI = new ColdFireReactRef();
        this.progressModal = new ColdFireReactRef();
        this.deleteUserModal = React.createRef();

        this.onSaveUser = this.onSaveUser.bind(this);
        this.reloadUsers = this.reloadUsers.bind(this);
        this.renderUserSelection = this.renderUserSelection.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.onCloseUser = this.onCloseUser.bind(this);
        this.onDeleteUserButton = this.onDeleteUserButton.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.reloadUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    reloadUsers()
    {
        this.setState(() => {
            return {userList : null};
        });
        this.progressUI.call("start","Retrieving Users...");
        return MeteorContext.sendAuthenticated({type:"auth", subType:"list"}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.call("error",_err);
                return;
            }
            _res.users.sort((_a,_b) => { return _a.id.localeCompare(_b.id)});
            this.setState(() => {
                return { userList : _res.users };
            });
            return MeteorContext.sendAuthenticated({type:"auth", subType:"getMeta"}, (_err, _res) => {
                if (!this._isMounted) { return; }
                if (_err) {
                    this.progressUI.call("error",_err);
                    return;
                }
                this.progressUI.call("stop");
                this.setState(() => {
                    return {
                        validPermissions : _res.permissions
                    };
                });
            });
        });
    }

    onSaveUser(_user) {
        return MeteorContext.sendAuthenticated({type:"auth", subType: "lockUser", userId: _user.id, locked: _user.locked}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                Notification.error({
                    title: "Save failed",
                    description: _err.message
                });
            }
            let permissionList = [];
            for(let key in _user.permissions) {
                permissionList.push({permission: key, allow: _user.permissions[key] === true});
            }
            return MeteorContext.sendAuthenticated({type: "auth", subType: "changePermission", userId : _user.id, permissions: permissionList}, (_err, _res) => {
                if (!this._isMounted) { return; }
                if (_err) {
                    Notification.error({
                        title: "Save failed",
                        description: _err.message
                    });
                    return;
                }
                Notification.success({
                    title: "Changes saved",
                    description: "Changes saved successfully"
                });
                this.onUserSelected(null);
                this.reloadUsers();
            });
        });
    }

    onDeleteUserButton(_item) {
        const userId = _item.id;
        return this.deleteUserModal.current.show(userId, (_delete) => {
            if (_delete) {
                this.progressModal.call("show","Deleting User "+_item.name);
                this.progressModal.call("startTask","Deleting User...");
                return MeteorContext.sendAuthenticated({type: "auth", subType: "deleteUser", userId : userId}, (_err, _res) => {
                    if (!this._isMounted) { return; }
                    if (_err) {
                        this.progressModal.call("error",_err);
                    }
                    else {
                        this.progressModal.call("complete");
                        this.reloadUsers();
                    }
                });
            }
        });

    }

    onUserSelected(_item) {
        return this.setState(() => {
            return {
                selectedUser: _item
            };
        })
    }

    onCloseUser() {
        this.onUserSelected(null);
    }

    renderUserSelection() {
        let data = [];
        if (this.state.userList) {
            for(let i = 0; i < this.state.userList.length; i++) {
                let currentUser= this.state.userList[i];
                data.push(<UserListEntry key={"user_"+i} name={currentUser.id}
                                         onEdit={() => {this.onUserSelected(currentUser);}}
                                         onDelete={() => {this.onDeleteUserButton(currentUser);}}
                />);
            }
        }
        if (data.length > 0) {
            return (
                <Panel bordered shaded style={{maxWidth:"350px"}}>
                    <List>
                        {data}
                    </List>
                </Panel>
            );
        }
        return null;
    }

    render() {
        return (
            <Fragment>
                <Container>
                    <MeteorNavigationBar/>
                    <Container style={{margin:"10px"}}>
                        <SimpleProgressModal ref={this.progressModal.getRef()}/>
                        <DeleteUserModal ref={this.deleteUserModal}/>
                        <Panel bordered shaded header={<h4>User Management</h4>}>
                            <SimpleProgressUI ref={this.progressUI.getRef()}/>
                            {(() => {
                                if (this.state.selectedUser) {
                                    return <MeteorUserUI user={this.state.selectedUser} permissions={this.state.validPermissions} onClose={this.onCloseUser} onSave={this.onSaveUser}/>
                                } else {
                                    return this.renderUserSelection()
                                }
                            })()}

                        </Panel>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default UserManagementPage;