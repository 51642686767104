import React,{Fragment} from "react";
import {Button, Container, Input, InputGroup, Panel, Notification, Table} from "rsuite";
import SimpleProgresUI from "../../common/SimpleProgresUI";
import withPermission from "../../common/SiteWithPermission";
import withNavigation from "../../common/SiteWithNavigation";
import TablePagination from "rsuite/es/Table/TablePagination";
import AnalyticsConnection from "../AnalyticsConnection";
import ESContext from "../ESContext";

function tryParse(_id, _text) {
    let json = null;
    try {
        json = JSON.parse(_text)
    } catch (ex) {}
    if (json === null) {
        Notification.error({title: "Error", description: _id+" not parsable"});
    }
    return json;
}

class ESQueryPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            fields : "[]",
            group: "[]",
            filter: "{}",
            page: 1,
            pageLength: 10
        };

        this.progressUI = React.createRef();

        this.onTextChanged = this.onTextChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderResultTable = this.renderResultTable.bind(this);
        this.checkPermission = this.checkPermission.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
    }

    checkPermission() {
        return true;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onTextChanged(_id, _val) {
        return this.setState(() => {
            let obj = {};
            obj[_id] = _val;
            return obj;
        });
    }

    onPageChanged(_val) {
        return this.setState(() => {
            return {page: _val};
        });
    }

    onSubmit() {
        let fields = tryParse("Fields", this.state.fields);
        let group = tryParse("Group", this.state.group);
        let filter = tryParse("Filter", this.state.filter);
        if (group === null || fields === null || filter === null) {
            return;
        }
        this.progressUI.current.start("Sending query...");
        let ctx = ESContext.getAsyncContext();
        ctx.sendAsyncUncached("data", this.state.index, fields, group, filter);
        ctx.waitForCompletion( (_err, _res) => {
        //return AnalyticsConnection.sendAuthenticated({type: "analytics_v3", subType:"es_query_v3", noCache:true,
            //index: this.state.index, fields: fields, group: group, filter: filter}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            this.progressUI.current.stop();
            let columns = {};
            for(let i = 0; i < _res.data.result.length; i++) {
                const current = _res.data.result[i];
                for(let colName in current) {
                    columns[colName] = true;
                }
            }
            let colNames = [];
            for(let colName in columns) {
                colNames.push(colName);
            }
            return this.setState(() => {
                return {
                    columns: colNames,
                    queryResult: _res.data.result
                };
            });
        });
    }

    renderResultTable() {
        let columns = [];
        if (this.state.columns) {
            for (let i = 0; i < this.state.columns.length; i++) {
                const colName = this.state.columns[i];
                columns.push(
                    <Table.Column resizable width={200}>
                        <Table.HeaderCell>{colName}</Table.HeaderCell>
                        <Table.Cell>
                            {(_rowData) => {
                                let value = _rowData[colName];
                                if (typeof value === "boolean") {
                                    return value === true ? "true" : "false";
                                }
                                return _rowData[colName];
                            }}
                        </Table.Cell>
                    </Table.Column>
                );
            }
        }
        if (columns.length > 0) {
            let start = (this.state.page-1) * this.state.pageLength;
            let end = start + this.state.pageLength;
            let filtered = this.state.queryResult.slice(start, end);
            return (
                <Fragment>
                    <Table data={filtered} autoHeight>
                        {columns}
                    </Table>
                    <TablePagination displayLength={this.state.pageLength} total={this.state.queryResult.length}
                                     page={this.state.page} showLengthMenu={false}
                                     onChangePage={this.onPageChanged} activePage={this.state.page}
                    />
                </Fragment>
            )
        }
        return null;
    }

    render() {
        return (
            <Container>
                <Panel bordered shaded header={<h4>Query</h4>} style={{margin:10}}>
                    <Panel bordered shaded header={<h6>Query Params</h6>} style={{width:"1000px"}}>
                        <InputGroup>
                            <InputGroup.Addon>Index</InputGroup.Addon>
                            <Input value={this.state.index} onChange={(_val) => {this.onTextChanged("index", _val)}}/>
                        </InputGroup>
                        <br/>
                        <InputGroup>
                            <InputGroup.Addon>Fields</InputGroup.Addon>
                            <Input componentClass="textarea" value={this.state.fields} onChange={(_val) => {this.onTextChanged("fields", _val)}} style={{width:1000, resize:"auto"}}/>
                        </InputGroup>
                        <br/>
                        <InputGroup>
                            <InputGroup.Addon>Group</InputGroup.Addon>
                            <Input value={this.state.group} onChange={(_val) => {this.onTextChanged("group", _val)}}/>
                        </InputGroup>
                        <br/>
                        <InputGroup>
                            <InputGroup.Addon>Filter</InputGroup.Addon>
                            <Input componentClass="textarea" value={this.state.filter} onChange={(_val) => {this.onTextChanged("filter", _val)}} style={{width:1000, resize:"auto"}}/>
                        </InputGroup>
                        <br/>
                        <Button appearance={"primary"} onClick={this.onSubmit}>Send</Button>
                    </Panel>
                    <SimpleProgresUI ref={this.progressUI}/>
                    {this.renderResultTable()}
                </Panel>
            </Container>
        );
    }

}

export default
    withNavigation(
        withPermission(()=>{return AnalyticsConnection.hasPermission("admin")},
            ESQueryPage
        )
    )
;