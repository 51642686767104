import React, {Fragment} from "react";
import {HelpBlock, Input, Button, Notification, Modal, Toggle, Icon} from "rsuite";
import FilterTags from "../FilterTags";
import ConditionalFragment from "../../common/ConditionalFragment";
import {GetGraphNameById} from "../charts/ChartsUtil";

class EditGraphConfigModal extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            open: false,
            fields: [],
            config : {}
        }

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    show(_graphName, _fields, _config, _callback) {
        let prepConfig = {};
        for (let i = 0; i < _fields.length; i++) {
            const field = _fields[i];
            let fieldId = null;
            let fieldType = "json";
            if (typeof field === "string") {
                fieldId = field;
                fieldType = "json";
            } else {
                fieldId = field.id;
                fieldType = field.type;
            }
            if (fieldType === "string") {
                prepConfig[fieldId] = _config[fieldId] ? _config[fieldId] : (field.default ? field.default : "");
            }
            if (fieldType === "number") {
                prepConfig[fieldId] = _config[fieldId] ? _config[fieldId] : (field.default ? field.default : 0);
            }
            if (fieldType === "boolean") {
                prepConfig[fieldId] = _config[fieldId] === true;
            }
            if (fieldType === "filterTags") {
                prepConfig[fieldId] = _config[fieldId] ? _config[fieldId] : {};
            }
            if (fieldType === "json") {
                prepConfig[fieldId] = _config[fieldId] ? JSON.stringify(_config[fieldId]) : "";
            }
        }
        return this.setState(() => {
            return {
                open: true,
                name: "["+GetGraphNameById(_graphName)+"]",
                fields : _fields,
                config : prepConfig,
                callback: _callback
            };
        });
    }

    close() {
        return this.setState(() => {
            return {
                open: false,
                callback: null };
        });
    }

    onValueChanged(_id, _val) {
        return this.setState((_state, _props)=> {
            let copy = JSON.parse(JSON.stringify(_state.config))
            copy[_id] = _val;
            return {config: copy};
        });
    }

    onSave() {
        let config = {};
        for(let i = 0; i < this.state.fields.length; i++) {
            const field = this.state.fields[i];
            let fieldId = null;
            let fieldType = "json";
            if (typeof field === "string") {
                fieldId = field;
                fieldType = "json";
            } else {
                fieldId = field.id;
                fieldType = field.type;
            }
            if (fieldType === "string") {
                config[fieldId] = this.state.config[fieldId];
            }
            if (fieldType === "number") {
                config[fieldId] = this.state.config[fieldId];
            }
            if (fieldType === "boolean") {
                config[fieldId] = this.state.config[fieldId] === true;
            }
            if (fieldType === "filterTags") {
                config[fieldId] = this.state.config[fieldId];
            }
            if (fieldType === "json") {
                const value = this.state.config[fieldId];
                let json = null;
                if (value !== "" && value !== undefined && value !== null) {
                    try {
                        json = JSON.parse(value);
                    } catch(ex) {}
                    if (json === null) {
                        Notification.error({description: "Couldn't parse field: "+field});
                        return;
                    }
                }
                config[fieldId] = json;
            }
        }
        if(typeof this.state.callback === "function") {
            this.state.callback(true, config);
        }
        this.close();
    }

    onCancel() {
        if(typeof this.state.callback === "function") {
            this.state.callback(false);
        }
        this.close();
    }

    onHide() {
        this.onCancel();
    }

    render() {
        const nameWidth = "55px";
        let inputs = [];
        for(let i = 0; i < this.state.fields.length; i++) {
            const field = this.state.fields[i];
            let fieldId = null;
            let fieldType = "json";
            let fieldName = null;
            if (typeof field === "string") {
                fieldId = field;
                fieldName = field;
                fieldType = "json";
            } else {
                fieldId = field.id;
                fieldName = field.name;
                fieldType = field.type;
            }
            let isVisible = true;
            if (typeof field === "object" && typeof field.showOnly === "function") {
                isVisible = field.showOnly(this.state.config);
            }
            if (!isVisible) {
                continue;
            }
            if (fieldType === "string") {
                inputs.push(
                    <Fragment key={"idx_"+inputs.length}>
                        <br/>
                        <HelpBlock>{fieldName+" ("+fieldType+")"}</HelpBlock>
                        <Input type={"text"} value={this.state.config[fieldId]} onChange={(_val) => {this.onValueChanged(fieldId, _val)}}/>
                    </Fragment>
                )
            }
            if (fieldType === "number") {
                inputs.push(
                    <Fragment key={"idx_"+inputs.length}>
                        <br/>
                        <HelpBlock>{fieldName+" ("+fieldType+")"}</HelpBlock>
                        <Input type={"number"} value={this.state.config[fieldId]} onChange={(_val) => {this.onValueChanged(fieldId, _val)}}/>
                    </Fragment>
                )
            }
            if (fieldType === "boolean") {
                inputs.push(
                    <Fragment key={"idx_"+inputs.length}>
                        <br/>
                        <HelpBlock>{fieldName+" ("+fieldType+")"}</HelpBlock>
                        <Toggle checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />} checked={this.state.config[fieldId]} onChange={(_val) => {this.onValueChanged(fieldId, _val)}}/>
                        <br/>
                    </Fragment>
                )
            }
            if (fieldType === "filterTags") {
                inputs.push(
                    <Fragment key={"idx_"+inputs.length}>
                        <br/>
                        <HelpBlock>{fieldName+" ("+fieldType+")"}</HelpBlock>
                        <ConditionalFragment condition={FilterTags.filerCount(this.state.config[fieldId]) === 0}>
                            <br/>
                        </ConditionalFragment>
                        <FilterTags filter={this.state.config[fieldId]} onChange={(_val) => {this.onValueChanged(fieldId, _val)}}/>
                    </Fragment>
                )
            }
            if (fieldType === "json") {
                inputs.push(
                    <Fragment key={"idx_"+inputs.length}>
                        <br/>
                        <HelpBlock>{fieldName+" ("+fieldType+")"}</HelpBlock>
                        <Input type={"text"} componentClass={"textarea"} value={this.state.config[fieldId]} onChange={(_val) => {this.onValueChanged(fieldId, _val)}}/>
                    </Fragment>
                )
            }

        }
        return (
            <Modal show={this.state.open} size={"md"} onHide={this.onHide}>
                <Modal.Header>
                    <Modal.Title>
                        Edit {this.state.name} Config
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HelpBlock>
                        Please enter the configuration of the Graph.
                    </HelpBlock>
                    {inputs}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onSave} appearance="primary">
                        Save Config
                    </Button>
                    <Button onClick={this.onCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default EditGraphConfigModal;