import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber} from "../graphs/GraphFormatting";
import {formatGermanDate} from "../../common/TimeUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";

class Installs_LineChart {
    static getUUID() {
        return "99a32211-8a6c-484c-825d-ac86b22fc9f1";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"minThreshold",name:"Min. Threshold",type:"number", default: 10},
            {id:"withSum",name:"with Sum", type:"boolean", showOnly: (_props) => {return _props.group && _props.group !== ""}},
            {id:"filter",name:"Filter", type:"filterTags"},
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcInstalls(_installsData, _minThreshold,  _groupName, _groupValue) {
        let installData = _installsData;
        if (_groupName && _groupValue) {
            installData = AnalyticsUtil.filterBy(_installsData, (_e) => _e[_groupName] === _groupValue);
        }
        let installs = {};
        for (let i = 0; i < installData.length; i++) {
            const current = installData[i];
            if (!installs[current.install_date]) {
                installs[current.install_date] = 0;
            }
            installs[current.install_date] += current.installs;
        }
        let series = [];
        for (let installDate in installs) {
            const current = installs[installDate];
            if (current >= _minThreshold) {
                series.push({name: _groupValue, x: new Date(installDate).getTime(), y: current, text: current+" Users"});
            }
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        ctx.sendAsync("installs","user_v4",["cardinality:user_id:installs"],["install_date", _props.group], filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installsData = _data.installs.result;
                let groups = AnalyticsUtil.getDistinct(installsData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = Installs_LineChart.calcInstalls(installsData, _props.minThreshold, _props.group, groups[i]);
                        series.push(data);
                    }
                    if (_props.withSum) {
                        let data = Installs_LineChart.calcInstalls(installsData, _props.minThreshold);
                        data.name = "Sum";
                        series.push(data);
                    }
                } else {
                    let data = Installs_LineChart.calcInstalls(installsData, _props.minThreshold);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getXAxis() {
        return {type: "datetime", crosshair: true};
    }

    static getTooltip() {
        return {
            split: true,
            formatter: function () {
                let result = [formatGermanDate(new Date(this.x))];
                for (let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(Installs_LineChart, HighChartsGraph);