import React, {Fragment} from "react";
import {Button, Container, FlexboxGrid, Icon, Input, InputGroup, Notification, Panel, Toggle} from "rsuite";
import SimpleProgressUI from "../../common/SimpleProgresUI";
import withNavigation from "../../common/SiteWithNavigation";
import withPermission from "../../common/SiteWithPermission";
import AnalyticsConnection from "../AnalyticsConnection";

class DBConfigPage extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
        };

        this.progressUI = React.createRef();
        this.loadConfig = this.loadConfig.bind(this);
        this.updateConfigVal = this.updateConfigVal.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadConfig();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadConfig() {
        this.progressUI.current.start("Retrieving Config...");
        return AnalyticsConnection.sendAuthenticated({type: "admin",subType:"getConfig", serviceId:"elasticsearch"}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                this.progressUI.current.error(_err);
                return;
            }
            this.progressUI.current.stop();
            return this.setState(() => {
                return {
                    config: _res.config,
                    origConfig: _res.config,
                    changed: false
                };
            })
        });
    }

    onCancel() {
        return this.setState((_prevState, _props) => {
            return {
                config : JSON.parse(JSON.stringify(_prevState.origConfig)),
                changed: false
            };
        });
    }

    onSave() {
        return AnalyticsConnection.sendAuthenticated({type: "admin",subType:"setConfig", serviceId:"elasticsearch", config: this.state.config}, (_err, _res) => {
            if (!this._isMounted) { return; }
            if (_err) {
                Notification.error({title: "Error", description: _err.message});
                return;
            }
            Notification.success({title: "Success", description: "Successfully saved config."});
            this.loadConfig();
        });
    }

    updateConfigVal(_id, _val) {
        return this.setState((_prevState, _props) => {
            let copy = JSON.parse(JSON.stringify(_prevState.config));
            copy[_id] = _val;
            return {
                config: copy,
                changed: true
            };
        });
    }

    renderConfig() {
        let saveUI = null;
        if (this.state.changed === true) {
            saveUI = (<Fragment>
                    <Button onClick={this.onCancel} appearance="subtle" style={{float:"right"}}>
                        Cancel
                    </Button>
                    <Button onClick={this.onSave} appearance="primary" style={{float:"right", marginRight:"10px"}}>
                        Save Changes
                    </Button>
                    <br/>
                </Fragment>
            )
        }
        if (this.state.config) {
            let nameWidth = "40px";
            return (
                <Panel bordered shaded style={{maxWidth:"600px"}}>
                    <InputGroup>
                        <InputGroup.Addon><div style={{width:nameWidth}}>Region</div></InputGroup.Addon>
                        <Input value={this.state.config.region} onChange={(_val) => {this.updateConfigVal("region",_val);}}/>
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroup.Addon><div style={{width:nameWidth}}>Host</div></InputGroup.Addon>
                        <Input value={this.state.config.host} onChange={(_val) => {this.updateConfigVal("host",_val);}}/>
                    </InputGroup>
                    <br/>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={2} style={{display:"flex", justifyContent: "flex-end"}}>
                                <Toggle checked={this.state.config.syncEnabled} onChange={(_val) => {this.updateConfigVal("syncEnabled", _val)}}
                                        checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />}/>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}/>
                            <FlexboxGrid.Item colspan={21}>
                                Sync Enabled
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    <br/>
                    {saveUI}
                </Panel>
            );
        }
        return null;
    }

    render() {

        return (
            <Container style={{margin:"10px"}}>
                <Panel bordered shaded header={<h4>DB Settings</h4>}>
                    <SimpleProgressUI ref={this.progressUI}/>
                    {(()=> {
                        return this.renderConfig();
                    })()}
                </Panel>
            </Container>
        );
    }
}

export default
    withNavigation(
        withPermission(() => {return AnalyticsConnection.hasPermission("admin")},
            DBConfigPage
        )
    )
;