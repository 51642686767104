import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatEuro} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import {formatGermanDate} from "../../common/TimeUtil";

class Revenue_Daily {
    static getUUID() {
        return "018f43ee-85e1-4345-b701-587c2d7ff56e";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"withSum",name:"with Sum", type:"boolean", showOnly: (_props) => {return _props.group && _props.group !== ""}},
            {id:"excludeAds", name: "Exclude Ads", type:"boolean"},
            {id:"excludeIAP", name: "Exclude IAPs", type:"boolean"},
            {id:"split", name: "Split Ads & IAPs", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcRevenue(_adsData, _iapData, _dauData, _props, _groupValue) {
        if (!_adsData) {
            _adsData = [];
        }
        if (!_iapData) {
            _iapData = [];
        }
        let adsData = _adsData;
        let iapData = _iapData;
        let dauData = _dauData;
        if (_props.group && _groupValue) {
            dauData = AnalyticsUtil.filterBy(_dauData, (_e) => _e[_props.group] === _groupValue);
            adsData = AnalyticsUtil.filterBy(_adsData, (_e) => _e[_props.group] === _groupValue);
            iapData = AnalyticsUtil.filterBy(_iapData, (_e) => _e[_props.group] === _groupValue);
        }
        let revSeries = [];
        let adSeries = [];
        let iapSeries = [];
        let revData = {};
        for (let i = 0; i < dauData.length; i++) {
            const current = dauData[i];
            if (!revData[current.day]) {
                revData[current.day] = {ads: 0, iap : 0, dau: 0}
            }
            if (typeof current.dau === "number") {
                revData[current.day].dau += current.dau;
            }
        }
        for (let i = 0; i < adsData.length; i++) {
            const current = adsData[i];
            if (!revData[current.day]) {
                revData[current.day] = {ads: 0, iap : 0, dau: 0};
            }
            if (typeof current.revenue === "number") {
                revData[current.day].ads += current.revenue;
            }
        }
        for (let i = 0; i < iapData.length; i++) {
            const current = iapData[i];
            if (!revData[current.day]) {
                revData[current.day] = {ads: 0, iap : 0, dau: 0}
            }
            if (typeof current.revenue === "number") {
                revData[current.day].iap += current.revenue;
            }
        }
        for(let day in revData) {
            const current = revData[day];
            revSeries.push({name: _groupValue, x: new Date(day), y: (current.iap + current.ads), text: (Math.round((current.iap+current.ads)*100)/100)+"€ ("+current.dau+" Users)"});
            adSeries.push({name: _groupValue+" (Ads)", x: new Date(day), y: current.ads, text: (Math.round(current.ads*100)/100)+"€ ("+current.dau+" Users)"});
            iapSeries.push({name: _groupValue+" (IAP)", x: new Date(day), y: current.iap, text: (Math.round(current.iap*100)/100)+"€ ("+current.dau+" Users)"});
        }
        if (_props.split) {
            let result = [];
            if (!_props.excludeAds && !_props.excludeIAP) {
                result.push({name: _groupValue ? _groupValue : "Revenue", data : revSeries});
            }
            if (!_props.excludeAds) {
                result.push({name: _groupValue ? _groupValue+" (Ads)" : "Ads", data : adSeries});
            }
            if (!_props.excludeIAP) {
                result.push({name: _groupValue ? _groupValue+" (IAP)" : "IAP", data : iapSeries});
            }
            return result;
        } else {
            return [{name: _groupValue, data : revSeries}];
        }
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        const grouping = ["day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        ctx.sendAsync("dau","session_duration_v4",["cardinality:user_id:dau"],["day", _props.group], filter);
        if (!_props.excludeAds) {
            ctx.sendAsync("ads","ad_impression_v4",["sum:revenue:revenue"],grouping, filter);
        }
        if (!_props.excludeIAP) {
            ctx.sendAsync("iap","premium_purchase_v4",["sum:revenue:revenue"],grouping, filter);
        }
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let dauData = _data.dau.result;
                let adsData = _data.ads ? _data.ads.result : [];
                let iapData = _data.iap ? _data.iap.result : [];
                let groups = AnalyticsUtil.getDistinct(dauData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = Revenue_Daily.calcRevenue(adsData, iapData, dauData, _props, groups[i]);
                        series = series.concat(data);
                    }
                    if (_props.withSum) {
                        let data = Revenue_Daily.calcRevenue(adsData, iapData, dauData, _props);
                        data[0].name = "Sum";
                        series = series.concat(data);
                    }
                } else {
                    let data = Revenue_Daily.calcRevenue(adsData, iapData, dauData, _props);
                    series = series.concat(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: series.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {type: "datetime", crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatEuro();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = [formatGermanDate(new Date(this.x))];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(Revenue_Daily, HighChartsGraph);