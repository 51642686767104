import {getUnixTime} from "../common/TimeUtil";
import AnalyticsConnection from "../analytics/AnalyticsConnection";
import FirebuildConnection from "./FirebuildConnection";
import {forwardRef} from "react";

function create() {
    let wrapper = {};

    wrapper.clearCache = () => {
        window.sessionStorage.setItem("cf.firebuild.buildctx",null);
        let toDelete = [];
        for(let i = 0; i < window.sessionStorage.length; i++) {
            let key = window.sessionStorage.key(i);
            if (key.indexOf("cf.firebuild.buildctx") === 0) {
                toDelete.push(key);
            }
        }
        for(let i = 0; i < toDelete.length; i++) {
            let key = toDelete[i];
            window.sessionStorage.setItem(key, null);
        }
    }

    let getBuildsCallbacks = []
    wrapper.getBuilds = (_projectId, _buildType, _cb) => {
        return FirebuildConnection.sendAuthenticated({type:"builds",subType:"list", projectId: _projectId, buildType: _buildType}, (_err, _result) => {
            if (_err) {
                return _cb(_err);
            }
            let result = [];
            if (_result && _result.builds) {
                result = _result.builds;
            }
            return _cb(null, result);
        });
    }

    wrapper.getBuild = (_buildId, _cb) => {
        return FirebuildConnection.sendAuthenticated({type:"builds",subType:"get", buildId: _buildId}, (_err, _result) => {
            if (_err) {
                return _cb(_err);
            }
            let result = [];
            if (_result && _result.build) {
                result = _result.build;
            }
            return _cb(null, result);
        });
    }

    wrapper.deleteBuild = (_build, _cb) => {
        return FirebuildConnection.sendAuthenticated({type:"builds", subType:"delete", buildId: _build.buildId}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            return wrapper.getBuilds(_build.projectId, _build.buildType, _cb);
        });
    }

    wrapper.updateBuildComment = (_build, _comment, _cb) => {
        return FirebuildConnection.sendAuthenticated({type:"builds", subType:"update", buildId: _build.buildId, comment: _comment}, (_err, _res) => {
            if (_err) {
                return _cb(_err);
            }
            return wrapper.getBuilds(_build.projectId, _build.buildType, _cb);
        });
    }

    let getProjectsCallbacks = [];
    wrapper.getProjects = (_cb) => {
        let cachedData = window.sessionStorage.getItem("cf.firebuild.buildctx");
        let cached = null;
        try {
            cached = JSON.parse(cachedData);
        } catch (ex) {};
        if (cached && cached.ttl >= getUnixTime()) {
            return _cb(null, cached.data);
        }
        if (getProjectsCallbacks.length > 0) {
            getProjectsCallbacks.push(_cb);
            return;
        }
        getProjectsCallbacks.push(_cb);
        return FirebuildConnection.sendAuthenticated({type:"projects",subType:"list"}, (_err, _result) => {
            if (_err) {
                let cbList = getProjectsCallbacks;
                getProjectsCallbacks = [];
                for (let i = 0; i < cbList.length; i++) {
                    cbList[i](_err);
                }
                return;
            }
            let result = [];
            if (_result && _result.projects) {
                let cache = {
                    data: _result.projects,
                    ttl: getUnixTime()+ 60*60
                };
                window.sessionStorage.setItem("cf.firebuild.buildctx", JSON.stringify(cache));
                result = cache.data;
            }
            let cbList = getProjectsCallbacks;
            getProjectsCallbacks = [];
            for(let i = 0; i < cbList.length; i++) {
                cbList[i](null, result);
            }
        });
    }

    wrapper.getProjectDetails = (_projectId, _cb) => {
        return wrapper.getProjects((_err, _list) => {
            if (_err) {
                return _cb(_err);
            }
            for (let i = 0; i < _list.length; i++) {
                if (_list[i].projectId === _projectId) {
                    return _cb(null, _list[i]);
                }
            }
            return _cb(null, null);
        });
    }

    wrapper.resolveProjectName = (_id, _cb) => {
        return wrapper.getProjects((_err, _list) => {
            if (_err) {
                return _cb(_err);
            }
            for (let i = 0; i < _list.length; i++) {
                if (_list[i].id === _id) {
                    return _cb(null, _list[i].name);
                }
            }
            return _cb(null, _id);
        });
    }

    return wrapper;
}

const instance = create();
export default instance;