import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatPercent} from "../graphs/GraphFormatting";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import {calculateSignificance} from "../chiTest";

class Retention_LineChart {
    static getUUID() {
        return "9c07bfeb-e2c5-439e-8c5a-c05c988ad00d";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"withSignificance", name:"Show Significance", type:"boolean"},
            {id:"refGroup", name:"ReferenceGroup", type:"string", showOnly: (_props) => {return _props.withSignificance === true}},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcRetention(_installsData, _sessionData, _groupName, _groupValue) {
        let installData = _installsData;
        let sessionData = _sessionData;
        if (_groupName && _groupValue) {
            installData = AnalyticsUtil.filterBy(_installsData, (_e) => _e[_groupName] === _groupValue);
            sessionData = AnalyticsUtil.filterBy(_sessionData, (_e) => _e[_groupName] === _groupValue);
        }
        let sessionPilData = AnalyticsUtil.addPil(sessionData);
        let series = [];
        for (let pil = 0; pil <= 28; pil++) {
            const filtered = AnalyticsUtil.filterBy(sessionPilData,(_e) => _e.pil >= 0 && _e.pil === pil && _e.maxPil >= pil);
            let userCount = 0;
            for (let i = 0; i < filtered.length; i++) {
                userCount += filtered[i].dau;
            }
            let installCount = 0;
            for (let i = 0; i < installData.length; i++) {
                const current = installData[i];
                const maxPil = AnalyticsUtil.calcMaxPil(current.install_date);
                if (maxPil >= pil) {
                    installCount += current.installs;
                }
            }
            if (installCount > 10) {
                let percentage = (userCount/installCount)*100;
                series.push({name: _groupValue, x: pil, y: percentage, userCount: userCount, installCount: installCount, text: (Math.round(percentage*100)/100)+"% ("+installCount+" Installs)"});
            }
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        let sessionFilter = filter;
        if (_props.dateSelection) {
            filter.install_date = {from: _props.dateSelection.start, to: _props.dateSelection.end};
            sessionFilter = JSON.parse(JSON.stringify(filter));
            sessionFilter.day = {from : _props.dateSelection.start, to: _props.dateSelection.end + (28*24*60*60)}
        }
        ctx.sendAsync("installs","user_v4",["cardinality:user_id:installs"],["install_date", _props.group], filter);
        ctx.sendAsync("sessions","session_duration_v4",["cardinality:user_id:dau"],["install_date","day", _props.group], sessionFilter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let installsData = _data.installs.result;
                let sessionData = _data.sessions.result;
                let groups = AnalyticsUtil.getDistinct(installsData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = Retention_LineChart.calcRetention(installsData, sessionData, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = Retention_LineChart.calcRetention(installsData, sessionData);
                    series.push(data);
                }
                if (_props.withSignificance && _props.refGroup) {
                    calculateSignificance(series, _props.refGroup);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {labels: {format: "D{value}"}, allowDecimals: false, crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatPercent();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = ["D"+this.x];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(Retention_LineChart, HighChartsGraph);