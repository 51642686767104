import React from 'react';
import {Button, FlexboxGrid, HelpBlock, Icon, IconButton, List, Panel, Toggle} from "rsuite";
import {ReactComponent as IconClose} from "../icons/times-solid.svg";
const hashSum = require("hash-sum");

class MeteorUserUI extends React.Component {
    static getDerivedStateFromProps(_props, _state) {
        const hash = hashSum(_props.user);
        if (_state.userHash !== hash) {
            return {
                userHash : hash,
                user : _props.user,
                hasChanges : false
            };
        }
        return null;
    }

    constructor(_props) {
        super(_props);
        this.state = {};

        this.onSave = this.onSave.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onLockChanged = this.onLockChanged.bind(this);
        this.onPermissionChanged = this.onPermissionChanged.bind(this);
        this.renderPermissions = this.renderPermissions.bind(this);
    }

    onSave() {
        if(typeof this.props.onSave === "function") {
            this.props.onSave(this.state.user);
        }
    }

    onClose() {
        if(typeof this.props.onClose === "function") {
            this.props.onClose();
        }
    }

    onLockChanged(_val) {
        return this.setState((_state, _props) => {
            if (_state.user) {
                let clone = JSON.parse(JSON.stringify(_state.user));
                clone.locked = _val;
                return {user: clone, hasChanges : true};
            }
            return null;
        });
    }

    onPermissionChanged(_permission, _val) {
        return this.setState((_state, _props) => {
            if (_state.user) {
                let clone = JSON.parse(JSON.stringify(_state.user));
                clone.permissions[_permission] = _val;
                return {user: clone, hasChanges : true};
            }
            return null;
        })
    }

    renderPermissions() {
        if (this.state.user && this.state.user.permissions) {
            const permissions = this.state.user.permissions
            const result = [];
            for(let i= 0; i < this.props.permissions.length; i++) {
                const permissionName = this.props.permissions[i];
                result.push(
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={22}>
                                {permissionName}
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={2} style={{display:"flex", justifyContent: "flex-end"}}>
                                <Toggle style={{float:"right"}} checked={permissions[permissionName]}
                                        onChange={(_val)=> {this.onPermissionChanged(permissionName, _val);}}
                                        checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                );
            }
            return result;
        }
        return null;
    }

    render() {
        if (!this.state.user) {
            return null;
        }
        return (
            <Panel style={{maxWidth:"350px"}} bordered shaded header={
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={22}>
                        <h6>{this.state.user.id}</h6>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={2} style={{display:"flex", justifyContent: "flex-end"}}>
                        <IconButton size="xs" color="red" icon={<i className="rs-icon"><IconClose/></i>} onClick={this.onClose}/>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            }>
                <HelpBlock>SID: {this.state.user.sid}</HelpBlock><br/>
                <List size="sm">
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={22}>
                                Locked
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={2} style={{display:"flex", justifyContent: "flex-end"}}>
                                <Toggle style={{float:"right"}} checked={this.state.user.locked}
                                        onChange={this.onLockChanged}
                                        checkedChildren={<Icon icon="check" />} unCheckedChildren={<Icon icon="close" />} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {this.renderPermissions()}
                </List>
                <br/>
                <Button block disabled={!this.state.hasChanges} appearance={this.state.hasChanges ? "primary" : "default"} onClick={this.onSave}>Save Changes</Button>
            </Panel>
        );
    }
}

export default MeteorUserUI;