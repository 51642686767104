
class OutlierUtil {
    static filterOutlier(_list) {
        let sum = 0;
        for(let i = 0; i < _list.length; i++) {
            sum += _list[i];
        }
        const avg = sum / _list.length;
        let sqDev = 0;
        for(let i = 0; i < _list.length; i++) {
            const dev = _list[i]-avg;
            sqDev += (dev*dev);
        }
        sqDev /= _list.length;
        const stdDev = Math.sqrt(sqDev);
        let filtered = [];
        let outliers = [];
        for(let i = 0; i < _list.length; i++) {
            const diff = Math.abs(_list[i]-avg);
            if (diff < (3*stdDev)) {
                filtered.push(_list[i]);
            } else {
                outliers.push(_list[i]);
            }
        }
        return {cleaned : filtered, outlier : outliers};
    }
}

export default  OutlierUtil;