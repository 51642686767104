import ESContext from "../ESContext";
import AnalyticsUtil from "../graphs/AnalyticsUtil";
import {YAxisFormatNumber} from "../graphs/GraphFormatting";
import {formatGermanDate} from "../../common/TimeUtil";
import HighChartsGraph from "../graphs/HighChartsGraph";
import CachedGraphV2 from "../graphs/CachedGraphV2";
import OutlierUtil from "../OutlierUtil";
import StatisticsUtil from "../StatisticsUtil";

class FPS_Daily {
    static getUUID() {
        return "9e37087c-14ce-4066-874e-ed3cac4ee868";
    }

    static getProps() {
        return [
            {id:"title",name:"Title", type:"string"},
            {id:"group",name:"Group by", type:"string"},
            {id:"minThreshold",name:"Min. Threshold",type:"number", default: 10},
            {id:"filterOutlier", name: "Filter Outlier", type:"boolean"},
            {id:"filter",name:"Filter", type:"filterTags"}
        ];
    }

    static getGraphSize() {
        return 1;
    }

    static getHeightRatio() {
        return "50%";
    }

    static calcFPS(_fpsData, _filterOutlier, _minThreshold, _groupName, _groupValue) {
        let fpsData = _fpsData;
        if (_groupName && _groupValue) {
            fpsData = AnalyticsUtil.filterBy(_fpsData, (_e) => _e[_groupName] === _groupValue);
        }
        let series = [];
        if (_filterOutlier) {
            let data = {};
            for (let i = 0; i < fpsData.length; i++) {
                const current = fpsData[i];
                if (!data[current.day]) {
                    data[current.day] = [];
                }
                data[current.day].push(current.fps);
            }
            for(let day in data) {
                let fpsValues = data[day];
                let outlierResult = OutlierUtil.filterOutlier(fpsValues);
                let avg = StatisticsUtil.avg(outlierResult.cleaned);
                let outlierPercentage = outlierResult.outlier.length / (outlierResult.outlier.length + outlierResult.cleaned.length);
                const outlierText = "Outliers: "+outlierResult.outlier.length+"("+Math.round(outlierPercentage*10000)/100+"%)";
                if (outlierResult.cleaned.length >= _minThreshold) {
                    series.push({name: _groupValue, x: new Date(day).getTime(), y: avg, text: (Math.round(avg*10)/10)+ " ("+outlierResult.cleaned.length+" Users) "+outlierText});
                }
            }
        } else {
            for (let i = 0; i < fpsData.length; i++) {
                const current = fpsData[i];
                const fps = current.fps;
                if (current.dau >= _minThreshold) {
                    series.push({name: _groupValue, x: new Date(current.day).getTime(), y: fps, text: (Math.round(fps*10)/10)+ " ("+current.dau+" Users)"});
                }
            }
        }
        return {name: _groupValue, data: series};
    }

    static getGraphData(ctx, _props, _cb) {
        let filter = JSON.parse(JSON.stringify(_props.filter));
        if (_props.dateSelection) {
            filter.day = {from: _props.dateSelection.start, to: _props.dateSelection.end};
        }
        const grouping = ["day", _props.group];
        if (_props.filterOutlier === true) {
            grouping.push("user_id");
        }
        ctx.sendAsync("fps","fps_v4",["cardinality:user_id:dau","avg:fps:fps"],grouping, filter);
        return ctx.waitForCompletion((_err, _data) => {
            if (_err) {
                _cb(_err);
            } else {
                let fpsData = _data.fps.result;
                let groups = AnalyticsUtil.getDistinct(fpsData, _props.group);
                let series = [];
                if (groups.length > 0) {
                    for(let i = 0; i < groups.length; i++) {
                        let data = FPS_Daily.calcFPS(fpsData, _props.filterOutlier, _props.minThreshold, _props.group, groups[i]);
                        series.push(data);
                    }
                } else {
                    let data = FPS_Daily.calcFPS(fpsData, _props.filterOutlier, _props.minThreshold);
                    series.push(data);
                }
                let graphData = {
                    chart : {type: "line"},
                    legend: {enabled: groups.length > 1},
                    series : series,
                };
                return _cb(null, graphData);
            }
        });
    }

    static getXAxis() {
        return {type: "datetime", crosshair: true};
    }

    static getYAxis() {
        return YAxisFormatNumber();
    }

    static getTooltip() {
        return {
            split:true,
            formatter : function() {
                let result = [formatGermanDate(new Date(this.x))];
                for(let i = 0; i < this.points.length; i++) {
                    result.push(this.points[i].point.text);
                }
                return result;
            }
        };
    }
}

export default CachedGraphV2(FPS_Daily, HighChartsGraph);