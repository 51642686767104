import React, {Fragment} from "react";

class ConditionalFragment extends React.Component
{
    render() {
        if (this.props.condition === true) {
            return (
                <Fragment>
                    {this.props.children}
                </Fragment>
            );
        }
        return null;
    }
}

export default ConditionalFragment;